<template>
  <div class="flex justify-between pointer  round-box">
    <div class="left-side full-center">
      <img :src="imgSrc()" />
      <div> {{ countryName }}</div>
    </div>

    <div class="right-side full-center">
      {{ allowedCurrencies.join(' - ') }}
    </div>


  </div>
</template>
<script>

export default {
  name: 'Country',
  props: {
    countryIso: {
      type: String,
      required: true
    },
    allowedCurrencies: {
      type: Array,
      required: true,
      default: []
    },
    countryName: {
      type: String,
      required: true
    }
  },
  methods: {
    imgSrc() {
      try {
        return require(`../../../../assets/mygmt_v2_images/countries/${this.countryIso}.svg`);
        
      } catch (error) {
        
      }

    },
    
  },
  created() {
    console.log('created');
  }



}
</script>
<style lang="scss" scoped>
.left-side {
  div {
    margin-left: 12.5px;
  }

  img {
    width: 32px;
    height: 32px;
  }
}

.right-side {
  margin-right: 10px;
  color: rgba(22, 25, 50, 0.69);
}
</style>