<template>
  <div class="container">
    <div class="main-summary">
      <div class="line">
        <div class="title"> Beneficiary Name</div>
        <div class="value"></div>
      </div>
      <div class="line">
        <div class="title">Destination</div>
        <div class="value"> <img :src="imgSrc(selectedTransferOption.destinationCountry)" alt="" srcset=""> <span>{{ destinationCountry}}</span> </div>
      </div>
      <div class="line">
        <div class="title">Transfer Method</div><div class="value"> {{ selectedTransferOption.transactionClass }}</div>
      </div>
      <div class="line">
        <div class="title">Pickup info</div><div class="value"></div>
      </div>
      <div class="line">
        <div class="title">Aviable in</div><div class="value"></div>
      </div>
      <div class="seperator"></div>
      <div class="line">
        <div class="title">They recieve</div><div class="value">{{ selectedTransferOption.totalReceiveAmount }} {{ selectedTransferOption.payoutCurrency }}</div>
      </div>
      <div class="line">
        <div class="title">Commision</div><div class="value">{{ selectedTransferOption.commission }} {{ selectedTransferOption.currency }}</div>
      </div>
      <div class="line">
        <div class="title">Exchange rate</div><div class="value">{{ selectedTransferOption.conversionRate || selectedTransferOption.estimatedExchangeRate }}</div>
      </div>
      <div class="line">
        <div class="title">Cashback discount</div><div class="value"></div>
      </div>
      <div class="line">
        <div class="title bold">I Pay</div><div class="value">{{ selectedTransferOption.amount  + selectedTransferOption.commission }}  {{ selectedTransferOption.currency }}</div>
      </div>

    </div>
    <div v-if="selectedCoupon" class="coupon">
      <div class="flex"> <img src="../../../../assets/mygmt_v2_images/Coupon.svg" alt="" srcset=""> <span>Coupon <span
            class="coupon-name ">"{{selectedCoupon.name}}"</span> applied!</span> </div> <img class="pointer" @click="removeCoupon"
        src="../../../../assets/mygmt_v2_images/Garbage.svg" alt="">
    </div>
    <div v-else class="coupon pointer" @click="addCoupon()">
      <div class="flex"> <img src="../../../../assets/mygmt_v2_images/Coupon.svg" alt="" srcset=""> <span>I have a coupon</span> </div> <img
        src="../../../../assets/mygmt_v2_images/add.svg" alt="">
    </div>
    <div class="flex full-center"> <button @click="$emit('changeStep', 4)" class="btn btn-main"> Next</button></div>

  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso,getCountryNameByIso ,isApp, isMobile } from '../../../../services/utils';


export default {
  components: {},
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    this.destinationCountry = getCountryNameByIso(this.selectedTransferOption.destinationCountry)
    console.log('blaa', this.$store.getters.getSelectedTransferOption);
    console.log('blaa2', this.$store.getters.getSelectedBeneficiary);


  

  },
  data() {
    return {
      selectedCoupon: null,
      destinationCountry: ''
    };
  },
  computed: {
    selectedTransferOption() {
      return this.$store.getters.getSelectedTransferOption
    },

  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() { },
  methods: {
    addCoupon(){
      this.selectedCoupon = {name: 'GMT2024'}
    },

    imgSrc(countryIso) {
      try {
        return require(`../../../../assets/mygmt_v2_images/countries/${countryIso}.svg`);
        
      } catch (error) {
        
      }

    },
    removeCoupon(){
      this.selectedCoupon = null
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

.main-summary {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  padding: 14px;
}

.title {
  display: flex;
  justify-content: space-between;
}

.seperator {
  height: 1px;
  border: 1px #EBEBEB solid;
  margin: 14px 0px;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #000;
font-family: Assistant;
font-size: 16px;
font-style: normal;
font-weight: 400;

.value {
 display: flex;
 justify-content: center;
 align-items: center;
  img{
    margin-right: 5px;
    height: 16px;
    width: 16px;

  }

}
}

.bold {
font-size: 18px;
font-weight: 700;
}

.coupon {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  margin: 24px 0px 40px 0px;

  img:first-child {
    margin-right: 8px;
  }

  .coupon-name {
    color: var(--GMT--Blue, #0E1F81);
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}

.btn-main {
  width: 335px;
  height: 45px;
}
</style>
