<template>
  <div>
    <Header></Header>
    <div class="main-wrapper">
      <Wellcome></Wellcome>
      <div class="content block block-recent-actions">
        <div class="content-top">
          <div class="form-search">
            <input type="text" class="form-control" v-bind:placeholder="$ml.with('VueJS').get('freeSearch')"
              v-model="searchTransactions">
            <i class="icon icon-search"></i>
          </div>
        </div>
        <div class="body">
          <div v-if="loading" class="loader_page">
            <img src="../assets/images/loading.gif" />
          </div>
          <div v-else class="wrap">
            <h1 class="title" v-text="$ml.with('VueJS').get('recentActions')"></h1>
            <ul class="nav-tabs text-center">
              <li v-if="customerHaveCards">
                <router-link v-bind:to="'/card_main'">
                  <img src="../assets/images/visaIconWhiteBg.png" class="smallImgs visaImg">
                  <span v-text="$ml.with('VueJS').get('itemNav12')"></span>
                </router-link>
              </li>
              <li>
                <router-link :to="createBeneficiaryRoute">
                  <img src="../assets/images/add-user.png" class="smallImgs beneficiarySmallImg">
                  <span v-text="$ml.with('VueJS').get('recentActionsTab1')"></span>
                </router-link>
              </li>
              <li>
                <router-link to="/beneficiaries">
                  <img src="../assets/images/people.png" class="smallImgs groupSmallImg">
                  <span v-text="$ml.with('VueJS').get('recentActionsBeneficiaries')"></span>
                </router-link>
              </li>
              <li>
                <router-link v-bind:to="'/transfer'">
                  <img src="../assets/images/paper-plane.png" class="smallImgs paperPlaneImg">
                  <span v-text="$ml.with('VueJS').get('recentActionsTransfer')"></span>
                </router-link>
              </li>
            </ul>
            <div class="tab-content">
              <div v-show="noTransactions" class="no-actions">
                <p><span v-text="$ml.with('VueJS').get('noRecentActions')"></span></p>
                <p><span v-text="$ml.with('VueJS').get('noRecentActions1')"></span></p>
                <p><span v-text="$ml.with('VueJS').get('noRecentActions2')"></span></p>
              </div>
              <div class="list-actions">
                <div v-for="(action, index) in filteredTransactions" :key="index" :set="cashBack = isCashBack(action)"
                  @click="showDetail(action, index)" :class="itemClasses(action)">
                  <i class="icon icon-action" :class="iconClass(action)"></i>
                  <div class="wrap-row" :set="payment = action.transactionClass === 'PAYMENT'">
                    <div class="item-row" :set="max = action.transactionClass === 'MAX_LOAD'">
                      <ul>
                        <li class="item-title">
                          <template v-if="action.otherSide == ''">
                            {{ action.descCode ? $ml.get(action.descCode) : action.settings }}
                          </template>
                          <template v-else-if="payment"><span v-text="$ml.with('VueJS').get('paymentFrom')"></span> {{
              action.otherSide }}</template>
                          <template v-else>{{ action.otherSide }}</template>
                        </li>
                        <li class="text-gray">{{ action.amount | formatComma }} {{ action.currency }}
                          <template v-if="action.expired == 1 && action.transactionStatus == 'STAGED'">
                            <span class="text-blue">-{{ $ml.with('VueJS').get('expired') }}</span>
                          </template>
                          <template v-if="action.expired != 1 && action.transactionStatus == 'STAGED'">
                            <span class="text-blue">{{ $ml.with('VueJS').get('waitingPayment') }}</span>
                            <span class="text-blue"> {{ action.correspondentReference }}</span>
                          </template>
                          <template v-if="action.transactionStatus == 'CANCELED'">
                            <span class="text-blue"> -{{ $ml.with('VueJS').get('canceled') }}</span>
                          </template>
                        </li>
                        <li class="text-gray"><span v-text="$ml.with('VueJS').get('sentOn')"></span> {{ action.date }}
                        </li>
                      </ul>
                    </div>
                    <div class="sub-row">
                      <ul>
                        <li v-if="action.transactionStatus == 'STAGED' && action.correspondentReference">
                          <span v-text="$ml.with('VueJS').get('payingReference')"></span>:
                          {{ action.correspondentReference }}
                        </li>
                        <li v-else>
                          <span v-text="$ml.with('VueJS').get('numberOfTransaction')"></span>:
                          {{ action.gmtId }}
                        </li>
                        <li
                          v-if="!payment && !max && !cashBack && action.transactionClass !== 'WALLET_DEPOSIT' && action.transactionClass !== 'WALLET_TO_WALLET' && action.transactionClass !== 'WALLET_FROM_WALLET'">
                          <span v-text="$ml.with('VueJS').get('transferType')"></span>:
                          {{ $ml.with('VueJS').get(action.countryIso == 'ISR' ? action.transactionClass + '_ISR' :
              action.transactionClass) }}
                        </li>
                        <li v-if="action.beneficiaryBankName != ''">
                          <span v-text="$ml.with('VueJS').get('bankName')"></span>:
                          {{ action.beneficiaryBankName }}
                        </li>
                        <li v-if="action.beneficiaryBankBranch != ''">
                          <span v-text="$ml.with('VueJS').get('branchNumber')"></span>:
                          {{ action.beneficiaryBankBranch }}
                        </li>
                        <li v-if="action.beneficiaryBankAccount != ''">
                          <span v-text="$ml.with('VueJS').get('bankAccount')"></span>:
                          {{ action.beneficiaryBankAccount }}
                        </li>
                        <li v-if="action.beneficiaryCardNumber != ''">
                          <span v-text="$ml.with('VueJS').get('cardNumber')"></span>:
                          {{ action.beneficiaryCardNumber }}
                        </li>
                        <li v-if="action.transactionClass == 'CASH_TRANSFER'">
                          <span v-text="$ml.with('VueJS').get('system')"></span>:
                          {{ action.correspondent }}
                        </li>
                        <li
                          v-if="action.countryIso != '' && action.transactionClass !== 'WALLET_TO_WALLET' && action.transactionClass !== 'WALLET_FROM_WALLET'">
                          <span v-text="$ml.with('VueJS').get(payment ? 'countryFrom' : 'destination')"></span>:
                          {{ country(action.countryIso) }}
                        </li>
                      </ul>
                      <ul class="border">
                        <li>
                          <span v-text="$ml.with('VueJS').get('amount')"></span>:
                          {{ action.amount | formatComma }} {{ action.currency }}
                        </li>
                        <li v-if="!payment && action.customerCommission > 0">
                          <span
                            v-text="$ml.with('VueJS').get(max ? 'customerCommissionMaxTopup' : 'customerCommission')"></span>:
                          {{ max ? action.customerCommission - action.agentCommission : action.customerCommission }} {{
              action.currency }}
                        </li>
                        <li v-if="!payment && action.discount">
                          <span v-text="$ml.with('VueJS').get('discount')"></span>:
                          {{ action.discount }} {{ action.currency }}
                        </li>
                        <li v-if="action.agentCommission">
                          <span
                            v-text="$ml.with('VueJS').get(max ? 'maxAccessionCommission' : 'foreignCurrencyHandlingFee')"></span>:
                          {{ action.agentCommission }} {{ action.currency }}
                        </li>
                        <li v-if="action.transactionClass !== 'WALLET_FROM_WALLET'">
                          <span v-text="$ml.with('VueJS').get('totalTransfer')"></span>:
                          {{ max ? action.totalSum - action.agentCommission : action.totalSum | formatComma }} {{
              action.currency }}
                        </li>
                        <li v-if="action.cashBackCredit">
                          <span v-text="$ml.with('VueJS').get('cashBackCredit')"></span>:
                          {{ action.cashBackCredit }} {{ action.cashBackCurrency }}
                        </li>
                        <li v-show="action.receiveAmount && action.currency != action.payoutCurrency">
                          <span v-text="$ml.with('VueJS').get('amountReceived')"></span>:
                          {{ action.receiveAmount | formatComma }} {{ action.payoutCurrency }}
                          <span
                            v-if="action.transactionStatus == 'STAGED' && action.currency !== action.payoutCurrency">
                            ~{{ $ml.with('VueJS').get('estimated') }}
                          </span>
                        </li>
                      </ul>
                      <ul>
                      </ul>
                      <div class="btn-row">
                        <span class="loader_button">
                          <img src="../assets/images/loading.gif">
                        </span>
                        <span class="btn-status">
                          <span v-if="action.canCheckStatus && action.transactionStatus != 'CANCELED'">
                            <a class="btn btn-border " :class="{ 'active': !statusBtn }" v-text="statusText"
                              @click.prevent="checkStatus(index, action.gmtId)" @click.stop></a>
                          </span>
                        </span>
                        <span
                          v-if="action.canClone && action.transactionStatus != 'STAGED' && action.transactionStatus != 'CANCELED' && action.transactionClass !== 'WALLET_FROM_WALLET'">
                          <a class="btn btn-border btn-clone" @click="repeatAction(index)" @click.stop
                            v-text="$ml.with('VueJS').get('clone')"></a>
                        </span>
                      </div>
                    </div>
                    <i v-show="!cashBack" class="icon icon-arrow-down-circle"></i>
                  </div>
                </div>
              </div>
              <div v-show="busy" class="loader_icon">
                <img class="loading" src="../assets/images/loading.gif" />
              </div>
              <a class="icon-plus mobile-icon pointer" @click="showActions"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="add_action" class="modal-container add_action">
      <button class="fancybox-close-small" title="Close" @click="closeModal()"></button>
      <div class="list-actions">
        <router-link v-bind:to="'/card_main'" class="item-action" v-if="customerHaveCards">
          <i class="icon icon-card-max-b"></i>
          <span v-text="$ml.with('VueJS').get('cardRoute')"></span>
        </router-link>
        <router-link :to="createBeneficiaryRoute" class="item-action">
          <i class="icon icon-user-add-b"></i>
          <span v-text="$ml.with('VueJS').get('recentActionsTab1')"></span>
        </router-link>
        <router-link to="/beneficiaries" class="item-action">
          <i class="icon icon-user-2-fab"></i>
          <span v-text="$ml.with('VueJS').get('recentActionsBeneficiaries')"></span>
        </router-link>
        <router-link v-bind:to="'/transfer'" class="item-action">
          <i class="icon icon-transfer-fab"></i>
          <span v-text="$ml.with('VueJS').get('recentActionsTransfer')"></span>
        </router-link>
        <router-link v-bind:to="'/card_main'" class="item-action" v-if="!customerHaveCards">
          <i class="icon icon-card-max-b"></i>
          <span v-text="$ml.with('VueJS').get('cardRoute')"></span>
        </router-link>
        <!--<a href="#" class="item-action">
            <i class="icon icon-plane-b"></i>
            <span>מט"ח לנתב"ג</span>
        </a>
        <a href="#" class="item-action">
            <i class="icon icon-card-b"></i>
            <span>הטענת כרטיס</span>
        </a>
        <a href="#" class="item-action">
            <i class="icon icon-money-b"></i>
            <span>קבלת כסף</span>
        </a>
        <a href="#" class="item-action">
            <i class="icon icon-currency-b"></i>
            <span>רכישת ביטקוין</span>
        </a>-->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { checkStatus, getTransactions } from "../services/api";
import { getCountryNameByIso, localesDictionary, modalHandler } from "@/services/utils"
import { Validator } from "vee-validate";
import Wellcome from '../components/Wellcome.vue';
import Footer from "@/components/Footer.vue";

const icons = {
  CASH_BACK: 'fas fa-gift',
  MAX_LOAD: 'fa fa-cc-visa',
  CARD_DEPOSIT: 'fa fa-credit-card',
  PAYMENT: 'fas fa-hand-holding-usd',
  BANK_TRANSFER: 'fa fa-university bank',
  WALLET_DEPOSIT: 'fas fa-wallet',
  WALLET_TRANSFER: 'fas fa-wallet',
  WALLET_TO_WALLET: 'fas fa-users',
  WALLET_FROM_WALLET: 'fas fa-hand-holding-usd'
}

export default {
  name: 'RecentActions',
  components: { MainNav, Header, Wellcome, Footer },
  data() {
    return {
      transactions: [],
      newTransactions: [],
      searchTransactions: '',
      customerHaveCards: JSON.parse(localStorage.getItem("registrationData")).isCustomerHaveCards,
      statusText: '',
      statusBtn: true,
      noTransactions: false,
      page: 0,
      busy: false,
      loading: false,
      email: ''
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    createBeneficiaryRoute() {
      return { name: 'beneficiary', params: { action: 'create' } }
    },
    filteredTransactions() {
      return this.transactions
        .filter(item => {
          return item.otherSide.toLowerCase().indexOf(this.searchTransactions.toLowerCase()) > -1
            || item.correspondentReference == this.searchTransactions ||
            item.country.toLowerCase().indexOf(this.searchTransactions.toLowerCase()) > -1;
        })
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.addListener();
    this.loading = true;
    this.loadTransactions(this.page);
    this.$store.commit('setTransferStep1', {});
  },
  mounted() {
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);

    document.body.setAttribute('dir', this.getDir);
  },
  beforeDestroy() {
    this.removeListener();
  },
  methods: {
    infiniteScroll() {
      if ($(window).scrollTop() + window.innerHeight >= document.body.scrollHeight - 1) {
        if (this.busy || !this.newTransactions.length)
          return;
        this.busy = true;
        this.loadTransactions(++this.page)
      }
    },
    loadTransactions(page) {
      getTransactions(page, (data) => {
        this.newTransactions = _.orderBy(data, [function (o) {
          return new Date(moment(o.date, 'DD/MM/YYYY'));
        }], ['desc']);
        this.transactions = [...this.transactions, ...this.newTransactions];
        this.noTransactions = this.transactions.length == 0
        this.busy = false;
        this.loading = false;
        if (!this.newTransactions.length) {
          this.removeListener();
        }
      });
    },
    repeatAction(index) {
      let transaction = this.filteredTransactions[index];
      this.$store.commit('setTransferStep1', {
        amount: null,
        currency: transaction.currency,
        destinationCountry: transaction.countryIso,
        transactionClass: transaction.transactionClass,
        correspondentName: transaction.correspondent
      });
      this.$store.commit('setCurrentBeneficiary', transaction.beneficiaryId);
      if (transaction.transactionClass === 'WALLET_TO_WALLET') {
        this.$router.push("/wallet/transfer/friend");
      } else if (transaction.transactionClass === 'WALLET_DEPOSIT') {
        this.$router.push("/wallet/transfer");
      } else {
        this.$router.push("transfer");
      }
    },
    showDetail(it, index) {
      if (this.isCashBack(it)) return
      let $action = $('.item-action:eq(' + index + ')');
      if ($action.hasClass('open')) {
        $action.removeClass('open');
      } else {
        $('.item-action').removeClass('open');
        $action.addClass('open');
        this.statusText = this.$ml.with('VueJS').get('checkStatus');
        this.statusBtn = true;
      }
    },
    checkStatus(index, transactionId) {
      if (this.statusBtn) {
        $('.loader_button:eq(' + index + ')').show();
        $('.btn-status:eq(' + index + ')').hide();
        checkStatus({ "transactionId": transactionId }, response => {
          this.statusText = this.$ml.with('VueJS').get(response ? response : 'STATUS_NOT_FOUND');
          this.statusBtn = false;
          $('.loader_button:eq(' + index + ')').hide();
          $('.btn-status:eq(' + index + ')').show();
        });
      }
    },
    showActions(index) {
      modalHandler('.add_action', 'show', '092086', 'light');
    },
    closeModal() {
      modalHandler('.add_action', 'hide', 'ffffff', 'dark')
    },
    addListener() {
      window.addEventListener('scroll', this.infiniteScroll, true);
      window.addEventListener('touchmove', this.infiniteScroll, true);
    },
    removeListener() {
      window.removeEventListener('scroll', this.infiniteScroll, true);
      window.removeEventListener('touchmove', this.infiniteScroll, true);
    },
    isCashBack(action) {
      return action.transactionClass === 'CASH_BACK';
    },
    iconClass(action) {
      return action.transactionClass === 'WALLET_TRANSFER' && action.countryIso === 'CHN' ? action.correspondentIdentifier === 'THUNES_WECHATPAY' ? 'fa fa-weixin' : 'fab fa-alipay' : (icons[action.transactionClass] || 'fa fa-money');
    },
    itemClasses(action) {
      return ['item-action', { 'open': action.isActive }, { 'action-grey': action.transactionStatus === 'STAGED' || action.transactionStatus === 'CANCELED' }]
    },
    country(iso3) {
      return getCountryNameByIso(iso3);
    },
    dictionary() {
      return localesDictionary({ messages: { required: this.$ml.get("E_REQUIRED"), email: this.$ml.get("E_REGEX_email") } });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.block-recent-actions {
  background: url(../assets/images/bg-actions.png) no-repeat;
  background-position: left calc(100% - 30px);
  -webkit-background-size: contain;
  background-size: contain;
  min-height: calc(100vh - 71px);
  padding-top: 0;

  .smallImgs {
    vertical-align: middle;
    display: inline-block;
    height: 21px;
    margin-inline-end: 5px;
  }

  .visaImg {
    padding-bottom: 2px;
    width: 20px;
    padding-top: 2px;
  }

  .groupSmallImg {
    padding-top: 3px;
    width: 18px;
  }

  .paperPlaneImg {
    width: 18px;
    padding-top: 1px;
    padding-bottom: 3px;
  }

  .beneficiarySmallImg {
    width: 14px;
    padding-top: 4px;
    padding-bottom: 5px;
  }

  .content-top {
    height: 69px;
    padding: 10px 0;
  }

  .wrap {
    padding: 0;
  }

  .form-search {
    max-width: 739px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      height: 48px;
      font-size: 20px;
      padding: 0 18px 0 40px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
        color: #808080;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
        color: #808080;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
        color: #808080;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
        color: #808080;
      }
    }

    .icon-search {
      position: absolute;
      top: 17px;
      left: 23px;
    }
  }

  .body {
    max-width: 739px;
    margin: 43px auto 0;
  }

  .nav-tabs {
    margin-top: 22px;

    li {
      display: inline-block;
      vertical-align: middle;

      &+li {
        margin-right: 15px;
      }

      a {
        font-size: 18px;

        &:before {
          display: inline-block;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }

  .loader_icon {
    width: 6%;
    margin: auto;
    margin-top: 10px;
  }

  .no-actions {
    color: grey;
    text-align: center;
    margin-top: 30%;
  }

  .list-actions {
    margin-top: 17px;
  }

  .item-action {
    width: 100%;
    height: 78px;
    overflow: hidden;
    border: 2px solid #ececec;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    position: relative;
    padding: 6px 16px 20px;
    line-height: 22px;
    font-size: 16px;
    display: flex;
    @include transition(all .4s ease);

    &:hover {
      background-color: #f2f2f2;
    }

    &.open {
      height: auto;
      overflow: visible;

      .icon-arrow-down-circle {
        background-image: url(../assets/images/icons/i-arrow-up-circle.png);
      }
    }

    &+.item-action {
      margin-top: 3px;
    }

    .item-title {
      font-size: 20px;
    }

    .text-gray {
      color: #767676;
    }

    .text-blue {

      color: #092086;

    }

    .icon-arrow-down-circle {
      position: absolute;
      top: 10px;
      left: 15px;
    }

    .icon-action {
      width: 38px;
      height: 38px;
      font-size: 20px;
      text-align: center;
      color: #ffffff;
      background-color: $mainColor;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      margin: 8px;
      margin-inline-end: 15px;
      line-height: 40px;
      cursor: pointer;

      &.bank {
        font-size: 17px;
        line-height: 38px;
      }
    }

    &.action-grey {
      .icon-action {
        background-color: #d8d8d8;
      }
    }
  }

  .sub-row {
    line-height: 19px;
    margin-top: 10px;

    .cashBack {
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      margin-top: 6px;
    }

    .btn-row {
      margin-inline-start: -15px !important;
    }

    .btn-border {
      background-color: transparent;
      border-color: $mainColor;
      color: $mainColor;
      margin: 16px 8px !important;
      min-width: 150px;
      display: inline-block;
      padding: 0 5px;

      &.active {
        border: none;
        font-size: 16px !important;
      }

      &.btn-clone {
        color: #ffffff;
        background-color: $mainColor;
      }
    }

    .loader_button {
      display: none;
    }
  }

  .border {
    margin-top: 6px;
    border-top: 0.5px solid rgb(209, 207, 207);
    // border-top: 0.5px solid rgb(6, 26, 136);
    padding-top: 4px;
    min-width: 220px;
  }

  .mobile-icon {
    display: none;
    position: fixed;
    left: 15px;
    bottom: 25px;
    width: 53px;
    height: 53px;

    &:before {
      width: 23px;
      height: 23px;
    }
  }
}

.add_action {
  animation: topDown ease 0.3s;
  display: none;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  padding: 24px 0;

  .list-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    .icon {
      margin: 0 auto;
      -webkit-background-size: cover;
      background-size: cover;
    }

    span {
      font-size: 30px;
      color: #ffffff;
    }
  }

  .fancybox-close-small {
    width: 106px;
    height: 106px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: rgb(213, 199, 65) !important;
    bottom: 30px;
    left: 30px;
    top: auto;
    background-image: none;

    &:before {
      content: '';
      display: block;
      width: 34px;
      height: 33px;
      background-image: url(../assets/images/icons/i-close-b.png);
      -webkit-background-size: cover;
      background-size: cover;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
}
</style>