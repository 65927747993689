import Vue from 'vue';
import { getBeneficiaries } from '../../services/api';
import { getCountryNameByIso } from '../../services/utils';
import store from './../index'

const state = {
    list: {},
    current: null,
    constraints: {},
    hasCard: false,
    updated: false,
    isValid: false
};

const mutations = {
    setListBeneficiary(state, data) {
        state.list = data;
    },
    setCurrentBeneficiary(state, data) {
        state.current = data;
    },
    setBeneficiaryUpdated(state, data) {
        state.updated = data;
    },
    setHasCard(state, data) {
        state.hasCard = data;
    },
    // removeBeneficiary(state, id) {
    //     Vue.delete(state.list, id);
    // },
    setBeneficiaryValid(state, data) {
        state.isValid = data;
    }
};

const getters = {
    getListBeneficiary(state) {
        return state.list;
    },
    getUpdatedBeneficiary(state) {
        return state.updated;
    },
    getSelectedBeneficiary(state) {
        return state.current;
    },
    getBeneficiary(state) {
        return state.list[state.current];
    },
    getHasCard(state) {
        return state.hasCard;
    },
    isBeneficiaryValid(state) {
        return state.isValid;
    }
};

const actions = {
    loadBeneficiary(context) {
        getBeneficiaries((data) => {
            let temp = {};

            data.forEach((value, key) => {
                temp[key] = value;
            });
            context.commit('setListBeneficiary', _.orderBy(temp, item => { return [getCountryNameByIso(item.countryIso3).toUpperCase(), item.firstName.toLowerCase()] }));
        });
    }
};

export default {
    state,
    mutations,
    getters,
    actions
}