import store from '../store'

export function dirMaskedCardNumber(maskedCardNumber) {
    return (store.getters.getDir == 'ltr') ? maskedCardNumber : maskedCardNumber.slice(maskedCardNumber.length - 4) + '******';
}

export function formatDecimal(value) {
    if (value !== undefined) {
        const str = Number(value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const splitted = str.split('.')
        return splitted[1] > 0 ? str : splitted[0]
    }
    return value
}

export function formatAmount(value) {
    if (value !== undefined && value !== '') {
        value = JSON.stringify(value);
        console.log("🚀 ~ formatAmount ~ value:", value);

        // Remove all non-numeric characters
        value = value.replace(/\D/g, '');

        // Remove leading zeros
        value = value.replace(/^0+/, '');

        // Return '0' if the resulting string is empty
        if (value === '') {
            return '0';
        }

        return value
    }
    return value;
}

export function formatComma(value) {
    if (isNaN(parseInt(value)) || (typeof value === 'string' && value.includes(','))) return value
    return Number(value).toLocaleString('en');
}

export function lowerCaseCapitalise(value) {
    if (!value || typeof value !== 'string') return value
    const chars = value.toLowerCase().split('')

    chars[0] = chars[0].toUpperCase()
    return chars.join('')
}

export function iso3ToSymbol(value) {
    switch (value) {
        case ("USD"):
            return "$"
        case ("ILS"):
            return "₪"
        case ("EUR"):
            return "€"
        default:
            return value
    }
}