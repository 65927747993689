<template>
  <div v-if="banners.length" class="marketing-banner-card-wrapper">
    <div class="marketing-banner-card-container">
      <section class="splide" aria-label="Marketing Carousel" id="marketingCarousel">
        <div class="splide__track">
          <ul class="splide__list">
            <li  v-for="banner in banners" class="splide__slide slide-content">
                <MarketingBanner @openYoutubeModal="openYoutubeModal($event)" :banner="banner"></MarketingBanner>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <YoutubeModal  ref="youTubeModalRef"></YoutubeModal>

  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import MarketingBanner from "./MarketingBanner.vue";
import { BannerService } from '@/services/BannerService';
import moment from "moment";
import YoutubeModal from "./YoutubeModal.vue";

export default {
  name: 'MarketingBanners',
  components: {MarketingBanner, YoutubeModal},
  props: {},
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile(),
      countryIso3: localStorage.getItem('country') && 'THA',
      banners:[]
    }
  },
  created() {
    const date = moment().format()
    const bannerService = new BannerService();
    const query = {
      language: this.getLang,
      origin: this.countryIso3,
      showType: this.getShowType,
      currentDate: date
    };
    this.banners = bannerService.getBannerByQuery(query).splice(0,3);
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },

    getShowType(){
      switch (true) {
        case this.isMobileDevice:
          return 'mobileShow';
        case this.isApp:
          return 'appShow';
        default:
          return 'desktopShow';
      }
    }

  },
  mounted() {
    if (!this.banners.length) return
    let isMobileDevice = isMobile();
    let paddingForSplide = { left: isMobileDevice ? '5%' : '0', right: isMobileDevice ? '5%' : '0' }
    let splideOptions = {
      isNavigation: false,
      arrows: false,
      padding: paddingForSplide,
      pagination: this.banners.length > 1,
      trimSpace: false,
      type: this.banners.length > 1 ? 'loop' : 'slide',
      gap: isMobileDevice ? '20px' : '10px',
      direction: this.getLang === 'il' ? 'rtl' : 'ltr',
      autoplay: true,
      pauseOnHover: true,
      classes: {
        pagination: 'splide__pagination carousel-slide-pagination ' + ((isMobileDevice ? '' : 'carousel-slide-pagination-desktop')),
        page: 'splide__pagination__page carousel-slide-pagination-page ' + ((isMobileDevice ? '' : 'carousel-slide-pagination-page-desktop')),
      },
    };
    var splide = new Splide('#marketingCarousel', splideOptions);
    splide.mount();
  },
  methods: {
    openYoutubeModal(url){
      this.$refs.youTubeModalRef.url = url
      this.$refs.youTubeModalRef.toggleModal()
      
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.marketing-banner-card-wrapper {
  height: 200px;
  border-radius: 20px;
}

.marketing-banner-card-container {
  margin-top: 20px;

  .slide-content {

    border-radius: 10px;
    border: none;
    height: 200px;
    color: white;
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    align-items: flex-start;
    justify-content: center;


    p {
      padding: 10px 0;
      font-size: 14px;
      opacity: 0.8;
    }

    h1 {
      font-size: 24px;
    }

    .carousel-button {
      background-color: white;
      font-size: 14px;
      color: #0E1F81; 
      font-weight: bold;
    }

    img {
      width: 100%;
      border-radius: 20px;
      height: 200px;

    }

 



  }  
}

@media only screen and (min-width: $screen-tablet) {

  .marketing-banner-card-container {
    margin-top: 0;
    height: 100%;

    #marketingCarousel {
      height: 100%;

      .splide__track {
        height: 100%;
      }
    }

    .slide-content {
      border-radius: 20px;
      height: 100%;
       img {
        height: 200px;
        width: 100%;
       }
    }
  }
}
</style>
