import Vue from 'vue'
import Router from 'vue-router'
import Login from './pages/Login.vue'
import Registration from './pages/Registration.vue'
import Calculator from './pages/Calculator.vue'
import CalculatorUnregistered from './pages/CalculatorUnregistered.vue'
import RecentActions from './pages/RecentActions.vue'
import Transfer from './pages/Transfer.vue'
import TransferMain from './pages/Transfer2/TransferMain.vue'
import Beneficiaries from './pages/Beneficiaries.vue'
import Beneficiary from './pages/Beneficiary.vue'
import RegistrationSteps from './pages/RegistrationSteps.vue'
import UserProfile from './pages/UserProfile.vue'
import { requireAuth, requireDefault } from './services/auth'
import store from './store'
import PaymentResponse from './pages/PaymentResponse.vue'
import CalcUnregisteredOptions from './pages/CalcUnregisteredOptions.vue'
import CashBack from './pages/CashBack.vue'
import Cashio from './pages/Cashio.vue'
import CreditCardPayment from './pages/CreditCardPayment.vue'
import CardMain from './pages/CardMain.vue'
import CardRecentActions from './pages/CardRecentActions.vue'
import WalletReceive from "@/pages/Wallet/Receive";
import WalletInvite from "@/pages/Wallet/Invite";
import Friends from "@/pages/Wallet/Friends";
import WalletTransfer from "@/pages/Wallet/Transfer";
import WalletCashDeposit from "@/pages/Wallet/Deposit/Cash";
import WalletBankDeposit from "@/pages/Wallet/Deposit/Bank";
import WalletBankConfirmation from "@/pages/Wallet/Deposit/BankConfirmation";
import WalletOrder from './pages/Wallet/WalletOrder';
import BiometricRegistration from './pages/BiometricRegistration.vue'
import Download from './pages/Download.vue'
import Contacts from "@/pages/Wallet/Contacts.vue";
import Home from './pages/Home.vue'
import { isDisplayingBanner } from "@/services/utils";
import TransactionsHistory from "@/pages/TransactionsHistory.vue";
import Cards from "@/pages/Cards.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            beforeEnter: requireDefault
        },
        {
            path: '/login',
            name: 'Login',
            mode: 'history',
            query: { lang: '' },
            component: Login
        },
        {
            path: '/registration',
            name: 'Registration',
            mode: 'history',
            query: { lang: '' },
            component: Registration
        },
        {
            path: '/registration/global',
            name: 'Registration',
            mode: 'history',
            query: { lang: '' },
            component: Registration
        },
        {
            path: '/calculator',
            name: 'calculator',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Calculator
        },
        {
            path: '/calculator-unregistered',
            name: 'calculator-unregistered',
            mode: 'history',
            query: { lang: '' },
            //beforeEnter: checkAuth,
            component: CalculatorUnregistered
        },
        {
            path: '/calc-unregistered-options',
            name: 'calc-unregistered-options',
            mode: 'history',
            //beforeEnter: checkAuth,
            component: CalcUnregisteredOptions
        },
        {
            path: '/transfer',
            name: 'transfer',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Transfer,
        },   {
            path: '/transfernew',
            name: 'transfernew',
            mode: 'history',
            beforeEnter: requireAuth,
            component: TransferMain,
        },
        {
            path: '/recent_actions',
            name: 'recent_actions',
            mode: 'history',
            beforeEnter: requireAuth,
            component: RecentActions
        },
        {
            path: '/card_main',
            name: 'card_main',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Cards
        },
        {
            path: '/beneficiaries',
            name: 'beneficiaries',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Beneficiaries
        },
        {
            path: '/beneficiary/:action',
            name: 'beneficiary',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Beneficiary,
            props: true
        },
        {
            path: '/transfer/beneficiary/:id?/:action',
            name: 'transfer-beneficiary',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Beneficiary,
            props: true
        },
        {
            path: '/registration_steps',
            name: 'registration_steps',
            mode: 'history',
            component: RegistrationSteps
        },
        {
            path: '/user-profile',
            name: 'user-profile',
            mode: 'history',
            beforeEnter: requireAuth,
            component: UserProfile
        },
        {
            path: '/cash-back',
            name: 'cash-back',
            mode: 'history',
            beforeEnter: requireAuth,
            component: CashBack
        },
        {
            path: '/payment_response',
            name: 'payment_response',
            mode: 'history',
            query: { Id: '', CCode: '', Amount: '', ACode: '', Order: '', Fild1: '', Fild2: '', Fild3: '', Sign: '' },
            beforeEnter: requireAuth,
            component: PaymentResponse
        },
        {
            path: '/credit_card_payment',
            name: 'credit_card_payment',
            mode: 'history',
            beforeEnter: requireAuth,
            component: CreditCardPayment
        },
        {
            path: '/wallet',
            name: 'wallet-main',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Home
        },
        {
            path: '/cashio',
            name: 'cashio',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Cashio
        },
        {
            path: '/wallet/receive',
            name: 'wallet-receive',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletReceive
        },
        {
            path: '/wallet/invite',
            name: 'wallet-invite',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletInvite
        },
        {
            path: '/i/:invitedBy',
            name: 'registration',
            mode: 'history',
            component: Registration,
            props: true
        },
        {
            path: '/wallet/friends',
            name: 'wallet-friends',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Friends,
        },
        {
            path: '/wallet/deposit/cash',
            name: 'wallet-cash-deposit',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletCashDeposit,
        },
        {
            path: '/wallet/deposit/bank',
            name: 'wallet-bank-deposit',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletBankDeposit,
        },
        {
            path: '/wallet/deposit/bank/upload',
            name: 'wallet-bank-confirmation',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletBankConfirmation,
        },
        {
            path: '/wallet/transfer/:mode?',
            name: 'wallet-transfer',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletTransfer,
            props: true
        },
        {
            path: '/wallet/WalletOrder',
            name: 'wallet-order',
            mode: 'history',
            beforeEnter: requireAuth,
            component: WalletOrder,
            props: true
        },
        {
            path: '/biometricRegistration',
            name: 'biometricRegistration',
            mode: 'history',
            component: BiometricRegistration,
            props: true
        },
        {
            path: '/download',
            name: 'Download',
            mode: 'history',
            component: Download
        },
        {
            path: '/TransactionsHistory',
            name: 'TransactionsHistory',
            mode: 'history',
            beforeEnter: requireAuth,
            component: TransactionsHistory
        },
        {
            path: '/Cards',
            name: 'Cards',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Cards
        },
        {
            path: '/Home',
            name: 'Home',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Home
        },
        {
            path: '/wallet/contacts',
            name: 'Contacts',
            mode: 'history',
            beforeEnter: requireAuth,
            component: Contacts
        },
        {
            path: '*',
            name: 'recent_actions',
            mode: 'history',
            beforeEnter: requireAuth,
            component: TransactionsHistory
        },
    ]
});

router.beforeEach((to, from, next) => {
    if (store.getters.getLoggedIn && localStorage.getItem('token') && !Object.keys(store.state.beneficiary.list).length) {
        store.dispatch('loadBeneficiary');
    }
    let routesToShow = ["Login", "Registration", "wallet-main", "recent_actions", "Download"];
    let currentRoute = to.name
    if (isDisplayingBanner() && routesToShow.includes(currentRoute)) {
        $("#smartAppBannerElement").show()
    } else {
        $("#smartAppBannerElement").hide()
    }
    next();
});

export default router;