export const cn = {
    aboutLink: "ch/my-gmt/",
    accessibleBranch: "活跃的网点",
    accountNumber: "收款人账户",
    activityTime: "活动时间",
    actualRate1: "线上",
    and: '和',
    appDownload: '下载我们的应用程序',
    appDownloadBtn: '直接下载 \n 到您的手机',
    actualRate2: "当前汇率是",
    addAddress: "添加地址",
    additionalPhone: "额外的电话号码",
    amount: "数额",
    AMOUNT_LIMITED: "很抱歉，此金额已超出该受益人的账户限额。 您可以尝试减少数额并重新输入。 如仍需帮助，请与我们联系。",
    amountDestination: "将收到的数额",
    amountIn: "谢克尔金额",
    amountReceived: "将收到的数额",
    amountTogether: "合计金额",
    anotherSurname: "额外的姓氏",
    answerQuestion: "与此同时，您可能可以找到问题的答案",
    API_EXCEPTION: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    approvalTransaction: "一切顺利！ 扣款成功，转账已批准。 在接下来的几分钟内，您将收到一条交易确认短信",
    maxCardLoadTransaction: 'Wonderful! The charge was successful, and your myGMT Card was reloaded with money ',
    approvedPolicies: "我已阅读并同意相关条款",
    arrivalBranch: "在GMT网点的现金",
    arrivalBranchMsg1: "我们现在会给您一个付款用的订单号。该订单号的有效时间为24小时。",
    arrivalBranchMsg2: "并会按照线下门店汇率支付",
    BAD_REQUEST: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    BANK_TRANSFER: "转到海外账户",
    BANK_TRANSFER_ISR: "转到账户",
    UPI_BANK_TRANSFER: 'Deposit to Account abroad by UPI',
    BANK_TRANSFER_TO: "转到在{dest}的账户",
    BANK_TRANSFER_PAYMENT: '银行转账',
    bankAccount: "银行账户",
    bankCode: '银行账号',
    bankName: "银行名称",
    bank: '银行',
    beneficiary: {
        abaRoutingNumber: "银行识别代码/美国银行协会ABA识别代码",
        accountIdentifier: "账号",
        walletIdentifier: '钱包号码',
        cashBankName: '提现点',
        walletBankName: '钱包名字',
        alipayWallet: '支付宝账号',
        address: "地址",
        bankAddress: "银行地址",
        bankCity: "银行所在城市",
        bankName: "银行名称",
        branch: "银行支行",
        city: "城市",
        country: "目的地",
        firstName: "名字的汉语拼音",
        iban: "IBAN国际银行账户号码",
        ifsc: "IFSC",
        lastName: "姓氏的汉语拼音",
        maskedCardNumber: "收款人信用卡卡号",
        maskedCardNumber_unionPay: "收款人银联卡卡号",
        phoneNumber: "收款人电话号码",
        province: "省/州",
        relationshipWithCustomer: "您与收款人的关系是？",
        swiftCode: "银行识别码SWIFT/BIC",
        zipCode: "邮政编码",
        identification: "收款人身份证号",
        upi: "UPI ID or number",
        upi_IND: "UPI ID or number",

    },
    transfer: {
        deliveryOptions: {
            ALL: '全部',
            CASH_TRANSFER: '现金转账',
            BANK_TRANSFER: '银行转账',
            WALLET_DEPOSIT: '钱包存款',
            WALLET_FROM_WALLET: '钱包存款',
            WALLET_TO_WALLET: '钱包存款',
            CARD_DEPOSIT: '银行卡存款'
        },
        deliveryOptionsInfo: {
            iSend: '我发送',
            iPay: '我支付',
            theyRecieve: '他们接收'
        },
        titles: {
            chooseAmount: '选择金额',
            chooseBeneficiaty: '选择受益人',
            chooseDestination: '选择目的地',
            recentDestinations: '您最近的目的地',
            allDestinations: '所有目的地',
            popularDestinations: '热门目的地',
            sendMoney: '发送资金',
            nothingFound: '未找到',
            typedCorrectly: '请确保您正确输入了国家名称。'
        },
        buttons: {
            select: '选择',
            addNewBeneficiary: '添加新受益人',
            coupon: {
                iHaveACoupon: '我有优惠券',
                couponApplied: '优惠券"{couponNumber}"已应用！'
            },
            back: '返回'

        },
        paymentMethods: {
            titles: {
                payingWith: '使用{amount}支付'
            },
            subtitles: {
                walletBalance: '您的钱包余额是',
                creditCard: '我们接受Visa、万事达卡、AMEX、大来卡、Apple Pay、Google Pay',
                bankTransfer: '直接转账到银行账户'
            }
        },
        countrySearchPlaceholder: '搜索目的地或货币'
    },
    BENEFICIARY_CARD_BLOCKED: 'This card number or the bank are not supported by the service, you can try to send to a different card',
    BENEFICIARY_INVALID: "很抱歉，该转账收款人的信息有误，请修改后重试。如仍需帮助，请联系我们。",
    BENEFICIARY_NOT_FOUND: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    beneficiaryRemoveDialogue: "确认需要从收款人列表中删除 {beneficiary} 吗？",
    beneficiaryRemoveDialogue2: "该操作将不会影响已存在的转账",
    billingSuccessful: "一切顺利！ 扣款成功，您的转账已被批准。 ",
    billingSuccessfulBankTransfer: "一切顺利！ 扣款成功，您的转账已被批准。 ",
    billingSuccessfulBankTransferISR: "一切顺利！ 扣款成功，您的转账已被批准。 ",
    billingSuccessfulCardDeposit: "一切顺利！ 扣款成功，您的转账已被批准。 ",
    BLACKLIST: "很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。",
    BLACKLIST_CARD: "很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。",
    blocked: "受限",
    blog: "博客",
    bntClear: "取消",
    bntContinue: "下一步",
    bntContinued: "下一步",
    bntImgDelete: "重新上传",
    bntSave: "保存",
    branchNumber: "支行",
    btnAccept: "接受",
    btnCheckStatusCheck: "付款就绪",
    btnCheckStatusNoCheck: "无法付款",
    btnClose: "关闭",
    btnDelete: "删除",
    btnDone: "确认",
    btnEnter: "让我们开始吧",
    btnFinish: "结束",
    btnFinishREgistration: "补全注册",
    btnOk: "同意",
    btnRegistration: "创建账户",
    btnReturn: "返回",
    btnSearch: "搜索",
    btnSend: "发送",
    btnSignIn: "通过短信发给我验证码",
    btnBiometricSignInFaceId: '面部识别登录',
    btnBiometricSignInTouchId: '指纹识别登录',
    btnToMyGmt: "到myGMT",
    btnTransfer: "转账",
    btnTransferFooter: "转账",
    btnUploadDocuments: "添加文档",
    business: "职业",
    biometricsHeader: '生物识别',
    biometricSectionApprove: '我批准T＆C',
    biometricSectionSignIn: '要完成生物识别的注册，请登录帐户。',
    biometricToggler: '生物识别登录',
    biometric_modal_text: '您要取消快速生物识别登录吗？',
    biometric_legal_clause_1: '当您进入系统的用户名关联的所有帐户时，生物识别验证（使用指纹和/或面部识别）将代替输入密码。',
    biometric_legal_clause_2: '公司不储存用户的面部识别,该验证仅于用户移动设备上的生物识别定义。',
    biometric_legal_clause_3: '该服务的注册授权输入网站/应用程序的个人声明，他们应是具有生物识别设备的唯一用户。 如果在设备上更新了几个人的生物识别，则不应使用它来注册该服务。',
    biometric_legal_clause_4: '请求在网站/应用程序上执行的信息和/或执行操作将被视为授权给公司执行操作，包括违反第三方执行上面第3节的操作。',
    biometric_legal_clause_5: '如果丢失和/或被盗移动设备，则必须通过电话与客户服务联系立即取消该服务的注册。',
    biometric_legal_clause_6: '您可以在应用程序中或与客户服务联系随时断开服务。',
    biometric_login_failed: '抱歉验证失败',
    biometric_public_key_expired:'抱歉给您带来不便！ 我们禁用了您的生物识别登录，这是为了保障您的帐户安全。 请再次重新注册生物识别登录。 谢谢您！',
    biometric_unregister_modal_title: '关闭生物识别登录',
    byExchangeRate: "汇率",
    calculatedAbroad: "将被在海外计算",
    canceled: "已取消",
    CAPTCHA_NOT_VERIFIED: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    captionAgreement: "我已阅读并同意",
    captionAgreementLink: "条款",
    captionCustomerService1: "请联系我们的客服，我们将竭诚为您服务。",
    CARD_DENIED: "很抱歉，向该卡转账失败。如仍需帮助，请联系我们。",
    CARD_DEPOSIT: "到海外银行卡",
    CARD_DEPOSIT_TO: "到在{dest}的卡",
    CARD_DEPOSIT_MAX: '卡片充值',
    CARD_REJECTED: "很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。",
    CARD_WRONG_DETAILS: "很抱歉，本次转账信息有误，请修改后重试。如仍需帮助，请与我们联系。",
    cardActionsModalTitle: "卡片操作",
    cardBlock: "封锁卡片",
    cardBlockBtn: "阻止",
    cardBlockConfirm: {
        1: "我们为您限制了该卡，您可在网站上重新激活。",
        2: "如遇任何问题，请及时与我们联系。",
        3: "联系我们"
    },
    walletOrder: {
        deposited: '存入',
        verification: '这是您的代码',
        getVerification: '获取您的代码',
        showTo: '出示给工作人员',
        expiry: '代码将在',
        minutes: '分钟后过期',
        returnHere: '您可以随时从返回这里',
        mainPage: '主页',
        cancel: '取消此订单',
        hooray: '万岁',
        close: '关闭',
        depositedToYourWallet: '我们将 ',
        depositedToYourWallet1: '存入您的钱包了',
        cancelModalTitle: '您想取消这个订单吗？',
        cancelModalText: '您总是可以重新操作一次',
        goToActiveOrder: '您的存款代码',
        depositCash: '将现金存入您的钱包',
        btnOk: '批准',
        btnReturn: '返回',
        amount: '数额',
        summaryOfDetails: '转账详情摘要',
        transferType: '传输类型'
    },
    cardBlockModalMessage: {
        1: "在您按下“阻止”按钮后，我们将暂时阻止您的卡进行新的交易或提取现金。",
        2: "您的余额已在卡上保存！",
        3: "如果您找到了该卡，您可以在这里重新激活它，或者您可以要求我们永久将其冻结并为您补发一张新卡"
    },
    cardDepositAccepted: "该服务仅适用于 Visa / Mastercard万事达",
    cardHystory: "过往交易",
    cardMargketing: {
        btn: "向我们发送Whatsapp消息",
        footer: {
            1: "新卡可在GMT网点获取",
            2: "欲了解更多详情和订购卡片"
        },
        info1: {
            con1: "在以色列、海外或线上购买",
            con2: "在以色列或海外的任意ATM取钱",
            title: "卡片权益"
        },
        info2: {
            con1: "查看您的卡片余额",
            con2: "追踪购买",
            con3: "恢复您的Pin密码",
            con4: "限制一张丢失或被偷窃的卡片",
            title: "您可以"
        },
        subtitle: "我们很高兴为您提供无需银行账户即可掌控开支的便捷方式！",
        title: "预付卡与 {max}  合作"
    },
    cardMerchantAddress: "地址",
    cardMerchantPhone: "电话",
    cardNumber: "卡号",
    cardPresented: "现场刷卡",
    cardRoute: "预付卡",
    cardUnBlock: "激活已受限卡片",
    cardUnBlockBtn: "激活",
    cardUnBlockConfirm: "您的卡片现已激活",
    cardUnBlockModalMessage: {
        1: "按下“激活”按钮后，您的卡将被激活以进行新的支付交易和现金提取",
        2: "请仅在您拥有该卡时才进行该操作"
    },
    cashioPageTtile: 'Foreign currency exchange services including delivery or airport pickup',
    CASH_TRANSFER: "国外取现",
    CASH_TRANSFER_INLAND: "国外取现",
    CASH_TRANSFER_ISR: "现金提取",
    CASH_TRANSFER_TO: "在{dest}取现",
    cashBack: "我的返现",
    cashBackReceive: '得到返现',
    cashBackLabel: 'CashBack',
    cashBackBalance1: "嘿，您有返现!",
    cashBackBalance2: "您的余额为: ",

    cashBackCredit: "兑换返现礼金",
    cashBackModalMessage: "惊喜！",
    cashBackModalMessage2: "您有",
    cashBackModalMessage3: "来自我们的",
    cashBackModalMessage4: "您可以现在使用并立即省钱",
    cashBackModalMessageCancel: "我将继续收集",
    cashBackModalMessageConfirm: "太好了！立刻使用",
    cashBackPageFotter: "您将收到转账同等货币的返现",
    cashBackPageFotter2: "您可以在myGMT或任意GMT网点（即将开放）使用您的返现",
    cashBackPageFotter3: "如果您超过六个月没有访问我们，您的返现礼金将被清空。",
    cashBackPageFotter4: "请阅读政策及条款全文",
    cashBackPageMessage2: "阅读政策及条款全文",
    cashBackPageMessage3: "一旦您达到",
    cashBackPageMessage4: "美元/英镑 您可以开始使用或继续收存",
    cashBackPageMessagePlus: "如何得到更多返现？",
    cashBackPageMessageZero: "如何获得返现？",
    cashBackPageTitlePlus: "真棒！您已收到",
    cashBackPageTitleZero: "返现",
    chatRepresentative: "和我们聊聊",
    checkStatus: "查看状态",
    status: "查看状态",
    changeAmount: '想要修改转账金额吗?',
    changePayoutAmount: '想要修改收款金额吗?',
    changeAmountLabel: '{amount} 的新金额',
    changeAmounBtn: '计算',
    city: "城市",
    clone: "重新发送",
    commission: "手续费",
    COMPANY_IDENTIFICATION: "很抱歉，您似乎尝试使用商务号码注册。 您可以使用身份证号码注册，我们很乐意为您解答任何问题",
    confirmBankAccount: "重要！ 请仔细检查账户明细，已存入海外账户的资金无法退还。",
    confirmBankAccount2: "收款人账户将在大约 2-4 个工作日内到账。",
    confirmCreditNumber: "重要！ 请仔细检查账户明细，已存入海外账户的资金无法退还。",
    confirmMarketingContent: "我想接收营销内容和更新",
    connectionError: "连接错误",
    Contact: "联系",
    CONTACT_DENIED: "很抱歉，为了保护您的财产安全，我们不得不拒绝此转账，且转账未成功。 如仍需帮助，请与我们联系。",
    CONTACT_GENERAL: "很抱歉，我们正在更新网站以改进我们的服务。 建议稍后再试。 如仍需帮助，请与我们联系。",
    contactBtnCaption: "服务页面",
    contactMenuCaption2: "微信",
    contactMenuCaption3: "致电",
    contactMenuCaption4: "Whatsapp",
    contactUsLink: "en/contact-us/",
    cookies: '该网站使用cookie文件,如果您继续浏览该网站,代表您同意使用cookies,欲了解更多详情，请参阅<a style="text-decoration:underline;display: inline;" href="https://gmtonline.co.il/en/privacy/" target="_blank">隐私政策</a>',
    CORRESPONDENT_BLOCKED: "很抱歉，此服务正在维护中，建议您稍后再试。 如仍需帮助，请与我们联系。",
    CORRESPONDENT_EXCEPTION: "十分抱歉，我们在尝试将您的转账发送到国外时出错。 请与我们联系，我们将及时检查并为您提供帮助。",
    correspondentReference: "交易参考号",
    correspondentSystem: "的网点",
    country: "国籍",
    countryBirth: "出生地所在国家",
    countryDestination: "目的地国",
    qrCodeDownload: "下载二维码",
    scanExplanation: "请扫描此二维码即可在微信找到我们",
    countryFrom: "从国家",
    CREDIT_BLOCKED: "很抱歉，由于我们正在更新网站，目前无法使用信用卡付款选项，建议稍后再试。 如仍需帮助，请与我们联系。",
    CREDIT_CARD_COMMIT_ERROR: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    CREDIT_CARD_ILS_AMOUNT: "总和将以谢克尔计算，付款将立即可用",
    CREDIT_CARD_SIGN_ERROR: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    CREDIT_CARD_VERIFY_ERROR: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    CREDIT_LIMIT: "本月您可发送至多 ",
    CREDIT_LIMIT_MONTHLY: "本月您可发送至多 {amount}",
    CREDIT_LIMIT_CREDIT_CARD: '交易金额超过我们的信用卡每月限额。 您最多可以使用 {amount}',
    CREDIT_LIMIT_WALLET: '交易金额超过了我们的钱包每月限额。 您最多可以使用 {amount}',
    CREDIT_LIMIT_RISKY: "本月您可向{country}至多发",
    CREDIT_LIMIT_UNREGISTERED: "本月您可发送至多",
    CREDIT_NOT_ACTIVE: "很抱歉，目前无法使用信用卡付款选项。 开放时间为 06:00-24:00。",
    creditCard: "信用卡",
    creditCardCommissionMsg: "您的信用卡公司可能会收取交易手续费",
    creditCardOwner: "卡上姓名",
    creditCardSecurityMsg: "我们不会保存您的信用卡详细信息，我们只会将其发送给信用卡公司进行审批",
    creditCardSupport: "我们合作",
    crediting: "收款",
    currency: "货币",
    currencyBankMsg: "最终收到的金额取决于收款人账户的类型和币种",
    currencyCardMsg: "最终收到的金额取决于收款人卡片的类型和币种",
    currencyEUR: "欧元",
    currencyILS: "以色列谢克尔",
    currencyUSD: "美元",
    CUSTOMER_ALREADY_REGISTERED: "您好！ 我们认为您已经在 myGMT注册，您可以在这里登录> <a href=\"https://mygmt.gmtonline.co.il\" style=\"color:#d4c641;text-decoration: underline;\">mygmt.gmtonline.co.il</a>",
    WALLET_FRIEND_NOT_UNIQUE: '抱歉，我们无法将此号码添加到您的好友列表中。我们很乐意为您提供帮助，请点击“联系我们”咨询任何问题',
    CUSTOMER_BLOCKED: "抱歉，您的个人资料已关闭，请联系我们",
    CUSTOMER_DAILY_LIMIT_EXCEEDED: "很抱歉，您已超过今日的转账次数上限。",
    CUSTOMER_DENIED: "嗨，欢迎回来！您有上次未完成的注册，请联系我们",
    CUSTOMER_MOBILE_NOT_MATCH: "抱歉，若需注册myGMT，请访问我们 <a href=\"https://gmtonline.co.il/en/contact-us/\" style=\"color:#d4c641;text-decoration: underline;\">Customer Service > </a>",
    CUSTOMER_MONTHLY_LIMIT: "抱歉，本月您已达到在GMT的月度转账上限。如需帮助，请联系我们。",
    CUSTOMER_MULTIPLE: "抱歉，若需注册myGMT，请访问我们<a href=\"https://gmtonline.co.il/en/contact-us/\" style=\"color:#d4c641;text-decoration: underline;\">Customer Service > </a>",
    CUSTOMER_NOT_AUTHORIZED: "很抱歉，您的个人资料中没有信用卡付款选项。如需帮助，请联系我们。",
    CUSTOMER_NOT_SIGNED: "很抱歉，您尚未完成注册，因此您的个人资料中还没有信用卡付款选项。如仍需帮助，请联系我们。",
    CUSTOMER_SESSION_NOT_FOUND: "身份信息不正确或您尚未注册 myGMT，您可以在这里注册 <a href=\"https://mygmt.gmtonline.co.il/#/registration\" style=\"color:#d4c641;text-decoration: underline;\">mygmt.gmtonline.co.il</a>",
    customerCommission: "您的手续费",
    customerCommissionMaxTopup: "充手续费",
    customerEmailNotification: {
        body: "我们似乎没有您的电子邮件，您可以在下面的字段中更新它，以便我们通过电子邮件向您发送每笔交易的副本。我们绝不会发送垃圾邮件。",
        cancel: "跳过",
        title: "更新您的邮箱"
    },
    dateBirth: "出生日期",
    daysWork: "星期日至星期四",
    daysWork2: "星期日至星期四08:30-19:00, 星期五 08:30-14:00",
    daysWork3: "星期五",
    depositCardNumber: "收款人信用卡卡号",
    DESC_019MOBILE: "019移动",
    DESC_CASHBACK: "返现",
    DESC_CASHBACK_BIRTHDAY: "生日快乐！返现",
    DESC_CASHBACK_ONETIME: "返现礼金",
    DESC_CASHBACK_WELCOME: "欢迎！返现",
    DESC_CONVERSION: "对话",
    DESC_MAX_ORDER: "预定卡片",
    DESC_MAX_PURCHASE: "卡片购买与充值",
    DESC_MAX_TOPUP: "卡片充值",
    DESC_REWIRE: "重新接线",
    DESC_WALLET: "到电子钱包",
    DESC_DEPOSIT_3MONTHS: '3个月存款计划',
    DESC_DEPOSIT_6MONTHS: '6个月存款计划',
    DESC_DEPOSIT_WITHDRAW: '提取存款资金',
    DESC_DEPOSIT_INTEREST_PAYMENT: '支付存款利息',
    DEPOSIT_IN: '转至存款账户',
    DEPOSIT_OUT: '提款存款账户',
    DEPOSIT_INTEREST: '支付存款利息',
    destination: "目的地",
    discount: "折扣",
    docsTitle: "请上传护照",
    docsTitleUploaded: "谢谢，我们即将完成!",
    DOCUMENT_NOT_FOUND: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    DOCUMENT_NOT_FOUND_FOR_CUSTOMER: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    documentsUploading1: "我们正在保存您添加的照片",
    documentsUploading2: "将需要片刻的时间",
    documentsUploading3: "随后点击下一步按钮",
    E_DECIMAL: "仅限整数",
    E_INVALID_zipCode: "邮政编码错误",
    E_LEMGTH: {
        1: "",
        2: "仅数字"
    },
    E_MAX_CHAR: {
        1: "",
        2: "最大字符数"
    },
    E_MAX_VALUE: "您已超过最大值",
    E_MIN_CHAR: {
        1: "",
        2: "最少字符数"
    },
    E_MIN_passport: "必须多于5个字符",
    E_NUMERIC: "请仅使用数字",
    E_REGEX: "该字段格式无效",
    E_REGEX_birthDateAge: "仅限18-85岁",
    E_REGEX_email: "邮箱格式无效",
    E_REGEX_english: "请填拼音",
    E_REGEX_israeliId: "非有效以色列身份证",
    E_REGEX_mobileNumber: "请输入10位数字有效的以色列手机号码",
    E_REGEX_passport: "仅限字母与数字",
    E_REGEX_zipCode: "邮编应为7位数",
    E_REQUIRED: "必填项目",
    E_REQUIRED_agreement: "请阅读并同意服务条款",
    E_REQUIRED_amount: "金额是？",
    E_REQUIRED_currency: "货币是？",
    E_REQUIRED_destinationCountry: "目的地是？",
    editUserProfile: "我的信息",
    email: "电子邮箱",
    error: "错误",
    errorCode: "无效码，请重试",
    estimated: "预估",
    exchangeRate: "汇率",
    expired: "过期",
    extraDetails: "更多细节",
    faqLink: "zh-hant/faq/",
    fax: "传真",
    FILE_NOT_ALLOWED: "文件出现问题",
    finished: "已完成",
    for: "为",
    foreignCurrencyHandlingFee: "外币手续费",
    foreignPassport: "我持有外国护照",
    freeSearch: "搜索",
    friday: "星期五",
    fullName: "全名",
    fun: "我们承诺只向您更新重要的信息。",
    gender: "性别",
    new: "新",
    newCustomer_section: '对金钱运用的完美选择',
    quickSend: '快速汇款',
    contact_us_paragraph: '"我们坚信良好的沟通，因此请随时通过此处列出的任何方式与我们联系。\n' + '我们的营业时间：\n' + '周日至周四：上午 08:30 - 下午 05:00，\n' + '周五：上午 08:30 - 下午 02:00。"',
    changeLang: 'Change Language',
    quickActions: '快速行动',
    quickSendActionsParagraph: '您最近的收款人已存在并准备好再次发送',
    quickActionsAdd: '添加',
    payAnywhere: '预付卡',
    carousel_slide_one_text: '获取您的预付卡并更好地控制您的金钱',
    carousel_slide_one_button: '了解更多',
    seeAll: '查看全部',
    cardBalance: '卡片余额',
    getCashback: '获取',
    cashBack_info: '返现',
    home: '首页',
    menu: '菜单',
    activity: '活动',
    cards: '卡片',
    greeting_morning: '早上好',
    greeting_afternoon: '下午好',
    greeting_evening: '晚上好',
    greeting_night: '晚安',
    paidBy: '支付方式',
    incoming: '收款',
    outgoing: '汇款',
    CONVERSION: '汇率',
    PAYMENT: '款项',
    CASH_BACK: '返现',
    cardActions: '卡的操作',
    actions: '菜单',
    activateCard: '激活您的新卡',
    activateCardAction: '激活卡片',
    enterLastDigits: '输入卡号最后 4 位数字进行激活',
    searchTransactions: '搜寻款项',
    filterTransactions: '款项筛选',
    filterTransactionsButton: '筛选',
    openWallet: '免费开设钱包账户',
    pleaseSelect: '选择一个或多个',
    date: '日期',
    from_date: '从日期',
    to_date: '到日期',
    choose_date: '选择日期',
    showResults: '显示结果',
    clearFilter: '清除筛选',
    today: '今天',
    call_us: '联系我们',
    contact_us_or: '或',
    lastTransactions:'卡最近交易记录',
    transactionClasses: {
        BANK_TRANSFER: '银行转账款项',
        CASH_TRANSFER: '现金提取款项',
        CARD_DEPOSIT: '充值卡片款项',
        PAYMENT: '转入款项',
        CONVERSION: '货币兑换',
        CASH_BACK: '返现',
        WALLET_DEPOSIT: '自助存款到您的钱包',
        WALLET_TRANSFER: '电子钱包充值款项',
        WALLET_TO_WALLET: 'myGMT充值款项',
        WALLET_FROM_WALLET: '转账myGMT钱包款项'
    },
    paymentTypes: {
        CASH: '现金',
        CHECK: '支票',
        BANK_TRANSFER: '银行转账',
        CREDIT_CARD: '卡',
        CREDITTING: '卡',
        OUTER_CONVERSION: '现金',
        CASHBACK: '返现',
        CUSTOMER_DEPOSIT: '第三方结余',
        WALLET: '钱包账户',
        CHANGE: '零钱',
        AIRPORT: '机场',
    },
    nothing_found: '没有找到任何交易',
    nothing_found_text: '只要您进行了一些交易，它就会显示在此处',
    creditCardReferenceNumber: '卡公司参考',
    checkNumber: '支票号码',
    referenceNumber: '参考号',
    flightDate: '航班日期',
    deposit_to_wallet: '充值钱包',
    contactMenuCaption1: '电报',
    CARD: '卡',
    CASH: '现金',
    AIRPORT: '机场',
    CHECK: '支票',
    CHANGE: '零钱',
    CREDIT_CARD: '卡',
    CREDITTING: '卡',
    OUTER_CONVERSION: '现金',
    CASHBACK: '返现',
    CUSTOMER_DEPOSIT: '第三方结余',
    WALLET: '钱包账户',
    quick_guide: '快速指南',
    invite_friends: '邀请朋友加入myGMT',
    cash_back_desc: '返现',
    send_money_home: '汇款回家',
    send_money_worldwide: '向世界各地汇款',
    generalErrorMsg: "很抱歉，我们正在更新网站以改进我们的服务，建议稍后再试。如需帮助，请与我们联系。",
    GMTbranches: "<a href=\"https://gmtonline.co.il/en/locating-branches/\" style=\"color:#d4c641;text-decoration: underline;\">在任一GMT网点</a>",
    GRAILS_VALIDATION_ERROR: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    here: "这里",
    hours: "请注意！ 您必须在接下来的 48 小时内到达 GMT 分支网点才能完成付款",
    houseNumber: "房号",
    welcome: '欢迎',
    installments: '付款次数',
    inProcess: "进行中",
    interest: '包含 {inter} 利息',
    interested: "同时，您可以访问我们的博客",
    INVALID_CARD: "很抱歉，您尝试发至的卡无法被支持。 您可以再次尝试使用不同的卡。如需帮助，请联系我们。",
    INVALID_SESSION_INFO: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    INVALID_VERSION: '对此次中断我们深感抱歉，请刷新页面，访问myGMT的最近更新。',
    israel: "以色列",
    israeliID: "我有以色列身份证",
    israeliResident: "以色列居民",
    itemNavMain: 'My Wallet',
    itemNav0: "我的返现",
    itemNav1: "转钱",
    itemNav10: "网点",
    itemNav11: "关于",
    itemNav12: "GMT预付卡",
    itemNavUserProfile: '个人详细信息和设置',
    itemNav13: '国外货币订单',
    itemNav2: "我的历史",
    itemNav3: "汇率&费用",
    itemNav4: "我的收款人",
    itemNav5: "GMT网点地址",
    itemNav6: "常见问题",
    itemNav7: "联系我们",
    itemNav8: "条款与条件",
    itemNav9: "退出登录",
    imprtant: 'Important',
    KYC_EXCEPTION: "对不便表示歉意。为了完成交易，我们需要额外的详细信息。请联系客户服务以获得帮助。谢谢。",
    WALLET_FRIEND_ALREADY_ADDED: '您好，此号码已在您的好友列表中。',
    WALLET_FRIEND_BLOCKED: '抱歉，我们无法将此号码添加到您的好友列表中。 请与我们联系以获取更多信息',
    WALLET_FRIEND_NOT_REGISTERED: '抱歉，手机号码 {mobileNumber} 还不在 myGMT 系统。使用您的个人注册链接邀请您的朋友并获得现金返还！',
    labelEmail: "邮箱地址",
    labelMsg: "您想告诉我们什么吗？",
    labelName: "名字的汉语拼音",
    labelPhone: "电话号码",
    lblDone: '汇款是作为对一级亲属的礼物或生活支持，不构成受益人的收入。',
    lblDoneWechat: '请注意，对于第一笔交易，您的收款人需要批准微信交易。 请告知他们点击短信链接。',
    lblDoneWechat1: '请在您绑定了微信的中国手机号前加上86，或绑定的以色列手机号前加上972。',
    labelSurname: "姓氏的汉语拼音",
    leaveMsg: "您想告诉我们什么吗？",
    linkToLoginPage: "已注册？请登录",
    listBranches: "找到GMT",
    locatingBranches: "定位分支网点",
    locationBtn: "打开",
    locationBtnClose: "关闭",
    locationLink: "zh-hant/branches/",
    loginAgreementRequired: "请阅读并同意服务条款",
    loginLabel1: "身份证/护照",
    loginLabel2: "电话号码",
    logInConsent: '通过登录，我同意',
    mailingAddress: "地址",
    mailingRecipient: "劳尔 瓦伦伯格 18C, 特拉维夫, 6971915",
    marked: "现在开放",
    marketingMessage: "使用 myGMT，您可以向境外的家人汇款，并在数千个支付点使用参考号取款，直接存入国外的信用卡，或直接存入国外的任何银行账户。",
    marketingMessage2: "简单。迅速。安全。为您定制",
    MAX_EXCEPTION: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    maxAccessionCommission: "参与手续费",
    maybe: "同时，您可以访问我们的博客",
    messageCode: "我们刚刚通过短信向您发送了一个验证码。请在下方输入验证码。",
    messageRegistration1: "您好，欢迎回来！",
    messageRegistration2: "我们只需要您上传您的身份证或护照照片，同意我们的条款和条件，即一切就绪了",
    messageSend: "在接下来的几分钟内，我们还将通过短信和电子邮件向您发送此确认信息。",
    messageNotSent: '接收验证码时遇到问题？ 请检查您的短信垃圾匣,骚扰和屏蔽选项. 我们很乐意帮助解答任何问题。',
    messageUploadDocuments: "您的身份证明文件似乎不再有效，请上传新的",
    middleName: "不用填",
    mobilePhone: "电话号码",
    mobilePhoneNumber: "以色列手机号码",
    monday: "星期一",
    moneyApproved: "转账已被批准！",
    MoneyGram: "速汇金",
    MONEYGRAM_301: "超过最大转账金额",
    MONEYGRAM_304: "很抱歉，我们的汇款服务目前已关闭。 我们将在工作日 8:00-21:00 和周五 8:00-15:00 之间回来",
    MONEYGRAM_323: "很抱歉，您输入的收款人姓名太长。 您可以使用较短的名称重试。 如仍需帮助，请联系我们。",
    MONEYGRAM_4055: "很抱歉，为了保护您，速汇金拒绝了此次转账，而且转账还没有完成。如需帮助，请联系我们。",
    MONEYGRAM_7003: "很抱歉，您已达到速汇金的限额，并且转账未完成。如需帮助，请联系我们。",
    MONEYGRAM_EXCEPTION: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    MONEYGRAM_VALIDATION_ERROR: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    moreOptions: "更多选项",
    msgError: "仅使用数字",
    myCard: "GMT预付卡",
    myDateBirthday: "日/月/年",
    myId: "我的身份证",
    nameBeneficiary: "收款人姓名",
    ERROR_NEGATIVE: '很抱歉您输入的金额有误无法以负金额进行交易，请更新请求的金额。',
    NEW_BENEFICIARY_LIMIT_EXCEEDED: "很抱歉，您最多可以在一周内向新的海外卡发送 2 次转账。 如有任何问题，我们的客户服务部将很乐意为您提供帮助。",
    NEW_BENEFICIARY_AMOUNT_EXCEEDED: '很抱歉，您已经超过了国外新卡存款的最高限额。 如有任何问题，我们的客服将很乐意为您提供帮助。',
    NEW_CUSTOMER: "您好，我们最多需要 24 小时才能完成您的注册。 同时，信用卡付款选项不可用。 我们会在准备好后尽快通知您。",
    NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: "很高兴有您加入我们！ 您已超过第一周新客户的最大转账次数。 如有任何问题，我们的客户服务部将很乐意为您提供帮助。",
    NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: "有您的加入，我们十分高兴！您已超过新用户第一周的最大额度。若您有疑问或需要申请更高额度，请联系我们的客服。",
    newBeneficiary: "新的收款人",
    nextLevel: "下一步",
    no: "否",
    noBeneficiaries: "系统中未定义该收款人",
    noCardRecentActions: "未找到交易",
    nonIsraeliResident: "外国居民",
    noRecentActions: "欢迎来到您的myGMT个人空间",
    noRecentActions1: "这里您可以看到您在GMT的所有历史记录",
    noRecentActions2: "现在请发起转账吧",
    notAvailable: "不可用",
    notRequired: '选填',
    numberOfTransaction: "发票号",
    numberPassport: "护照号",
    openNow: "开放",
    pay: "下单",
    loadCard: '从钱包往卡充值',
    payingReference: "订单号",
    paymentCredit: "信用卡",
    paymentFrom: "付款自",
    paymentProcessing: '请稍候，我们正在处理您的请求。',
    paymentType: "付款类型",
    paymentTypeByCredit: "刷卡支付",
    paymentTypeCash: "现金",
    paymentTypeCredit: "信用卡支付",
    payUsing: "支付通过",
    toPay: '支付',
    payRate: '汇率 {rate}',
    photoID: "身份证、驾照或护照",
    photoRequired: "请添加一张照片",
    pinCode: "查看pin码",
    pinCodeModalMessage: "您想要查看以{digits}结尾的卡片的pin码吗",
    pinCodeModalMessage2: "我们向您的手机发送了一条短信以查看pin码",
    placeholderDigit: "九位数身份证号",
    protocolLink: "en/protocol/",
    privacyLink: 'en/privacy',
    privacyPolicy: '隐私政策',
    profileDelete: "删除您的个人资料",
    profileDeleteModalTitle: "您要取消MYGMT个人资料吗？",
    profileDeleteModalText: "通过点击批准按钮,我们将收到一条消息，并尽快与您联系",
    questionsAnswers: "常见问题",
    questionsAnswersSubtitle: "找不到您的问题的答案？ 请通过联系页面上列出的任何方式与我们联系",
    r_step_address: "地址",
    r_step_doc: "文件",
    r_step_name: "名字",
    rate: '利率',
    reasonTransfer: "转账理由",
    recentActions: "我的历史",
    recentActionsBeneficiaries: "我的收款人",
    recentActionsTab1: "新收款人",
    recentActionsTab2: "本古里安机场的外汇服务",
    recentActionsTab3: "收费",
    recentActionsTab4: "收钱",
    recentActionsTab5: "购买比特币",
    recentActionsTransfer: "汇款",
    recommended: "推荐！",
    REGISTER_INFO_DETAILS_REQUIRED: "您好，我们需要您的身份信息和文档来向海外转钱",
    REGISTER_INFO_SEARCH_ZIP: "嗨，您可以通过点击放大镜搜索邮政编码",
    registerFooter: "GMT 在财政部的许可下运营",
    registerFooter2: "扩展金融服务提供商 - 许可证编号 57479",
    registerFooter3: "我们的服务是加密和安全的，我们永远不会向第三方分享您的信息。",
    registerSystem: "注册myGMT",
    registerSystemAddDocuments: "请上传护照",
    registerText1: "只需片刻，您就可以汇款",
    registerText2: "找到你的身份证/护照，我们将会请你对其拍照，这就开始吧！",
    REGISTRATION_TOKEN_EXPIRED: "抱歉，该验证码不再有效，您可以重新获取",
    REGISTRATION_TOKEN_NOT_FOUND: "您输入的验证码有误",
    registrationSuccess: "注册成功！",
    registrationSuccess1: "感谢！我们已收到您的信息",
    registrationSuccess2: "通过GMT，您可以轻松快捷地向境外汇款",
    relationshipWithCustomer: {
        SELF: '我本人',
        CHILD: "我的孩子",
        EX_SPOUSE: "我的前任配偶",
        PARENT: "我的父母",
        SIBLING: "我的兄弟姐妹",
        SPOUSE: "我的配偶"
    },
    requestSuccessfully: "您的请求已成功收到",
    requestSuccessfully1: "您的订单号是：",
    requestSuccessfully2: "接下来做什么？ 现在您需要在 24 小时内支付这笔转账费用",
    requestSuccessfully3: "<a href=\"https://gmtonline.co.il/en/locating-branches/\" style=\"color:#d4c641;text-decoration: underline;\">在任一GMT支行网点</a>",
    requestSuccessfully4: "随后它将被发至海外",
    riskyLimitMsg: '需要汇款更高金额？ 联系我们的客服团队。',
    russianSpeaker: "俄语服务",
    saturday: "星期六",
    searchResult: "在XXX找到X网点",
    selectBeneficiary: "我的收款人",
    selectCity: "通过城市寻找",
    selectCountry: "国籍",
    selectProvince: "选择省份",
    sendMarketingContentLbl: "通知我特殊优惠与更新",
    sentOn: "日期",
    SERVER_ERROR: "很抱歉，系统正在更新，建议稍后再试。如需帮助，请与我们联系。",
    SESSION_EXPIRED: "回来啦！ 我们以为您离开了，所以我们退出了页面以保证它的安全。 您可以重新登录",
    signedUp: "您已注册！",
    SMS_EXPIRED: "抱歉，验证码已失效，您可重新获取一个新验证码。",
    SMS_WRONG: "您输入的验证码有误",
    soonPossible: "我们将在上班时间回复您。",
    staging: {
        body: "当您按下“下单”按钮时，我们将为您准备转账。 您在GMT的任一网点付款后，转账将被发出。",
        order: "下单",
        payWith: "在GMT网点支付现金"
    },
    STATUS_ACCEPTED: "已接受",
    STATUS_BEING_CANCEL: "已被取消",
    STATUS_CANCELED: "已取消",
    STATUS_CLOSED: "已关闭",
    STATUS_CONFIRMED: "已确认",
    STATUS_EXPIRED: "已过期",
    STATUS_FAILED: "已失败",
    STATUS_IN_PROCESS: "被支付",
    STATUS_INCOMPLETE: "未完成",
    STATUS_NOT_FOUND: "无法找到",
    STATUS_PAID: "已付",
    STATUS_PREPARED: "已准备",
    STATUS_READY_FOR_PAYMENT: "付款就绪",
    STATUS_READY_TO_CANCEL: "可取消",
    STATUS_REJECTED: "已拒绝",
    STATUS_RETURNED: "已返还",
    STATUS_SENT_TO_BANK: "汇给银行",
    STATUS_STAGED: "进行中",
    STATUS_SUBMITTED: "已提交",
    STATUS_SWIFT_APPROVED: "已批准",
    STATUS_SWIFT_TRANSMITTED: "已传送",
    streetName: "街道",
    streetName_HouseNumber: "路名与门牌号",
    subscribeDesc: "金融科技界最新的新闻都在这里了",
    subscribeNewsletter: "注册接收我们的时事通讯——绝对值得！",
    subtitleCustomerService: "请联系我们的客服，我们将竭诚为您服务。",
    sumAmount: "合计金额",
    summaryTransfer: "转账详情概括",
    sunday: "星期天",
    surtitleFormLang: "选择适合您的语言",
    smartBannerTitle: '升级至myGMT软件',
    smartBannerText: '简单。 快速。安全。 正是您需要的。',
    smartBannerBtnText: '安装',
    system: "系统",
    t_step_amount: "金额",
    t_step_pay: "转账",
    t_step_summary: "明细",
    t_step_transfer: "支付",
    TEMPORARY_ERROR: "很抱歉，我们在尝试将您的转账发送到国外时出错。 请与我们联系，我们很乐意检查并为您提供帮助",
    terms: "条款与条件",
    termsTitle: "请阅读并同意条款与条件",
    tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
    surveyModal: {
        active: true,
        title: '分享您的想法：参加我们的快速匿名问卷',
        body: '您好 {name}，我们希望收到您的意见！ 通过回答简短的匿名调查来帮助我们改善金融服务。 您的意见很重要。',
        confirm: '开始问卷调查',
        cancel: '稍后',
        link: 'https://forms.gle/LYSDw7oGrQPLNHvZ9'
    },
    termsUpdate: {
        title: '条款条件和隐私政策',
        body: {
            first: '您好，我们更新了我们的法律文件，',
            second: '您可以在这里阅读它们>'
        }
    },
    termsUse: "T&C",
    thanks: "感谢联系！",
    thursday: "星期四",
    titleFormLang: "欢迎",
    titleLoginPage: "登录myGMT",
    to: "到",
    toBankAccount: "到账户",
    toWalletAccount: "到钱包",
    pickupPoint: '取款点',
    toCreditCard: "到卡",
    total: "总计",
    totalNIS: "谢克尔总计",
    totalTransfer: "支付金额",
    TRANSACTION_LIMIT: "很抱歉，此转账仅限于 {currency},{amount} 每笔交易。 您可以更新金额并发送。 如遇问题，欢迎联系我们。",
    TRANSACTION_NOT_FOUND_FOR_CUSTOMER: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    transactionAmount: "金额",
    transactionCharge: "排序依据",
    filterPaymentType: '付款类型',
    transactionType: '款项类型',
    transferAmount: "金额",
    transferFundsTo: "转",
    transferOptions: "查询",
    transferType: "转账类型",
    transferTypePick: "请选择您的转账方式",
    tuesday: "星期二",
    updateBeneficiary: "收款人信息",
    uploadDocumentMsg1: "我们需要认证您的身份信息，请拍张您身份证件的照片",
    uploadDocumentMsg2: "请放心，我们会认真对待您的隐私。 我们的网站已加密并安全，永远不会分享您的详细信息。",
    version: 'v {num}',
    waitingPayment: "等待付款",
    wallet: {
        menu: '我的钱包',
        balance: "我的GMT钱包",
        payment: {
            body: "在您按下“下单”按钮后，我们将使用您的帐户信用额度为您进行交易，您的余额将相应更新。",
            footer: "付款后，您的余额将更新为 {currency}{amount}",
            inActive: {
                footer: "很抱歉，您的钱包余额不足。 您可以更改金额或将更多资金存入您的钱包账户"
            },
            title: "我的GMT钱包"
        },
        payWith: "确认并使用我的钱包付款",
        main: {
            title: '您好 {name}',
            card: {
                title: '您的钱包余额',
                transactionsLink: '查看您的所有交易'
            },
            depositToWallet: '钱包充值方式',
            sendMoney: '汇钱',
            walletOptions: '钱包选项',
            depositActions: {
                cash: '现金存款',
                bank: '银行转帐',
                card: '银行卡'
            },
            sendActions: {
                sendHome: '汇款回家 ',
                inIsrael: '以色列境内汇款',
            },
            walletActions: {
                loadPrePaid: 'myGMT PrePaid Card',
                send: ' 发送给 myGMT 好友',
                invite: ' 邀请好友，返现'
            },
            doarInstruction: '如何从邮局汇款'
            // doarInstruction: 'GMT 新年优惠'
        },
        invite: {
            title: '邀请好友并获得现金返还',
            body: '您和您的朋友可以从 myGMT 获得更多。\n“将您的个人注册链接发送给您的朋友。在 myGMT 上进行第一次交易后，你们俩都将获得 {amount} ILS 现金返还！”\n\n您可以使用下面的按钮分享您的个人注册链接',
            shareActions: {
                copy: '复制到剪贴板',
                copied: '已复制',
                whatsapp: '通过 WhatsApp 邀请',
                wechat: '通过微信邀请',
                line: '通过LINE邀请',
                general: '分享'
            },
            inviteMessages: {
                firstLine: '嗨，邀您和我一起加入myGMT！使用下面的个人链接注册',
                secondLine: '到 myGMT，即刻获得现金返还！>'
            }
        },
        deposit: {
            cash: {
                title: '将现金存入您的钱包',
                body: '您可以在任何 GMT 网点存入现金。\n几秒钟后，您的 myGMT 钱包将被更新，您就可以立即使用它',
                locations: 'GMT网点地址>',
                walletOrderExplanation: {
                    depositAnywhere: '您可以在GMT的任何网点存入现金。',
                    amount: '输入您要存入的金额',
                    verificationCode: '获取您的代码',
                    teller: '带上资金并向工作人员出示代码。',
                    finishTitle: '就是这样！',
                    finishText: '您的钱包账户将立即入账'
                }
            },
            bank: {
                title: '从您的银行或 HaDoar 账户转账到您的钱包',
                body: '将银行转帐至GMT并直接将其存入您的钱包帐户。\n您的雇主也可以将钱从他们的账户转入您的钱包。',
                transferDetails: '“帐户详细资料:\nGMT TECH INNOVATION LTD\n银行 HaPoalim 12\n613 分行\n帐号 545690',
                uploadDetails: '“在此处上传您的转账确认信息，不要忘记提及 您的护照和手机号码”',
                uploadConfirmationBtn: '上传确认信息',
                placeholder: '添加银行转账确认',
                upload: {
                    title: '上传您的转账确认',
                    loader: '我们正在上传您添加的照片',
                    footer: '不要忘记提及您的护照和手机号码',
                    success: '太棒了！我们的工作人员会尽快处理，您​​的钱包余额将很快更新。\n我们还将通过短信和电子邮件向您发送确认信息。'
                }
            }
        },
        friends: {
            title: '发送给 myGMT 好友',
            addFriend: '添加新朋友',
            newFriend: '转账至 myGMT 钱包',
            contacts: '从我的联系人中选择',
            addContactByPhone: '转账到未储存的联系人',
            searchByNameOrPhone: '按姓名或号码搜索',
            header: '借助我们易于使用的转账功能，您可以安全地将资金发送给您的任何 myGMT 朋友。\n 又快又简单！',
            mobilePhone: '手机号码',
            bulletsOne: '选择您朋友的手机电话。',
            bulletsTwo: '输入您要发送的金额。',
            bulletsThree: '确认交易。',
            outro: '就是这样！\n 您和您朋友的钱包余额将会立即更新。',
            inviteBnt: '邀请朋友加入 myGMT',
            list: {
                title: '好友列表'
            },
            addModal: {
                title: '您要添加吗',
                footer: '给您钱包的朋友？',
                saveBtn: '保存',
                closeBtn: '取消'
            },
            friendCard: {
                title: '钱包',
                delete: '删除',
                deleteDialogFirstLine: '您想从您的钱包好友列表中删除 {beneficiary} 吗？',
                deleteDialogSecondLine: '这不会影响现有的转账',
                send: '转到钱包'
            }
        },
        transfer: {
            amount: {
                title: '汇款至 myGMT 钱包'
            },
            send: {
                title: '将 {amount} ILS 转到 myGMT 钱包'
            },
            summary: {
                title: '将 {amount} ILS 转到 myGMT 钱包}'
            },
            pay: {
                title: '将 {amount} ILS 转到 myGMT 钱包'
            },
        },
        load: {
            amount: {
                title: '存入您的钱包并使用您的卡付款'
            },
            send: {
                title: '存入您的钱包'
            },
            summary: {
                title: '存入您的钱包'
            },
            pay: {
                title: '存入您的钱包'
            },
        },
        card: {
            amount: {
                title: 'Reload myGMT Card with your Wallet'
            },
            send: {
                title: 'Reload myGMT Card with your Wallet'
            },
            summary: {
                title: 'Reload myGMT Card'
            },
            pay: {
                title: 'Reload myGMT Card'
            }
        }
    },
    WALLET_TRANSFER: '存款到电子钱包',
    WALLET_TRANSFER_TO: '转账至 {dest} 电子钱包 ',
    WALLET_TO_WALLET: '发送到 myGMT 钱包',
    WALLET_FROM_WALLET: '接收到 myGMT 钱包',
    WALLET_DEPOSIT: '存入到 myGMT 钱包',
    wednesday: "星期三",
    YAAD_SARIG_141: "很抱歉，我们现在只能接受 Visa 和万事达卡。 如需帮助，请联系我们。",
    YAAD_SARIG_26: "很抱歉，该卡不属于您的身份证号码。 您可以使用属于您的卡再试一次。如仍需帮助，请联系我们。",
    YAAD_SARIG_3: "很抱歉，信用公司没有批准转账。 我们建议您就该问题与它联系，然后重试。如仍需帮助，请联系我们。",
    YAAD_SARIG_3000: "很抱歉，信用公司没有批准转账。 我们建议您就该问题与它联系，然后重试。如仍需帮助，请联系我们。",
    YAAD_SARIG_3003: "很抱歉，信用公司没有批准转账。 我们建议您就该问题与它联系，然后重试。如仍需帮助，请联系我们。",
    YAAD_SARIG_303: "很抱歉，您的信用卡不支持信用卡公司安全检查，您可以联系您的信用卡公司。如仍需帮助，请联系我们。",
    YAAD_SARIG_4: "很抱歉，信用卡公司未批准转账或您输入的卡详细信息有误，我们建议您核对后再试一次。 如仍需帮助，请与我们联系",
    YAAD_SARIG_447: "很抱歉，您输入的信用卡号有误，请重试。如仍需帮助，请联系我们。",
    YAAD_SARIG_553: "很抱歉，我们认为您没有批准向您的信用卡支票付款，因此它被取消了。 你可以再试一次。如仍需帮助，请联系我们。",
    YAAD_SARIG_6: "抱歉，您输入的卡片信息有误，请重试。如仍需帮助，请与我们联系。",
    YAAD_SARIG_9101: "很抱歉，您的信用卡不支持信用卡公司安全检查，您可以联系您的信用卡公司。如仍需帮助，请联系我们。",
    YAAD_SARIG_999: "十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    YAAD_SARIG_GENERAL: "十分抱歉，本次付款出了点问题，请稍后再试。如仍需帮助，请与我们联系。",
    yes: "确认",
    yourCardBalance: "卡余额",
    zipCode: "邮政编码",
    zipCodeNotFound: "我们找不到该地址的邮政编码，请再次检查或填写您的七位邮政编码",
    zipCodePlaceholder: "七位数",
    zipCodeRequiredFields: "请填写您地址的详细信息，以便我们查找您的邮政编码",
    calculatedBy: "换算成以{curr}计算",
    recipientAmount: "收件人获得",
    currencyDestination: "收件人货币/目的地金额",

    NAME_ALIPAY: '支付宝',
    NAME_UNIONPAY: '银联',
    NAME_WECHATPAY: '微信支付',
    NAME_UPI: 'UPI',

    validation: {
        mock: '',
        beneficiary: {
            invalid: '该字段格式无效',
            maskedCardNumber: {
                invalidCountry: '该卡可能与目的地国家/地区不匹配',
                tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试'
            },
            accountIdentifier: {
                invalid: '该字段格式无效',
                tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试'
            },
            walletIdentifier: {
                invalid: '该字段格式无效',
                tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试'
            },
            phoneWallet: {
                unavailable: '很遗憾，此帐户不可用且无法接收资金。 请在重汇款前检查帐户。',
                mismatch: '很遗憾，收款人姓名和帐号不匹配。 您可以修正详细信息，然后重试。',
                invalidAccount: '很遗憾，该帐户无效。 请在重汇款前检查帐户。',
                barred: '很遗憾，该帐户被锁。 请在重汇款前检查帐户。'
            },
            phoneNumber: {
                format: {
                    passParam: '微信绑定国内电话前面加86,以色列电话绑定前面加972'
                }
            },
        }
    }
}