import Vue from 'vue'
import Vuex from 'vuex'

import registration from './modules/registration'
import beneficiary from './modules/beneficiary'
import transfer from './modules/transfer'
import card from './modules/card'
import user from './modules/user'
import lookup from './modules/lookup'


Vue.use(Vuex);

let initialState = {
    registration: registration.state,
    beneficiary: beneficiary.state,
    transfer: transfer.state,
    card: card.state,
    user: transfer.user,
    lookup: lookup.state
}

export default new Vuex.Store({
    modules: {
        registration,
        beneficiary,
        transfer,
        card,
        user,
        lookup
    },
    state: {
        locales: {
            'il': 'עברית',
            'en': 'English',
            'ru': 'Русский',
            'th': 'ภาษาไทย',
            'cn': '中文'
        },
        localesShort: {
            'il': 'עב',
            'en': 'en',
            'ru': 'ru',
            'th': 'ไทย‏',
            'cn': '中文'
        },
        locale: {
            'lang': 'il',
            'dir': 'rtl'
        },
        localeTitle: '',
        locationDetail: {},
        showModalError: false,
        errorCode: '',
        errorMessage: '',
        subscriptionId: localStorage.getItem('subscriptionId') || ''

    },
    mutations: {
        changeLang(state, dataLang) {

            if (dataLang.lang === 'il') {
                dataLang.dir = 'rtl'
            } else {
                dataLang.dir = 'ltr'
            }
            document.body.setAttribute('dir', dataLang.dir);

            state.locale = dataLang;
            console.log("🚀 ~ changeLang ~ state.locale:", state.locale)
            localStorage.setItem("lang", JSON.stringify(dataLang));
            if (!this.getters.getLoggedIn) localStorage.setItem("langChanged", 1);
            window.location.reload()
        },
        setLocationDetail(state, data) {
            state.locationDetail = data;
        },
        setLangTitle(state, data) {
            state.localeTitle = this.state.locales[data];
        },
        resetAllState(state) {
            Object.keys(state).forEach(key => {
                Object.assign(state[key], initialState[key]);
            })
            state.beneficiary.list = {};
        },
        setShowModalError(state, data) {
            state.showModalError = data;
        },
        setErrorCode(state, data) {
            state.errorCode = data;
        },
        setErrorMessage(state, data) {
            state.errorMessage = data;
        },
        setSubscriptionId(state,data) {
        state.subscriptionId = data
        }
    },
    getters: {
        getDir(state) {
            let lang = JSON.parse(localStorage.getItem("lang"));

            return (!lang) ? state.locale.dir : state.locale.dir = lang.dir;
        },
        getLang(state) {
            let lang = JSON.parse(localStorage.getItem("lang"));

            return (!lang) ? state.locale.lang : state.locale.lang = lang.lang;
        },
        getLangTitle: state => lang => {
            return state.locales[lang];
        },
        getLangTitleShort: state => lang => {
            return state.localesShort[lang];
        },
        getLocationDetail(state) {
            return state.locationDetail;
        },
        getShowModalError(state) {
            return state.showModalError;
        },
        getErrorCode(state) {
            return state.errorCode;
        },
        getErrorMessage(state) {
            return state.errorMessage;
        },
        getSubscriptionId(state) {
            return state.subscriptionId
        }
    },
    actions: {
        getLangTitle({ commit }) {
            let lang = JSON.parse(localStorage.getItem("lang"));
            commit('setLangTitle', (!lang) ? this.state.locale.lang : lang.lang);
        }
    }
})