<template>
  <div class="payment-container">
    <div class="title">
      Paying {{ amountToPay }} {{ currency }} with:
    </div>
    <div class="payment-types">
      <div class="payment-type flex justify-between pointer">
        <div class="flex flex-column" @click="showWalletPaymentApprovalModal()">
          <div class="title flex"> <img src="../../../../assets/mygmt_v2_images/transfer_methods/WALLET_TRANSFER.svg"
              alt="" srcset=""> <span class="flex full-center">My wallet</span></div>
          <div class="body"> Your wallet balance is: <span> {{ getSymbol }} {{ walletBalance | formatComma }} </span></div>
        </div>
        <div class="flex full-center"> <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
      </div>
      <div class="payment-type flex justify-between pointer">
        <div class="flex flex-column">
          <div class="title flex"> <img src="../../../../assets/mygmt_v2_images/transfer_methods/CARD_DEPOSIT.svg"
              alt="" srcset=""> <span class="flex full-center">Credit card</span></div>
          <div class="body"> We accept Visa, Mastercard, AMEX, Diners, Apple Pay, Google pay</div>
        </div>
        <div class="flex full-center"> <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
      </div>
      <div class="payment-type flex justify-between pointer">
        <div class="flex flex-column">
          <div class="title flex"> <img src="../../../../assets/mygmt_v2_images/transfer_methods/BANK_TRANSFER.svg"
              alt="" srcset=""> <span class="flex full-center">Bank transfer</span></div>
          <div class="body"> Transfer directly to a bank account</div>
        </div>
        <div class="flex full-center"> <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
      </div>
    </div>

    <!-- <modal ref="walletModal" class="modal-container2" :noCancelBtn="true" icon="fas fa-wallet" icon-style="color: white; font-size: 40px"
          :title="$ml.get('wallet.payWith')" :confirm="$ml.get('pay')" @action="payByWallet">
          <p style="font-size: 30px">{{ this.getWalletSymbol }}{{ this.amountToPayToPay | formatComma }}</p>
          <hr />

          <p>{{ $ml.get('wallet.payment.body') }} </p>
        </modal>  -->
    <modal2 v-if="beneficiary" ref="walletModal" class="modal-container2" padding="20px 30px" :noCancelBtn="true" icon="fas fa-wallet"
      icon-style="color: white; font-size: 40px" :title="$ml.get('wallet.payWith')" :confirm="$ml.get('pay')"
      @action="payByWallet">

      <!-- <p style="font-size: 30px">{{ this.getWalletSymbol }}{{ this.amountToPayToPay | formatComma }}</p> -->
      <!-- <hr /> -->
      <div class="flex flex-column">
        <div class="modal-title">
          Wallet payment
        </div>
        <div class="grey-box">
          <div class="flex align-center">
            <div> <img src="../../../../assets/mygmt_v2_images/beneficiary/beneficiary_wallet.svg" alt="" srcset=""></div>
            <div class="flex flex-column">
              <div> My Wallet</div>
              <div> Balance: 1000</div>
              <div> wallet-number</div>
            </div>
          </div>
        </div>

        <div class="grey-box">
          <div class="flex align-center">
            <div> <div class="circle pointer" @click=""><strong>{{ beneficiary.firstName[0].toUpperCase()
                    }}</strong></div></div>
            <div class="flex  flex-column">
              <div>Beneficiafry</div
                >
              <div> Guy sarouk</div>
            </div>

          </div>
        </div>

        <div class="grey-box">
          <div class="flex justify-between">
            <div> Total payment:</div>
            <div> 950.00</div>
          </div>
        </div>
        <div>
          <button
           class="btn btn-main" @click="payByWallet">Confirm payment</button>
        </div>
      </div>

      <!-- <p>{{ $ml.get('wallet.payment.body') }} </p> -->
    </modal2>

  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import { getBeneficiary, commitTransaction, creditCardSign, paymentAmountInfo, sendValidation, getInstallmentsData } from "../../../../services/api";

import { formatComma } from '../../../../filters/AppFilters';
import Modal from '../../../../components/Modal.vue';
import Modal2 from '../../../../components/Modal2.vue';


export default {
  components: {},
  components: { Modal, Modal2 },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    getBeneficiary(this.$store.getters.getSelectedBeneficiary).then((beneficiary)=> {
      this.beneficiary = beneficiary
    })
    


  },
  data() {
    return {
      beneficiary: null
      // amountToPay: '100',
      // currency: 'ILS'
    };
  },
  methods: {
    showWalletPaymentApprovalModal() {
      // this.transmitSelectedPaymentType('my_wallet') 
      if (this.walletHasEnoughBalance) {
        console.log("🚀 ~ showWalletPaymentApprovalModal ~ this.walletHasEnoughBalance:", this.walletHasEnoughBalance)
        this.$refs.walletModal.openModal()
      }
    },
    bla(){
    console.log('blaaa')
    },
    payByWallet() {
      this.paymentType = 'WALLET'
      this.$refs.walletModal.closeModal();
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        let self = this;
        this.paying = true;
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "CORRESPONDENT_COMMISSION": selectedOption.correspondentCommission,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData()
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            commitTransaction({
              "transaction": {
                "id": responseValidation.result.transactionId,
                "sessionId": selectedOption.sessionId,
                "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
                "paymentType": 'WALLET',
              }
            }, response => {
              if (!response.errorCode) {
                this.paying = false;
                self.isSubmitted = false;
                if (response.result) {
                  this.referenceNumber = response.result;
                  modalHandler('.modal-mask-transfer-3', 'show', '092086', 'light');
                  this.$analyticsService.transmitSwitchCase('transaction', 'transaction_wallet')
                }
              } else {
                this.paying = false;
                this.$store.commit('setShowModalError', true);
                this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                this.$analyticsService.transmitSwitchCase('transaction', 'transaction_error', { errorCode: responseValidation.errorCode })
              }
            });
          } else {
            this.paying = false;
            this.$store.commit('setShowModalError', true);
            this.$store.commit('setErrorCode', responseValidation.errorCode);
            self.isSubmitted = false;
          }
        });
        setTimeout(() => this.isSubmitted = false, 10000);
      }
    },
    getTransactionData(paymentType) {
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      return {
        "amount": selectedOption.amount,
        "currency": selectedOption.currency,
        "commission": selectedOption.commission,
        "destinationCountry": selectedOption.destinationCountry,
        "sessionId": selectedOption.sessionId,
        "payoutCurrency": selectedOption.payoutCurrency,
        "transactionClass": selectedOption.transactionClass,
        "correspondentId": selectedOption.correspondentId,
        "paymentType": paymentType,
        "transferPurpose": "FAMILY_TRANSFER",
        "totalReceiveAmount": selectedOption.totalReceiveAmount || '',
        "transactionRate": this.$store.getters.getSelectedExchangeRate,
        "lookupAmount": selectedOption.lookupDto && selectedOption.lookupDto.lookupAmount,
        "lookupRate": selectedOption.lookupDto && selectedOption.lookupDto.rate,
        "lookupBaseRate": selectedOption.lookupDto && selectedOption.lookupDto.baseRate
      }
    },
  },
  computed: {
    amountToPay() {
      return this.$store.getters.getSelectedTransferOption.amount + this.$store.getters.getSelectedTransferOption.commission;
    },
    transactionAmount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    currency() {
      this.$store.getters.getSelectedTransferOption.currency
    },
    getSymbol() {
      return this.$store.getters.getSelectedCurrencySymbol;
    },
    walletHasEnoughBalance() {
      return this.remainingWalletBalance >= 0;
    },
    walletBalance() {
      return (this.$store.getters.getWalletBalance('ILS'));
    },
    remainingWalletBalance() {
      return (this.$store.getters.getWalletBalance('ILS') - this.amountToPay).toFixed(2);
    },
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() { },

};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";
.modal-title {
  margin-top: 56px
}

.btn {
  // display: block;
    width: 80%;
    height: 45px;
    margin: 0 auto;
}
.grey-box {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  margin: 20px 0px;
  padding: 14px;

  & :first-child {
    margin-right: 12px;

  }

    img {
    width: 36px;
    }
}

.circle {
  background: #0E1F81;
  border-radius: 35px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-right: 12px;

  strong {
    color: white;
    font-weight: 600;
    height: 25px;
    font-size: 18px;
  }
}


.modal-container2 {
  background-color: purple !important;

  .modal-mask {
    .modal-container {
      background-color: red !important;
    }
  }

}

.payment-type {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  padding: 15px;
  margin-top: 20px;

  .title {
    color: var(--Main-Blue, #0E1F81);
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;

    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;
    }
  }

  .body {
    color: #000;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: #000;
      font-family: Assistant;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }


}
</style>
