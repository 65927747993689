<template>
    <div draggable="false" class="grey-bgc  main-container relative">
        <img class="kite" src="../../assets/mygmt_v2_images/kite-transfer.svg" alt="">
        <Header2 @stepBack="currentStep > 0 ? currentStep-- : $router.go(-1)" :currentStep="currentStep + 1"></Header2>
        <div class="transfer-container relative">
            <div class="upper flex flex-column">
                <div @click="handleBackClick" class="pointer back"
                    v-if="!isMobileDevice">
                    < {{$ml.get('transfer.buttons.back')}} </div>
                        <div class="money-title">
                            {{ $ml.get('wallet.main.sendMoney')}}
                        </div>
                </div>

                <div class="flex transfer-info" :class="{'justify-center': isMobileDevice}">
                    <div  v-if="currentStep" >{{ selectedCountry }}</div>
                    <div class="flex"  v-if="selectedTransferOption" >
                    <div class="flex"> <div class="flex full-center eclipse"> <img src="../../assets/mygmt_v2_images/Ellipse.svg" alt="" srcset=""></div> <span>{{selectedTransferOption.amount + selectedTransferOption.commission | formatComma }}   {{selectedTransferOption.currency }}</span> <div class="flex full-center eclipse"> <img src="../../assets/mygmt_v2_images/Ellipse.svg" alt="" srcset=""></div> </div> <div> {{selectedTransferOption.transactionClass }}</div>
                    </div>
                </div>
                <ProgressBar :trackColor="'#F6F6F6'" :fillColor="'#D5C840'" :ballColor="'#D5C840'"
                    :progress="calculatedProgress" class="progress-bar" />
                <component  ref="genericComponent" :is="steps[currentStep]" @changeStep="changeStep($event)"></component>
                <!-- <CountrySelect></CountrySelect> -->
                <!-- <MethodAndAmountSelection></MethodAndAmountSelection> -->
            </div>
        </div>
</template>
<script>
import CountrySelect from './steps/step1/CountrySelect.vue'
import Lookup from '../../components/Lookup.vue';
import MethodAndAmountSelection from './steps/step2/MethodAndAmountSelection.vue'
import Header2 from "@/components/Header2";
import Footer from "@/components/Footer.vue";
import ProgressBar from './steps/components/ProgressBar.vue'
import { isMobile, getCountryNameByIso } from "@/services/utils";
import BeneficiarySelection from './steps/step3/BeneficiarySelection.vue';
import Summary from './steps/step4/Summary.vue';
import PaymentMethodSelection from './steps/step5/PaymentMethodSelection.vue';
import Payment from './steps/step6/Payment.vue';




export default {
    components: { CountrySelect, MethodAndAmountSelection, Header2, Footer, ProgressBar, Lookup, BeneficiarySelection, Summary, PaymentMethodSelection, Payment },
    data() {
        return {
            isMobileDevice: isMobile(),
            steps: ['CountrySelect', 'MethodAndAmountSelection', 'BeneficiarySelection', 'Summary', 'PaymentMethodSelection', 'Payment'],
            currentStep: 0,
            progress: 0,
           
        }
    },
    created() {
        // this.$store.commit('setLookupAmount', 120);
        // this.$store.commit('setLookupCurrency', 'USD');
        this.$store.dispatch('loadBeneficiary');

    },
    mounted() {
        document.body.setAttribute('dir', this.dir);
    },
    methods: {
        changeStep(idx) {
            this.currentStep = idx


        },
        handleBackClick(){
            const component = this.$refs.genericComponent
            if (component.name === 'MethodAndAmountSelection' && component.isCalculating) {
                component.amount = component.previousState.amount
                component.selectedCurrency = component.previousState.currency
                component.isCalculating = false
            } else {
                this.currentStep > 0 ? this.currentStep-- : this.$router.go(-1);
            }
        }
    },
    computed: {
        dir() {
            return this.$store.getters.getDir;
        },
        selectedCountry() {
            return getCountryNameByIso(this.$store.state.transfer.destinationCountry)
        },
        selectedTransferOption(){
            if (this.$store.getters) {
                return  this.$store.getters.getSelectedTransferOption  

            }
        },
        calculatedProgress() {
            return (this.currentStep / (this.steps.length - 1)) * 90;
        }
    },
    watch: {
        currentStep(newValue) {
            if (newValue === 0) {
                this.$store.commit('setTransferStep1Response', []);
                console.log('blaaaa');
            }
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";

.main-container {
    background-image: none;
    min-height: none;
    overflow: hidden;
    // max-height: 100vh;

}

.kite {
    display: none;
}

.eclipse {
    margin: 0 8px
}

.transfer-info {
    margin-bottom: 12px;
}
.mobile-margin {
    margin-right: 20px;
    margin-left: 20px;
}
.transfer-container {
    min-height: 90vh;
    padding: 0px 20px;
    background: #FFF;
  //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    background-image: url('../../assets/mygmt_v2_images/mygmt2-background-grey.svg');
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 100px;
    background-size: 100%;



    .upper {
        align-items: center;
        // margin-bottom: 10px;

        .back {
            color: var(--GMT--Blue, #0E1F81);
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 8px;

        }

        .money-title {
            color: #0E1F81;
            font-family: Assistant;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }

    .progress-bar {
        width: 100vw;
        height: 4px;
        margin-left: -20px;

        background: #F6F6F6;
         // margin-bottom: 26px;

        // position: relative;
        .test-grey {
            // position: absolute;
            // background: #F6F6F6;

        }
    }

}

.mygmt2-header-wrapper {
    background-color: #FFFF;
}

@media only screen and (min-width: $screen-tablet) {

    .upper {
        margin-bottom: 10px;
    }

    .mobile-margin {
    margin-right: 0px;
    margin-left: 0px;
}

    .main-container {
        min-height: 100vh;
        background-image: url('../../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
        background-repeat: no-repeat;
        background-position: left bottom, right top;
        background-repeat: no-repeat, no-repeat;
        background-size: auto auto, auto auto;
    }

    .kite {
        display: block;
        position: absolute;
        right: 0px;
        top: 200px;
        z-index: 0;
    }


    .transfer-container {
        padding: 0px 24px;
        width: 600px;
        min-height: 85vh;
        margin: 0 auto;
        margin-top: 40px;
        border-radius: 20px;
        background-image: none;
        z-index: 2;



        .upper {
            align-items: flex-start;

            .money-title {
                font-size: 24px;
            }
        }

        .progress-bar {
            width: 100%;
            margin-left: 0px;

        }


    }
}
</style>