<template>
  <div class="">
  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';


export default {
  name: 'MethodAndAmountSelection',
  components: {  },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    console.log('blaa', this.transactionClassSelected);

  },
  data() {
    return {
      
    };
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() { },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";


</style>
