<template>
  <div class="relative">
    <div v-if="($refs.currencySelectionDrawer && $refs.currencySelectionDrawer.show) || showCurrencySelector"
                  @click.stop="isMobileDevice ? $refs.currencySelectionDrawer.closeDrawer() : showCurrencySelector = false;"
                  class="white-screen"></div>
    <div class="amount-container">
      <h4>{{$ml.get('transfer.titles.chooseAmount')}}</h4>
      <div @click.stop="$refs.numberInput.focus(); isCalculating = true;previousState.amount = amount"
        class="mock-input flex align-center justify-between">
        <!-- <div class="amount-display"> {{ amount | formatAmount | formatComma }} </div> -->
        <input inputmode="numeric" ref="numberInput" type="text" v-model="formattedAmount" @input="formatAmount"
          @keyup.enter="calculate()" maxlength="35" max="6" min="0">
        <div class="pointer flex justify-between" style="width: 85px;height: 100%;">
          <div @click.stop="isMobileDevice ? $refs.currencySelectionDrawer.openDrawer() : showCurrencySelector = true;"
            class="relative flex full-center pointer justify-between" style="padding: 0px 10px;height: 100%;width: 100%;">
            <img style="width: 20px;height: 20px;" :src="getFlagByCurrency(selectedCurrency)" alt="">
            {{ selectedCurrency }}
            <div v-if="showCurrencySelector" >
              <div class="currency-select-desktop">
                <div v-for="currency of selectedCountry.allowedCurrencies" @click.stop="changeCurrency(currency)"
                  class="currency-options-container pointer flex justify-between align-center" >
                  <div class=" flex">
                    <img class="country-img pointer" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)"
                      alt="" srcset="">
                    <div class=""> New Israeli Shekels ({{ currency }})</div>
                  </div>
                </div>
              </div>
            </div>
            <div  v-if="showCurrencySelector"  class="closer" @click.stop="showCurrencySelector=false"></div>
          
            <img src="../../../../assets/mygmt_v2_images/Right-Chevron.svg" style="transform: rotate(90deg);" alt="">
          </div>
          <!-- <select @click.prevent class="currency-select" @input="changeCurrency" name="bla" v-model="selectedCurrency" id="">
            <option v-for="(currency, index) in selectedCountry.allowedCurrencies" :key="index" :value="currency">
              {{ currency }}
            </option>
          </select> -->
        </div>
      </div>
    </div>

    <div v-if="!isCalculating && filterOptions && filterOptions.length">
      <div v-if="filterOptions.length > 2" class="method-filter-container flex">
        <div v-for="method of filterOptions" @click="setSelectedMethod(method)"
          class="method flex flex-column full-center pointer" :class="{ 'selected-method': selectedMethod == method }">
          <div class="img-container full-center">
            <img :src="getIconByValue(method)" alt="">
          </div>
          <div class="text-container">
            {{ $ml.get('transfer.deliveryOptions.' + method) }}
          </div>
        </div>
      </div>


      <div class="flex flex-column">
        <div v-for="(deliveryOption , index) of fiteredDeliveryOptions"
          class="delivery-options-container flex flex-column justify-between">
          <div class="option-header flex">
            <img style="width: 24px; height: 24px;" :src="getIconByValue(deliveryOption.transactionClass)" alt="">
            <div> {{ $ml.get('transfer.deliveryOptions.' + deliveryOption.transactionClass) }} - {{ deliveryOption.correspondentName }} </div>
          </div>
          <div class="option-info">
            <div class="flex justify-between">
              <div>I send:</div>
              <div> {{ deliveryOption.amount }} {{ deliveryOption.currency }}</div>
            </div>
            <div class="flex justify-between">
              <div>Comission:</div>
              <div> {{ deliveryOption.commission }}  {{ deliveryOption.currency }}</div>
            </div>
            <div class="flex justify-between">
              <div>Exchange rate:</div>
              <div> {{ deliveryOption.conversionRate || deliveryOption.estimatedExchangeRate }}</div>
            </div>
            <div class="flex justify-between">
              <div>I pay:</div>
              <div> {{ deliveryOption.amount  + deliveryOption.commission }}  {{ deliveryOption.currency }}</div>
            </div>

            <div class="flex justify-between">
              <div>They recieve:</div>
              <div> {{ deliveryOption.totalReceiveAmount }} {{ deliveryOption.payoutCurrency }} </div>
            </div>
          </div>
          <div class="option-button">
            <button @click="setTransactionClass(index)" class="btn">Select</button>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="height: 44vh;" v-if="isCalculating">
      <div class="flex flex-column full-center">
        <button @click="calculate();" class="calculate-btn btn">Calculate</button>
        <div class="cash-back flex  align-center w-100">

          <div><img src="../../../../assets/mygmt_v2_images/Present-Cashback-Sparkling.svg" alt="" srcset=""></div>
          <div>  
           <div>You have ₪50 cashback available for use.</div>
           <div>Minimum amount to use: ₪10</div>
          </div>
        </div>
      </div>
    </div>

    <lookup style="visibility: hidden;" @ready="lookupReady" ref="lookup" :logged-in="true"></lookup>
    <Drawer ref="currencySelectionDrawer" :title="'Select currency'" :drawerId="'currencySelectionDrawer'"
      class="drawer-wrapper-card" inLineDrawer>
      <template v-slot:content>
        <div>
          <div v-for="currency of selectedCountry.allowedCurrencies" @click="changeCurrency(currency)"
            class="currency-options-container flex justify-between align-center">
            <div class=" flex">
              <img class="country-img" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)" alt=""
                srcset="">
              <div class=""> New Israeli Shekels ({{ currency }})</div>
            </div>
            <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
          </div>
        </div>
      </template>
    </Drawer>
  </div>
</template>
<script>
import _ from 'lodash';
import Lookup from '../../../../components/Lookup.vue';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import Drawer from '../../../../components/Drawer.vue';


export default {
  name: 'MethodAndAmountSelection',
  components: { Lookup, Drawer },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    console.log("🚀 ~ created ~ this.selectedCountry:", this.selectedCountry)
    this.$store.commit('setCurrentOption', null);
    this.selectedCurrency = this.selectedCountry.defaultCurrency

    const blaaaaa = this.$store.getters.getListBeneficiary
  },
  data() {
    return {
      amount: '',
      previousState: {amount:'', currency:''},
      isCalculating: false,
      selectedMethod: 'ALL',
      selectedCurrency: '',
      bla: ['euro', 'shekelz'],
      showCurrencySelector: false,
      isMobileDevice: isMobile(),
      name: 'MethodAndAmountSelection'
    };
  },
  computed: {
    fiteredDeliveryOptions() {
      if (this.selectedMethod === 'ALL') return this.deliveryOptions
      return this.deliveryOptions.filter((i) => i.transactionClass.includes( this.selectedMethod.split('_')[0]) )

    },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso ) > -1);
      });
    },

    deliveryOptions() {
      return this.$store.getters.getTransferStep1Response || [];
    },
    filterOptions() {
      const deliveryOptionsUnique = this.removeDuplicatesByFirstWord(this.deliveryOptions.map((i) => i.transactionClass))
      return deliveryOptionsUnique
    },
    getCurrencyIcon() {
      const currenciesIconMap = {}
    },
    formattedAmount: {
      get() {
        return this.formatNumber(this.amount);
      },
      set(newValue) {
        this.amount = newValue.replace(/[^0-9.]/g, '');
      }
    }
  },
  watch: {
    // amount(newValue){
    // this.amount() = newValue + 'bla'
    // }
    selectedCurrency(newval, oldval) {
      console.log("🚀 ~ selectedCurrency ~ newval:", newval)
      console.log("🚀 ~ selectedCurrency ~ oldval:", oldval)
      if (oldval && newval) {
        this.previousState.currency = oldval
        this.$refs.lookup.setCurency(newval);
        this.$store.commit('setLookupCurrency', newval);
        if (this.isCalculating) return
        this.$refs.lookup.doLookup()
      }

    }
  },
  mounted() {
    const { defaultAmount, defaultCurrency } = this.selectedCountry
    this.amount = defaultAmount
    this.$refs.lookup.setAmount(this.amount);
    this.$refs.lookup.setCurency(defaultCurrency);
    // this.$refs.currencySelectionDrawer.openDrawer()
    this.$store.commit('setLookupAmount', this.amount);
    this.$store.commit('setLookupCurrency', defaultCurrency);

    console.log('drawer', this.$refs.currencySelectionDrawer);


  },
  beforeDestroy() { },
  methods: {
   setTransactionClass(index) {
    // this.$store.setTransactionClass(transactionClass)
    this.$store.commit('setCurrentOption', index);
    this.$emit('changeStep', 2)
   },
   removeDuplicatesByFirstWord(arr) {
  const map = new Map();
  arr.forEach(item => {
    const firstWord = item.split('_')[0];
    if (!map.has(firstWord)) {
      map.set(firstWord, item);
    }
  });
  return ['ALL',... Array.from(map.values())]
},


    changeCurrency(currency) {
      this.isMobileDevice ? this.$refs.currencySelectionDrawer.closeDrawer() : this.showCurrencySelector = false;
      this.selectedCurrency = currency
    },

    calculate() {
      const cleanAmount = this.amount.replaceAll(',', '');
      this.$refs.lookup.setAmount(cleanAmount);
      this.$refs.lookup.doLookup();
      this.isCalculating = false
    },

    lookupReady() {
      const { defaultAmount, defaultCurrency, iso } = this.selectedCountry
      this.amount = defaultAmount
      this.$refs.lookup.setAmount(this.amount);
      this.$refs.lookup.setCurency(defaultCurrency);
      this.$store.commit('setLookupAmount', this.amount);
      this.$store.commit('setLookupCurrency', defaultCurrency);
      if (this.deliveryOptions.length && this.deliveryOptions.every((option)=> option.destinationCountry === iso )) return  
      this.$refs.lookup.doLookup()
    },

    setSelectedMethod(method) {
      this.selectedMethod = method
    },
    formatAmount(event) {
      let input = event.target.value;
      input = input.replace(/[^0-9.]/g, '');
      if (input.startsWith('0') && !input.startsWith('0.')) {
        input = input.substring(1);
      }
      this.amount = input;
      event.target.value = this.formatNumber(input);
    },
    formatNumber(value) {
      if (!value) return '';
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },
    getIconByValue(methodName) {
      if (!methodName) return
      if (methodName === 'WALLET_DEPOSIT' || methodName === 'WALLET_TO_WALLET') methodName = 'WALLET_TRANSFER'
      return require(`../../../../assets/mygmt_v2_images/transfer_methods/${methodName}.svg`)
    },
    getFlagByCurrency(currency) {
      let countryIso = ''
      switch (currency) {
        case 'EUR':
          countryIso = 'DEU'
          break;
        case 'ILS':
          countryIso = 'ISR'
          break;
        case 'USD':
          countryIso = 'USA'
          break;

        default:
        countryIso = 'ISR'
          break;
      }
      return require(`../../../../assets/mygmt_v2_images/countries/${countryIso}.svg`)

    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

.drawer-wrapper-card {
  left: 0;
  border-radius: 20px;
  width: 100%;
  position: absolute;
  inset-block-start: 500px;
}

.white-screen {
  // height: 100vh;
  // width: 100vw;
  background-color: white;
  opacity: 0.5;
  position: 'absolute';
  z-index: 999999999;

}

.currency-options-container {
  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 40px;
  border-bottom: 1px solid #EEE;
  // z-index: 10;

  .country-img {
    margin-right: 10px;
  }
}

.amount-container {
  h4 {
    margin-bottom: 20px;
    color: #000;
font-size: 20px;
font-weight: 600;
  }
  .mock-input {
    width: 100%;
    height: 64px;
    border-radius: 5px;
    border: 1px solid #E4E8EB;
    background: #FFF;
    padding: 0px 16px 0px 10px;
    cursor: text;
    margin-bottom: 20px;

    input {
      border: none;
      color: #161932;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 75%;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .currency-select {
      border: none;
      background-color: white;
      cursor: pointer;
      height: 40px;
      width: 95px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

}

.calculate-btn {
  height: 45px;
  width: 100%;
  margin-bottom: 26px;
}

.cash-back {
  height: 80px;
  border-radius: 8px;
  border: 1px solid #E4E8EB;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
}
.closer{
  // background-color: rgba(128, 128, 128, 0.26);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.currency-select-desktop {
  width: 335px;
  position: absolute;
  background-color: white;
  top: 40px;
  z-index: 1001;
  left: -240px;
  border-radius: 5px;
border: 1px solid #EBEBEB;
background: #FFF;
box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.20);

  .currency-options-container {
    padding: 10px;
  }

  .currency-options-container:hover {
    color: #0E1F81;
    background-color: #F1F6F9;

  }
}

.method-filter-container {
  margin-top: 20px;


  .method {
    margin-bottom: 20px;
    color: #0E1F81;
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-right: 19px;
    height: 68px;
    justify-content: space-between;
    // width: 90px;
    // min-width: 90px;




    .img-container {
      border-radius: 100%;
      min-width: 46px;
      min-height: 46px;
      border: 1.5px solid #EBEBEB;


      img {
        width: 23px;
        height: 23px;
      }
    }
  }
}

.text-container {}


.selected-method {
  font-weight: 700;

  .img-container {
    border: 1.5px solid #0E1F81 !important;

  }
}

.delivery-options-container {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  min-height: 263px;
  margin-bottom: 20px;

  .option-header {
    border-bottom: 1px solid #EBEBEB;
    padding: 14px;
    font-weight: 700;

    img {
      margin-right: 10px;
    }

  }

  .option-info {
    height: 100%;
    padding: 14px;

    //  div {
    //   margin-bottom: 20px;
    //  }
  }

  .option-button {
    border-top: 1px solid #EBEBEB;
    height: 60px;
    padding: 12px 14px;

    button {
      width: 100%;
    }

  }
}

@media only screen and (min-width: $screen-tablet) {

  h4 {
  font-size: 18px;
  }

  .mock-input {
    height: 58px;
  }

  .calculate-btn {
    height: 45px;
    width: 335px;
  }


  .method-filter-container {
  margin-top: 20px;


  .method {
    height: 80px;
    margin-bottom: 24px;
    .img-container {
      border-radius: 100%;
      min-width: 56px;
      min-height: 56px;
      border: 1.5px solid #EBEBEB;


      img {
        width: 29px;
        height: 29px;
      }
    }
  }
}



  .delivery-options-container {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    min-height: 263px;

    .option-header {
      border-bottom: 1px solid #EBEBEB;
      padding: 14px;
      font-weight: 700;

      img {
        margin-right: 10px;
      }

    }

    .option-info {
      height: 100%;
      padding: 14px;
    }

    .option-button {
      border-top: 1px solid #EBEBEB;
      height: 74px;
      padding: 12px 14px;

      button {
        width: 100%;
        height: 46px;
      }

    }
  }

}
</style>
