<template>
  <div class="wrap">
    <h1 class="title">{{ $ml.with('VueJS').get('transferFundsTo') + ' ' + getTitleAmount + ' ' +
      $ml.with('VueJS').get('to') + getCountryName }}</h1>
    <h2 class="subtitle" v-text="$ml.with('VueJS').get('transferTypePick') + ':'"></h2>
    <div class="payment-type">
      <div class="wrapper" v-for="(item, index) in transferOptions" @click="selectOption(item, index)"
        :class="[{ 'disabled': item.amountLimited || !item.canSend || item.amount <= 0 }]">
        <div class="item-title">
          <i :class="iconClass(item)"></i>
          &nbsp;&nbsp;
          <strong>{{ $ml.with('VueJS').get(destinationCountry === 'ISR' ? item.transactionClass + '_ISR' :
            item.transactionClass) }}
            <span v-text="correspondentName(item)"></span>
          </strong>
        </div>
        <span :set="conversionRate = getOptionConvertionRate(item)"></span>
        <span :set="payoutCurrency = getOptionPayoutCurrency(item)"></span>
        <span :set="isEstimaed = isOptionEstimaed(item, payoutCurrency)"></span>
        <div class="item-type">
          <div class="col">
            <ul class="captions">
              <!-- <li v-if="item.transactionClass=='CASH_TRANSFER'" v-text="$ml.with('VueJS').get('correspondentSystem')"></li> -->
              <li v-text="$ml.with('VueJS').get('transferAmount')"></li>
              <li v-text="$ml.with('VueJS').get('commission')"></li>
              <li v-if="item.discount" v-text="$ml.with('VueJS').get('discount')"></li>
              <li v-text="$ml.with('VueJS').get('exchangeRate')"></li>
              <li v-text="$ml.with('VueJS').get('amountReceived')"></li>
            </ul>
            <ul class="values">
              <!-- <li v-if="item.transactionClass=='CASH_TRANSFER'">{{$ml.with('VueJS').get(item.correspondentName)}}</li>           -->
              <li :style="item.amount <= 0 ? 'color:red' : ''"><span dir="ltr">{{ item.amount | formatComma }}</span> {{
                item.currency }}</li>
              <li>
                <template v-if="item.discount">{{ item.commission + item.discount }} {{ item.currency }}</template>
                <template v-else-if="item.commission">{{ item.commission }} {{ item.currency }}</template>
                <template v-else>0</template>
              </li>
              <li v-if="item.discount"><span dir="ltr">{{ item.discount }} </span> {{ item.currency }}</li>
              <li>
                <template v-if="conversionRate">{{ conversionRate }}
                  <template v-if="isEstimaed">~{{ $ml.with('VueJS').get('estimated') }}</template>
                </template>
                <template v-else>{{ payoutCurrency }} ~</template>
              </li>
              <li>
                <template v-if="item.totalReceiveAmount">
                  <strong>
                    <span dir="ltr">{{ item.totalReceiveAmount | formatComma }}</span> {{ payoutCurrency }}
                    <template v-if="isEstimaed">~{{ $ml.with('VueJS').get('estimated') }}</template>
                  </strong>
                </template>
                <template v-else>
                  <template>{{ $ml.with('VueJS').get('calculatedAbroad') }}</template>
                </template>
              </li>
            </ul>
            <div class="pickTransferType" v-show="!item.amountLimited && item.canSend && item.amount > 0">
              <span> <img class="arrow-next" :src="blueArrow"> </span>
            </div>
            <div class="pickTransferType" v-show="item.amountLimited || !item.canSend || item.amount <= 0">
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-info-circle" fill="currentColor"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                <path
                  d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
                <circle cx="8" cy="4.5" r="1" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="group-btn">
      <a class="subtitle link" @click="changeAmount"
        v-text="$ml.get($store.getters.getAmountType == 'RECEIVE_AMOUNT' ? 'changePayoutAmount' : 'changeAmount')"></a>
    </div>

    <div class="modal-mask modal-mask-message toast" @click="closeModalDialogue">
      <div class="modal-container modal-message container-dialogue" id="modal-calculate" @click.stop>
        <div v-show="!busy" class="modal-body body-dialogue">
          <div class="modal-header">
            <a class="a-close" @click="closeModalDialogue" :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
          </div>
          <lookup ref="lookup" :logged-in="true"
            :label-names="{ amount: $ml.with('amount', $store.getters.getTransferLookupSymbol).get('changeAmountLabel') }"
            :next-btn="$ml.get('changeAmounBtn')" :fileds="{ 'amount': true }" btn-class="btn-yellow" @ready="ready"
            @execute="execute" @next-step="lookupNextStep" @back-step="backStep">
          </lookup>
        </div>
      </div>
    </div>

    <div class="modal-mask modal-mask-beneficiary" @click="closeBeneficiaryModal">
      <div class="modal-container modal-beneficiary" id="modal-beneficiary" @click.stop>
        <div>
          <div class="modal-body">
            <div class="modal-header">
              <a class="a-close" @click="closeBeneficiaryModal" :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
            </div>
            <div class="title">{{ $ml.with('VueJS').get('selectBeneficiary') + ' ' + $ml.with('VueJS').get('to') }}{{
              getCountryName }}</div>
            <router-link tag="button" class="btn btn-yellow" v-bind:to="createBeneficiaryRoute"
              v-text="$ml.with('VueJS').get('newBeneficiary')" />
            <div class="list-beneficiaries">
              <div class="item-beneficiary" v-for="(beneficiary, index) in beneficiaries"
                @click="selectBeneficiary(beneficiary)">
                <div class="item-row">
                  <ul>
                    <li class="item-title">{{ beneficiary.firstName }} {{ beneficiary.lastName }}</li>
                    <li class="item-title">
                      <span v-if="cardDeposit && beneficiary.maskedCardNumber">
                        {{ $ml.with('VueJS').get('toCreditCard') }} {{ beneficiary.maskedCardNumber | formatCardNumber }}
                      </span>
                      <span v-else-if="bankTransfer && (beneficiary.accountIdentifier || beneficiary.iban)">
                        {{ $ml.with('VueJS').get('toBankAccount') }} {{ beneficiary.accountIdentifier ?
                          beneficiary.accountIdentifier : beneficiary.iban }}
                      </span>
                      <span v-else-if="(walletTransfer && beneficiary.walletIdentifier) && !isWalletPhone">
                        {{ $ml.with('VueJS').get('toWalletAccount') }} {{ beneficiary.walletIdentifier }}
                      </span>
                      <span v-else-if="cashBank && beneficiary.cashBankName">
                        {{ $ml.with('VueJS').get('pickupPoint') }} {{ beneficiary.cashBankName }}
                      </span>
                      <span v-else-if="isWalletPhone && beneficiary.phoneNumber">
                        {{ $ml.with('VueJS').get('beneficiary.phoneNumber') }} {{ beneficiary.phoneNumber }}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="pickTransferType">
                  <span> <img class="arrow-next" :src="whiteArrow"> </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { getBeneficiary, getCashBackCreditAvailable, startTransaction, validateBeneficiary } from '../../services/api';
import app from "../../main";
import Lookup from '../../components/Lookup.vue';
import { getCurrencySymbol, modalHandler } from "@/services/utils"
import { parseConstraints } from "@/services/utils";

const icons = {
  CASH_BACK: 'fas fa-gift',
  MAX_LOAD: 'fa fa-cc-visa',
  CARD_DEPOSIT: 'fa fa-credit-card',
  PAYMENT: 'fas fa-hand-holding-usd',
  BANK_TRANSFER: 'fa fa-university bank',
  WALLET_TRANSFER: 'fas fa-wallet'
}

export default {
  name: 'Step2',
  components: { Lookup },
  data() {
    return {
      countries: [],
      transactionClassSelected: '',
      busy: false,
    }
  },
  computed: {
    destinationCountry() {
      return this.$store.state.transfer.destinationCountry;
    },
    createBeneficiaryRoute() {
      return { name: 'transfer-beneficiary', params: { action: 'create' } }
    },
    transferOptions() {
      return this.$store.getters.getTransferStep1Response.filter(element => element.transactionClass !== 'WALLET_DEPOSIT' && element.transactionClass !== 'WALLET_TO_WALLET' && element.transactionClass !== 'WALLET_FROM_WALLET' && (element.transactionClass !== 'CARD_DEPOSIT' || element.destinationCountry !== 'ISR'));
    },
    getCountryName() {
      return this.$store.getters.getCountryName;
    },
    getTitleAmount() {
      return this.$store.getters.getTitleLookupAmount
    },
    cardDeposit() {
      return this.transactionClassSelected == 'CARD_DEPOSIT';
    },
    bankTransfer() {
      return this.transactionClassSelected == 'BANK_TRANSFER';
    },
    walletTransfer() {
      return this.transactionClassSelected == 'WALLET_TRANSFER';
    },
    cashBank() {
      return this.transactionClassSelected == 'CASH_TRANSFER' && this.$store.getters.getSelectedTransferOption.correspondentType == 'TRANGLO';
    },
    isWalletPhone() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES_WECHATPAY';
    },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.destinationCountry) > -1);
      });
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    blueArrow() {
      return require(this.$store.state.locale.lang === 'il' ? '../../assets/images/icons/i-arrow-left.png' : '../../assets/images/icons/i-arrow-right.png')
    },
    whiteArrow() {
      return require(this.$store.state.locale.lang === 'il' ? '../../assets/images/icons/i-arrow-left-grey.png' : '../../assets/images/icons/i-arrow-right-grey.png')
    },
  },
  methods: {
    ready() {
      this.$refs.lookup.setCurency(this.$store.getters.getLookupCurrency);
    },
    execute() {
      this.busy = true;
      this.$refs.lookup.doLookup();
    },
    lookupNextStep() {
      modalHandler('.modal-mask-message', 'removeClassOpen', 'ffffff', 'dark')
      modalHandler('.modal-mask-message', 'hide', 'ffffff', 'dark')
      this.$emit('onNextStep', 1);
    },
    backStep() {
      this.$router.push('/transfer');
    },
    changeAmount() {
      this.busy = false;
      this.$refs.lookup.setAmount(null);
      modalHandler('.modal-mask-message', 'show', '092086', 'light');
      modalHandler('.modal-mask-message', 'addClassOpen', '092086', 'light')
      $('#amount').focus()
    },
    closeModalDialogue() {
      if (!this.busy)
        modalHandler('.modal-mask-message', 'removeClassOpen', 'ffffff', 'dark')
    },
    closeBeneficiaryModal() {
      modalHandler('.modal-mask-beneficiary', 'hide', 'ffffff', 'dark')
    },
    async selectOption(item, index) {
      if (!item.canSend) {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'CORRESPONDENT_BLOCKED');
        return;
      }
      if (item.amountLimited) {
        this.$store.commit('setShowModalError', true);
        const message = this.$ml.with('amount', item.amountLimit).with('currency', getCurrencySymbol(item.currency)).get("TRANSACTION_LIMIT");
        this.$store.commit('setErrorMessage', message);
        return;
      }
      if (item.amount <= 0) {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'ERROR_NEGATIVE');
        return;
      }
      this.$store.commit('setCurrentOption', index);
      sessionStorage.setItem('currentDeliveryOption', JSON.stringify(this.$store.getters.getSelectedTransferOption));
      this.$store.commit('setTransferAmount', item.amount);
      this.$store.commit('setTransferCurrency', item.currency);

      this.$store.commit('resetCashBack');
      if (this.getData().currency != 'ILS') {
        getCashBackCreditAvailable(this.getData(), (data) => {
          this.$store.commit('setCashBackCredit', data);
          this.$store.commit('setCashBackCurrency', this.getData().currency);
        });
      }

      app.$Progress.start();
      await this.storeRequiredFields()
      app.$Progress.finish();
      this.transactionClassSelected = item.transactionClass;
      this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_2', item)
      if (this.$store.getters.getSelectedBeneficiary === null) {
        if (Object.keys(this.beneficiaries).length === 0) {// if no beneficiaries to this destination
          this.$router.push(this.createBeneficiaryRoute)
        } else {
          modalHandler('.modal-mask-beneficiary', 'show', '092086', 'light');
        }
      } else {
        await this.updateBeneficiary()
      }
    },
    nextStep() {
      this.$emit('onNextStep', 2);
    },
    getData() {
      return {
        amount: this.$store.state.transfer.amount,
        currency: this.$store.state.transfer.currency,
        destinationCountry: this.destinationCountry,
      };
    },
    async updateBeneficiary() {
      app.$Progress.start();
      const beneficiary = await getBeneficiary(this.$store.getters.getSelectedBeneficiary);
      let constraints = JSON.parse(sessionStorage.getItem('beneficiaryFieldsConstraints'));
      if (beneficiary.maskedCardNumber) {
        constraints = Object.assign({}, constraints, { 'maskedCardNumber': {} })
      }
      app.$Progress.finish();
      const res = await this.validateBeneficiary(beneficiary, constraints)
      if (res.error) {
        sessionStorage.setItem('currentBeneficiary', null);
        this.$store.commit('setCurrentBeneficiary', null);
        return;
      }
      if (res.valid) {
        this.$store.commit('setBeneficiaryValid', true);
        this.nextStep()
      } else {
        await this.$router.push({ name: 'transfer-beneficiary', params: { id: beneficiary.id, action: 'update' } });
      }
    },
    async storeRequiredFields() {
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      let lookupDto = selectedOption.lookupDto;
      const response = await startTransaction({
        "amount": selectedOption.amount,
        "currency": selectedOption.currency,
        "destinationCountry": selectedOption.destinationCountry,
        "sessionId": selectedOption.sessionId,
        "payoutCurrency": selectedOption.payoutCurrency,
        "transactionClass": selectedOption.transactionClass,
        "correspondentId": selectedOption.correspondentId,
        "commission": selectedOption.commission,
        "amountLimit": selectedOption.amountLimit,
        "transactionRate": this.$store.getters.getSelectedExchangeRate,
        "lookupAmount": lookupDto && lookupDto.lookupAmount,
        "lookupRate": lookupDto && lookupDto.rate,
        "lookupBaseRate": lookupDto && lookupDto.baseRate
      });
      parseConstraints(response.fields)
      sessionStorage.setItem('requiredFields', JSON.stringify(response.fields));
      this.$store.commit('setCreditAvailabilityMap', response.creditAvailabilityMap);
    },
    async validateBeneficiary(beneficiary, constraints) {
      let resVal = { valid: true }

      for (const key in beneficiary) {
        if (beneficiary.hasOwnProperty(key) && constraints.hasOwnProperty(key)) {
          resVal.valid = (await this.$validator.verify(beneficiary[key], constraints[key])).valid
          if (!resVal.valid) {
            break;
          }
        }
      }
      if (resVal.valid) {
        let res = await validateBeneficiary({
          beneficiary: beneficiary,
          correspondentId: this.$store.getters.getSelectedTransferOption.correspondentId,
          transaction: {
            amount: this.$store.getters.getSelectedTransferOption.amount,
            transactionClass: this.$store.getters.getSelectedTransferOption.transactionClass,
            payoutCurrency: this.$store.getters.getSelectedTransferOption.payoutCurrency
          }
        })
        resVal.valid = res.result.valid;
        if (res.errorCode)
          resVal.error = true;
      }
      return resVal
    },
    async selectBeneficiary(beneficiary) {
      this.$store.commit('setCurrentBeneficiary', beneficiary.id); // after reload is undefined
      sessionStorage.setItem('currentBeneficiary', beneficiary.id); // for reload .
      this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_3', {error_count: 0,beneficiary: 'existing_beneficiary' })
      await this.updateBeneficiary();
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    iconClass(item) {
      return item.transactionClass === 'WALLET_TRANSFER' && item.destinationCountry === 'CHN' ? item.correspondentIdentifier === 'THUNES_WECHATPAY' ? 'fa fa-weixin' : 'fab fa-alipay' : (icons[item.transactionClass] || 'fa fa-money')
    },
    getOptionPayoutCurrency(opt) {
      return opt.validCurrencyIndicator ? opt.payoutCurrency : opt.estimatedReceiveCurrency ? opt.estimatedReceiveCurrency : '~'
    },
    getOptionConvertionRate(opt) {
      return opt.validCurrencyIndicator ? opt.conversionRate : opt.estimatedExchangeRate
    },
    isOptionEstimaed(opt, curr) {
      return ['MONEYGRAM', 'GOLDEN', 'CONTACT'].includes(opt.correspondentType) && opt.currency !== curr
    },
    correspondentName(item) {
      if (item.destinationCountry === 'CHN') {
        return item.transactionClass === 'WALLET_TRANSFER' ? '- ' + (item.correspondentIdentifier === 'THUNES_WECHATPAY' ? this.$ml.get('NAME_WECHATPAY') : this.$ml.get('NAME_ALIPAY')) : item.transactionClass === 'CARD_DEPOSIT' ? '- ' + this.$ml.get('NAME_UNIONPAY') : '';
      } else if (item.transactionClass === 'WALLET_TRANSFER' && item.destinationCountry === 'IND') {
        return '- ' + this.$ml.get('NAME_UPI')
      }
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.countries = require('../../data/countries');
    this.$store.dispatch('loadBeneficiary');
    this.$store.commit('setTransferStep1Response', this.transferOptions);
  }
}
</script>
<style lang="scss">
.modal-beneficiary {
  animation: topDown ease 0.3s;
  max-width: 640px;
  width: auto;
  height: auto;
  padding-bottom: 43px;
  padding-top: 48px;
  text-align: center;

  .list-beneficiaries {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  .title {
    font-size: 30px;
    color: #fdffff;
  }

  .btn {
    margin: 38px auto 0;
    min-width: 151px;
    width: auto;
    display: inline-block;
    padding: 0 5px;
  }

  .item-beneficiary {
    width: 98%;
    height: 88px;
    border: 0.7px solid rgb(236, 236, 236);
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    padding: 10px 16px 10px;
    display: flex;
    line-height: 25px;
    margin-top: 12px;
    font-size: 18px;
    color: #fdffff;
    margin: 10px 15px 0;
    cursor: pointer;

    &:hover {
      border: 2.5px solid rgb(236, 236, 236);
    }

    .item-row {
      display: flex;
      justify-content: center;
      align-items: center;
      width: inherit;
    }

    .item-title {
      margin: 4px 8px;
      font-size: 18px;
    }

    .pickTransferType {
      position: relative;
      left: 0;
      margin-right: auto;
      top: 35%
    }
  }
}
</style>