const state = {

    destinationCountry: null,
    lookupCurrency: null,
    lookupAmount: null,
    lookupPayoutCurrency: null,
    lookupCurrencyRate: null,
    transfetLimit: null,
    dummyIls: null,

    creditLimit: {
        limit: '',
        message: '',
        available: false,
        risky: false,
    },

};

const mutations = {

    setCreditLimit(state, data) {
        state.creditLimit = data;
    },
    setDestinationCountry(state, data) {
        state.destinationCountry = data;
    },
    setLookupCurrency(state, data) {
        state.lookupCurrency = data;
    },
    setLookupAmount(state, data) {
        state.lookupAmount = data;
    },
    setLookupPayoutCurrency(state, data) {
        state.lookupPayoutCurrency = data;
    },
    setLookupCurrencyRate(state, data) {
        state.lookupCurrencyRate = data;
    },
    setTransfetLimit(state, data) {
        state.transfetLimit = data;
    },
    setDummyIls(state, data) {
        state.dummyIls = data;
    },
};

const getters = {

    getDummyIls(state) {
        return state.dummyIls;
    },
    getCreditLimit(state) {
        return state.creditLimit;
    },
    getLookupCurrency(state) {
        return state.lookupCurrency;
    },
    getLookupAmount(state) {
        return state.lookupAmount;
    },
    getLookupCurrencyRate(state) {
        return state.lookupCurrencyRate;
    },
    getTransfetLimit(state) {
        return state.transfetLimit
    },
    getLookupCurrencySymbol() {
        return getCurrencySymbol(state.lookupCurrency);
    },
    getLookupDummyIlsSymbol() {
        return getCurrencySymbol(state.dummyIls.currency);
    },
    getLookupCountryName() {
        return getCountryNameByIso(state.destinationCountry)
    },
    getLookupCountryIso() {
        return state.destinationCountry
    },
    getLookupCurrencyByAmountType() {
        return state.lookupPayoutCurrency ? getCurrencySymbol(state.lookupPayoutCurrency) : getCurrencySymbol(state.lookupCurrency);
    },
};

const actions = {};

import { getCurrencySymbol, getCountryNameByIso } from "../../services/utils"

export default {
    state,
    mutations,
    getters,
    actions
}