<template>
  <div>
    {{ beneficiary }}
  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';


export default {
  components: { },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
  },
  data() {
    return {
      selectedCountry: {},
      selectedBeneficiary: {},
      isMobileDevice: isMobile(),
      };
  },
  computed: {
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso ) > -1);
      });
    },
  },
  watch: {
  },
  mounted() {
  },
  beforeDestroy() { },
  methods: {

  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";


</style>
