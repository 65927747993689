<template>
<div class="transfer-lookup">
  <form @submit.prevent="next">
    <div  v-if="!fileds || fileds.country" class="form-group">
        <label for="destinationCountry" v-text="labels.country || $ml.get('countryDestination')"></label>
        <div class="custom-select" id="destinationCountry">
            <model-list-select :isDisabled="!!$store.getters.getSelectedBeneficiary" :list="countries" name="destinationCountry" v-validate="'required'" class="form-control"
                        v-model="destinationCountry"
                        option-value="iso"
                        :option-text="$store.state.locale.lang">
            </model-list-select>
            <span class="error">{{ errors.first('destinationCountry') }}</span>
        </div>
    </div>
    <div v-if="!fileds || fileds.currency" :class="walletMode !== this.$walletTransferType.NONE ? 'form-group currency-input' : 'form-group'">
        <div v-if="transferCurrency">
            <label for="currency" v-text="labels.currency ||  $ml.get('currency')"></label>
            <div class="custom-select">
                <select name="currency" id="currency" class="form-control" v-validate="'required'" v-model="currency">
                    <option v-for="it in currencies" :key="it.key" :value="it.currency">{{it.currency}}</option>
                </select>
            </div>
        </div> 
        <div v-else>
            <label for="payoutCurrency" v-text="labels.payoutCurrency || $ml.get('currencyDestination')"></label>
            <input name="payoutCurrency" id="payoutCurrency" disabled class="form-control" :value="payoutCurrency">
        </div>
    </div>
    <div v-if="!fileds || fileds.amount" :class="walletMode !== this.$walletTransferType.NONE ? 'form-group amount-input' : 'form-group'">
        <label for="amount" v-text="labels.amount || $ml.get(transferCurrency ? 'transferAmount' : 'recipientAmount')"></label>
        <input type="tel" name="amount" id="amount" class="form-control" v-validate="{required: true, regex: /^[1-9]\d*(\.\d+)?$/, decimal: decimal, max_value: getCurrencyLimit, min_value: 1}" v-model="amount">
        <span class="error">{{ errors.first('amount') }}</span>
    </div>
    <a v-show="(!fileds || fileds.exchange) && showPayoutCurrency" class="change-amount-type" @click="changeAmountType"><i class="fa fa-exchange" aria-hidden="true"></i> {{this.$ml.with('curr', this.exchangeCurrency).get('calculatedBy')}}</a>
    <div>
      <button :class="['btn wide', btnClass]" v-text="nextBtn"></button>
    </div>
  </form>
</div>
</template>

<script>
  import app from '../main';
  import {EventBus} from "../event-bus";
  import countries from '../data/countries';
  import {feeLookup, preLookup, feeLookupOpen, preLookupOpen} from "../services/api";
  import {localesDictionary} from "../services/utils"
  import LookupType from "../enums/lookupType";

  export default {
    props: {
      loggedIn: {
        type: Boolean
      },
      recaptchaToken: {
        type: String
      },
      nextBtn: {
        type: String
      },
      btnClass:{
        type: String
      },
      labelNames:{
        type: Object 
      },
      fileds:{
        type: Object
      },
      walletMode: {
        type: String,
      },
      currenciesProp: {
        type: Array
      }
    },
    data() {
      const amountType = this.$store.getters.getAmountType || LookupType.SENDER;
      const amount = amountType === LookupType.RECEIVER ? this.$store.state.transfer.payoutAmount : this.$store.getters.getLookupAmount
      return {
          countries,
          amount: amount || this.$route.query.amount,
          currency: this.$store.state.transfer.currency,
          payoutCurrency: this.$store.state.transfer.payoutCurrency,
          destinationCountry: this.$store.state.transfer.destinationCountry || this.$route.query.destinationCountry,
          transactionClass: this.$store.state.transfer.transactionClass,
          correspondentName: this.$store.state.transfer.correspondentName,
          myGmtPaymentType: null,
          creditLimit:'',
          hideCountryAfterPreLookup:false,
          currencies:[],
          amountType:amountType,
          transferCurrency: amountType !== LookupType.RECEIVER,
          payoutCurrencyRate: null
      }
    },
    watch: {
      destinationCountry(val){
        if(val) {
            this.transferCurrency = true;
            this.payoutCurrency = null;
            this.$store.commit('setDestinationCountry', val);
            this.doPreLookup();
        }
      },
      currency(val){
        this.setLookupCurrency(val)
      },
      transferCurrency(val, olddVal){
        if(val != olddVal )
            this.amount = null;
        this.$store.commit('setLookupPayoutCurrency', val ? null : this.payoutCurrency);
      }
    },
    created (){
      EventBus.$on('changeLang', lang => {
        this.$validator.localize(this.dictionary());
        this.$validator.localize(lang);
      });
      EventBus.$on('clearTransferState', () => {
        this.$store.commit('setTransferStep1', {});
      });
      this.countries = _.orderBy(this.countries, this.getLang).filter(item => {return item.moneygram !== false});
      if(!this.destinationCountry){
        this.destinationCountry = this.loggedIn ? JSON.parse(localStorage.getItem('registrationData')).country : 'ISR';
        if(this.destinationCountry == 'ISR' && this.loggedIn || !this.countries.some(item => {return item.iso === this.destinationCountry}))
          this.hideCountryAfterPreLookup = true;
      }
      else 
        this.doPreLookup();
    },
    methods: {
      next() {
        console.log('next');
        this.$validator.validate().then(result => {
            if (result) {
                this.$store.commit('setLookupAmount', this.amount);
                this.$store.commit('setTransferStep1', this.getData());
                this.$emit('execute');
            }
        });
      },
      getData() {
        let minimum = +(JSON.parse(localStorage.getItem('lookupWithFeeMin')) || 0);
        this.amountType = this.transferCurrency ? this.currency == 'ILS' && this.amount > minimum ? LookupType.SENDER_FEE_INCLUDED : LookupType.SENDER : LookupType.RECEIVER ;
        this.setLookupCurrency(this.currency)
        return {
            amount: this.amount,
            currency: this.currency,
            destinationCountry: this.destinationCountry,
            transactionClass: this.transactionClass,
            correspondentName: this.correspondentName,
            amountType: this.amountType,
            myGmtPaymentType: this.myGmtPaymentType,
            recaptchaToken: this.getRecaptchaToken
        };
      },
      doLookup(transactionClass, destinationCountry, correspondentName, myGmtPaymentType) {
        if (transactionClass) {
          this.transactionClass = transactionClass
        }
        if (destinationCountry) {
          this.destinationCountry = destinationCountry
        }
        if (correspondentName) {
          this.correspondentName = correspondentName
        }
        if (myGmtPaymentType) {
          this.myGmtPaymentType = myGmtPaymentType
        }
        app.$Progress.start();
        const callback = (data) => {
            app.$Progress.finish();
            if (!data.errorCode){
                if(data.result.length){
                    this.$store.commit('setTransferStep1Response', data.result);
                    this.$store.commit('setTransferPayoutCurrency', this.payoutCurrency);
                    this.$store.commit('setTransferPayoutAmount', data.result[0].totalReceiveAmount);
                    this.$emit('next-step');
                } else {
                    this.$store.commit('setShowModalError', true);
                    this.$store.commit('setErrorCode', 'CORRESPONDENT_BLOCKED');
                }
            } else {
              this.$emit('back-step');
            }
        }
        if(this.loggedIn) {
          feeLookup(this.getData(), callback);
        } else {
          feeLookupOpen(this.getData(), callback);
        }
      },
      doPreLookup(){
        const callback = (data) => {
            if((!data.result.currencies.send.some(el => el.currency == 'ILS')) && (this.hasWallet || localStorage.getItem('showCardLoad') === 'true')){
              this.$store.commit('setDummyIls', data.result.currencies.send[0]);
              data.result.currencies.send.unshift({currency: 'ILS', rate: 1})
            } else {
              this.$store.commit('setDummyIls', null);
            }
            this.currencies = this.currenciesProp || data.result.currencies.send;
            this.payoutCurrency = data.result.currencies.payout.currency;
            this.payoutCurrencyRate = data.result.currencies.payout.rate; // for limit calculation only
            this.currency = this.currencies[0].currency; // set default currency for the country
            this.$store.commit('setCreditLimit', data.result.limitMap);
            this.creditLimit = data.result.limitMap.risky || data.result.limitMap.nationalityLimit ? data.result.limitMap.limit : null; // at this point there is no limit for regular countries  
            if(this.hideCountryAfterPreLookup) 
                this.destinationCountry = this.hideCountryAfterPreLookup = null;
            this.$emit('ready');
        }
        if(this.loggedIn)
           preLookup(this.getData(), callback)
        else
           preLookupOpen(this.getData(), callback);
      },
      changeAmountType(){
        this.transferCurrency = !this.transferCurrency;
      },
      dictionary(){
        const custom = {
          amount: {
              regex: this.$ml.get("E_NUMERIC"),
              decimal: this.$ml.with('VueJS').get("E_DECIMAL"),
              required: this.$ml.get("E_REQUIRED_amount"),
              max_value: this.$ml.get("E_MAX_VALUE"),
              min_value: this.$ml.get("E_REGEX"),
          },
          currency: {
              required: this.$ml.get("E_REQUIRED_currency")
          },
          destinationCountry: {
              required: this.$ml.get("E_REQUIRED_destinationCountry")
          },
        }
        return localesDictionary({ custom: custom });
      },
      setCurency(currency) { 
        this.currency = currency;
      },
      setAmount(amount) { 
        this.amount = amount;
      },
      setLookupCurrency(curr){
        this.$store.commit('setLookupCurrency', curr);
        this.$store.commit('setLookupCurrencyRate',this.getRate);
      },
      // focusAmount(){
      //   if(this.destinationCountry && !this.hideCountryAfterPreLookup)
      //      setTimeout(() => { $('#amount').focus() }, 100);
      // }
    },
    computed:{
      getLang() {
          return this.$store.getters.getLang;
      },
      getRate(){
        const curr = this.currencies.find(it =>{ return it.currency === this.currency});
        return curr && curr.rate || '';
      },
      // old version - monthly limit checked here.
      // getCurrencyLimit(){
      //   const limitByRate = (!this.transferCurrency && this.payoutCurrencyRate ? this.creditLimit * this.payoutCurrencyRate : this.creditLimit / this.getRate);
      //   let limit = Math.max(Math.floor(limitByRate), this.walletBalance || 0).toFixed();
      //   if (this.walletMode === this.$walletTransferType.FRIEND || this.walletMode === this.$walletTransferType.CARD ) {
      //     limit = Math.min(limit, this.walletBalance || 0).toFixed();
      //   }
      //   this.$store.commit('setTransfetLimit', limit)
      //   return limit;
      // },
      // new version - only risky country limit checked here
      getCurrencyLimit() {
        if (this.creditLimit) { // risky
          const limit = (!this.transferCurrency && this.payoutCurrencyRate ? this.creditLimit * this.payoutCurrencyRate : this.creditLimit / this.getRate).toFixed();;
          this.$store.commit('setTransfetLimit', limit)
          return limit;
        } 
        return false // no limit at all
      },
      hasWallet() {
        return this.$store.getters.hasWallet('ILS');
      },
      walletBalance() {
        return this.$store.getters.getWalletBalance(this.currency);
      },
      decimal(){
          return this.destinationCountry == 'UKR' ? 0 : 2
      },
      showPayoutCurrency() {
          return this.payoutCurrency;
      },
      exchangeCurrency(){
          return this.transferCurrency ? this.payoutCurrency : this.currency
      },
      getRecaptchaToken(){
        return this.recaptchaToken;
      },
      labels(){
        return this.labelNames || {};
      }
    },
    mounted() {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(this.$store.getters.getLang);
    },
  }
</script>

<style lang="scss">
@import "../assets/styles/partials/variables", "../assets/styles/partials/mixins";

.transfer-lookup{
  .form-group, .btn {
    display: inline-block;
    vertical-align: middle;
  }
  .form-group {
    margin-left: 14px;
    label {
      text-align: start;
      color: #4a4a4a;
      margin-right: 10px;
    }
    .form-control {
      margin-top: 5px;
    }
    select.form-control {
      margin-top: 0;
    }
    .custom-select {
      margin-top: 5px;
    }
  }
  .btn {
    margin-top: 28px;
    min-width: 200px;
    height: 38px;
    width: auto;
    padding: 0 10px;
    font-size: 18px;
    text-align: center;
    line-height: 38px;
  }
  .change-amount-type {
    display: block;
    width: fit-content;
    text-align: start;
    cursor: pointer;
    padding-block-start: 16px;
    margin-inline-start: 94px;
    margin-bottom: -15px;
  }
  .error {
    display: block;
  }
}
.modal-mask {
  .transfer-lookup{
    .form-group{
      margin: 0!important;
      .form-control{
        appearance: none;
        text-align: center !important;
        letter-spacing: 2px;
        font-size: 21px;
        border: none;
      }
      .error {
        text-align: start;
        margin-inline-start: 6px;
        white-space: nowrap;
      }
      label{
        color: white;
        text-align: center;
        margin: 0!important;
      }
    }
  }
}

.amount-input .form-control{
  width: 200px;
}

@include max-screen($screen-phones) {
  .currency-input.form-group:nth-child(1){
      width: 41% !important;
      margin-top: 20px;
      margin-bottom: 15px;
      margin-inline-end: 8px;
  }

  .amount-input.form-group:nth-child(2){
    width: 56% !important;
    margin: 20px 0 15px !important;
  }
}
</style>