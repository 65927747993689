export const th = {
  aboutLink: 'en/my-gmt/',
  accessibleBranch: 'สามารถเข้าถึงได้',
  accountNumber: 'หมายเลขบัญชีของผู้รับผลประโยชน์',
  activityTime: 'เวลาที่ทำกิจกรรม:',
  actualRate1: 'อัตราออนไลน์',
  and: 'และ ',
  appDownload: 'ดาวน์โหลดแอปของเรา',
  appDownloadBtn: 'ดาวน์โหลดโดยตรง \n ไปยังโทรศัพท์ของคุณ',
  actualRate2: 'ตอนนี้คือ',
  addAddress: 'เพิ่มที่อยู่',
  additionalPhone: 'หมายเลขโทรศัพท์อื่น',
  amount: 'จำนวนเงิน',
  AMOUNT_LIMITED: 'ขออภัย! แต่จำนวนเงินนี้สูงเกินไปสำหรับวงเงินของบัตรของผู้รับผลประโยชน์ คุณสามารถลองอีกครั้งด้วยจำนวนเงินที่น้อยลง เรายินดีช่วยเหลือคุณ',
  recipientAmount: 'ผู้รับจะได้รับ',
  amountIn: 'จำนวนเงินสกุล ILS',
  amountReceived: 'จำนวนเงินที่จะได้รับ',
  amountTogether: 'จำนวนเงินรวม',
  anotherSurname: 'นามสกุลอื่น',
  answerQuestion: 'ในระหว่างนี้ คุณสามารถค้นหาคำตอบสำหรับคำถามของคุณได้',
  API_EXCEPTION: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  approvalTransaction: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินได้รับอนุมัติแล้ว อีกสักครู่ คุณจะได้รับ SMS ยืนยันการทำธุรกรรม',
  maxCardLoadTransaction: 'ยอดเยี่ยม! การเรียกเก็บเงินสำเร็จแล้ว, และ myGMT card ของคุณได้ถูกเติมเงินเข้าเรียบร้อยแล้ว ',
  approvedPolicies: 'ฉันได้อ่านและยอมรับใน T&C',
  arrivalBranch: 'ขึ้นเงินสดที่ร้านของ GMT',
  arrivalBranchMsg1: 'เราจะแจ้งหมายเลขคำสั่งซื้อสำหรับชำระเงิน หมายเลขนี้สามารถใช้งานได้ 24 ชั่วโมง',
  arrivalBranchMsg2: 'และการชำระเงินจะเป็นสกุล ILS ตามอัตราแลกเปลี่ยนในร้าน',
  BAD_REQUEST: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  BANK_TRANSFER: 'ฝากเงินเข้าบัญชีในต่างประเทศ',
  BANK_TRANSFER_ISR: 'ฝากเงินเข้าบัญชี',
  UPI_BANK_TRANSFER: 'Deposit to Account abroad by UPI',
  BANK_TRANSFER_TO: 'ฝากเงินเข้าบัญชีใน {dest}',
  BANK_TRANSFER_PAYMENT: 'โอนเงินผ่านธนาคาร',
  bankAccount: 'บัญชีธนาคาร',
  bankCode: 'หมายเลขธนาคาร',
  bankName: 'ชื่อบัญชี',
  bank: 'ธนาคาร',
  beneficiary: {
    abaRoutingNumber: 'Bank Routing/หมายเลข ABA‏',
    accountIdentifier: 'หมายเลขบัญชี',
    walletIdentifier: 'หมายเลขบัญชีวอลเลท',
    alipayWallet: 'หมายเลขบัญชี',
    address: 'ที่อยู่',
    bankAddress: 'ที่อยู่ของธนาคาร',
    bankCity: 'เมืองของธนาคาร',
    bankName: 'ชื่อบัญชี',
    cashBankName: 'จุดรับเงิน',
    walletBankName: 'ชื่อบัญชีวอลเลท',
    branch: 'สาขาธนาคาร',
    city: 'เมือง',
    country: 'ปลายทาง',
    firstName: 'ชื่อจริง',
    iban: 'IBAN',
    ifsc: 'IFSC',
    lastName: 'นามสกุล',
    maskedCardNumber: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
    maskedCardNumber_unionPay: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
    phoneNumber: 'หมายเลขโทรศัพท์ของผู้รับผลประโยชน์',
    province: 'รัฐ/จังหวัด',
    swiftCode: 'รหัส SWIFT/BIC ของธนาคาร',
    zipCode: 'รหัสไปรษณีย์',
    relationshipWithCustomer: "ความสัมพันธ์ระหว่างคุณและผู้รับผลประโยชน์คือ?",
    identification: "Identification Number",
    upi: "UPI ID or number",
    upi_IND: "UPI ID or number",

  },
  transfer: {
    deliveryOptions: {
        ALL: 'ทั้งหมด',
        CASH_TRANSFER: 'การโอนเงินสด',
        BANK_TRANSFER: 'การโอนผ่านธนาคาร',
        WALLET_DEPOSIT: 'ฝากเงินในกระเป๋า',
        WALLET_FROM_WALLET: 'ฝากเงินในกระเป๋า',
        WALLET_TO_WALLET: 'ฝากเงินในกระเป๋า',
        CARD_DEPOSIT: 'ฝากเงินจากบัตร'
    },
    deliveryOptionsInfo: {
        iSend: 'ฉันส่ง',
        iPay: 'ฉันจ่าย',
        theyRecieve: 'พวกเขาได้รับ'
    },
    titles: {
        chooseAmount: 'เลือกจำนวน',
        chooseBeneficiaty: 'เลือกผู้รับประโยชน์',
        chooseDestination: 'เลือกปลายทาง',
        recentDestinations: 'ปลายทางล่าสุดของคุณ',
        allDestinations: 'ปลายทางทั้งหมด',
        popularDestinations: 'ปลายทางยอดนิยม',
        sendMoney: 'ส่งเงิน',
        nothingFound: 'ไม่พบข้อมูล',
        typedCorrectly: 'ตรวจสอบให้แน่ใจว่าคุณพิมพ์ชื่อประเทศถูกต้อง'
    },
    buttons: {
        select: 'เลือก',
        addNewBeneficiary: 'เพิ่มผู้รับประโยชน์ใหม่',
        coupon: {
            iHaveACoupon: 'ฉันมีคูปอง',
            couponApplied: 'คูปอง "{couponNumber}" ถูกนำไปใช้แล้ว!'
        },
        back:'กลับ'
    },
    paymentMethods: {
        titles: {
            payingWith: 'ชำระ {amount} ด้วย'
        },
        subtitles: {
            walletBalance: 'ยอดเงินในกระเป๋าของคุณคือ',
            creditCard: 'เรารับ Visa, Mastercard, AMEX, Diners, Apple Pay, Google Pay',
            bankTransfer: 'โอนโดยตรงไปยังบัญชีธนาคาร'
        }
    },
    countrySearchPlaceholder: 'ค้นหาปลายทางหรือสกุลเงิน'
},

  relationshipWithCustomer: {
    SELF: 'ตัวเอง',
    CHILD: 'ลูกของฉัน',
    PARENT: 'พ่อแม่ของฉัน',
    SPOUSE: 'หุ้นส่วนของฉัน',
    SIBLING: 'พี่น้องของฉัน',
    EX_SPOUSE: 'คู่สมรส'
  },
  BENEFICIARY_INVALID: 'ขออภัย! รายละเอียดของผู้รับผลประโยชน์ของการโอนเงินนี้ไม่ถูกต้อง คุณสามารถแก้ไข แล้วลองอีกครั้ง เรายินดีช่วยเหลือคุณ',
  BENEFICIARY_NOT_FOUND: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  beneficiaryRemoveDialogue: 'คุณต้องการลบออกจาก {beneficiary} รายชื่อผู้รับผลประโยชน์ของคุณ?',
  beneficiaryRemoveDialogue2: 'การดำเนินการนี้จะไม่มีผลกระทบต่อการโอนเงินปัจจุบัน',
  billingSuccessful: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณได้รับอนุมัติแล้ว',
  billingSuccessfulBankTransfer: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัญชีที่ระบุได้รับอนุมัติแล้ว',
  billingSuccessfulBankTransferISR: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัญชีที่ระบุได้รับอนุมัติแล้ว',
  billingSuccessfulCardDeposit: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัตรที่ระบุได้รับอนุมัติแล้ว',
  CARD_REJECTED: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  BLACKLIST: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  BLACKLIST_CARD: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  blocked: 'ถูกปิดกั้นไว้',
  blog: 'ปิดกั้น',
  bntClear: 'ยกเลิก',
  bntContinue: 'ถัดไป',
  bntContinued: 'ขั้นตอนถัดไป',
  bntImgDelete: 'อัปโหลดอีกครั้ง',
  bntSave: 'บันทึก',
  branchNumber: 'สาขา',
  btnAccept: 'ยอมรับ',
  btnCheckStatusCheck: 'พร้อมชำระ',
  btnCheckStatusNoCheck: 'ไม่สามารถชำระเงินได้',
  btnClose: 'ปิด',
  btnDelete: 'ลบ',
  btnDone: 'เสร็จแล้ว',
  btnEnter: 'เข้าเว็บไซต์',
  btnFinish: 'เสร็จแล้ว',
  btnFinishREgistration: 'เพื่อลงทะเบียนให้เสร็จ',
  btnOk: 'อนุมัติ',
  btnRegistration: 'สร้างบัญชีใหม่ >',
  btnReturn: 'ย้อนกลับ',
  btnSearch: 'ค้นหา',
  btnSend: 'ส่ง',
  btnSignIn: 'ส่งรหัสผ่านให้ฉันทาง SMS',
  btnBiometricSignInFaceId: 'ล็อกอินด้วย Face ID',
  btnBiometricSignInTouchId: 'ล็อกอินด้วย Touch ID',
  btnToMyGmt: 'ไปยัง myGMT',
  btnTransfer: 'ส่งเงิน',
  btnTransferFooter: 'ส่งเงิน',
  btnUploadDocuments: 'เพิ่มเอกสาร',
  business: 'อาชีพ',
  biometricsHeader: 'การระบุตัวตนด้วย \n การสแกนใบหน้าหรือนิ้วมือ',
  biometricSectionApprove: 'ฉันได้เห็นด้วยกับ T&C',
  biometricSectionSignIn: 'ในการลงทะเบียนสำหรับการใช้งานด้วยการสแกนใบหน้าหรือนิ้วมือให้เสร็จสมบูรณ์นั้น ท่านต้องทำการลงชื่อเข้าใช้บัญชีของท่าน',
  biometricToggler: 'เข้าสู่ระบบด้วยการสแกนใบหน้าหรือนิ้วมือ',
  biometric_modal_text: 'คุณต้องการทำการยกเลิกการเข้าสู่ระบบด้วยการสแกนหรือไม่?',
  biometric_legal_clause_1: 'การระบุตัวตนด้วยไบโอเมตริกซ์ (หรือการใช้ลายนิ้วมือ หรือ การจดจำใบหน้า) จะใช้แทนการป้อนรหัสผ่าน เมื่อคุณใส่รายละเอียดของบัญชีทั้งหมดที่เกี่ยวข้องกับการใช้งาน ที่คุณใช้ในการเข้าสู่ระบบ',
  biometric_legal_clause_2: 'บริษัทไม่ได้ทำการจัดเก็บการจดจำใบหน้าของผู้ใช้, การระบุตัวตนจะอาศัยการระบุตัวตนแบบไบโอเมตริกที่กำหนดไว้ในอุปกรณ์เคลื่อนที่ของผู้ใช้',
  biometric_legal_clause_3: 'การลงทะเบียนสำหรับใช้บริการ ขึ้นอยู่กับบุคคลที่ได้รับอนุญาตให้เข้าสู่เว็บไซต์/แอพ ว่าพวกเขาต้องเป็นผู้ที่ใช้อุปกรณ์ที่มีข้อมูลระบุตัวตนแบบไบโอเมตริกซ์เท่านั้น, หากว่าได้มีการอัปเดตการระบุตัวตนทางชีวมาตรของบุคคลหลายคนบนอุปกรณ์นั้นๆ ไม่ควรใช้เพื่อลงทะเบียนสำหรับบริการ',
  biometric_legal_clause_4: 'การขอข้อมูลและ/หรือการดำเนินการบนเว็บไซต์/แอพจะถือเป็นการกระทำที่ดำเนินการโดยผู้ถือสิทธิ์ รวมถึงการกระทำที่ดำเนินการโดยบุคคลที่สามที่ละเมิดข้อ 3 ข้างต้น',
  biometric_legal_clause_5: 'ในกรณีที่อุปกรณ์เคลื่อนที่สูญหายและ/หรือถูกขโมย ต้องยกเลิกการลงทะเบียนใช้บริการทันทีโดยติดต่อฝ่ายบริการลูกค้าทางโทรศัพท์',
  biometric_legal_clause_6: 'คุณสามารถยกเลิกการใช้บริการได้ตลอดเวลาทั้งในแอปพลิเคชันและโดยติดต่อฝ่ายบริการลูกค้า',
  biometric_login_failed: 'ขออภัย การระบุตัวตนของท่านล้มเหลว',
  biometric_public_key_expired:'ขออภัยในความไม่สะดวก! เราปิดการใช้งานการเข้าสู่ระบบ\n\nไบโอเมตริกซ์ของคุณ หรือ ระบบการสแกนใบหน้า หรือ ลายนิ้วมือเพื่อรักษาบัญชีของคุณให้ปลอดภัย กรุณาลงทะเบียนเพื่อเข้าสู่ระบบไบโอเมตริกซ์ไหม่อีกครั้ง ขอบคุณ!',
  biometric_unregister_modal_title: 'ปิดการใช้งานด้วยการสแกนใบหน้าหรือนิ้วมือ',
  byExchangeRate: 'อัตราแลกเปลี่ยน',
  calculatedAbroad: 'จะถูกคำนวณในต่างประเทศ',
  calculatedBy: 'คำนวณเป็น {curr}',
  canceled: 'ยกเลิกแล้ว',
  CAPTCHA_NOT_VERIFIED: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  captionAgreement: 'ข้าพเจ้าได้อ่านและยอมรับใน',
  captionAgreementLink: 'T&C',
  captionCustomerService1: 'กรุณาติดต่อแผนกบริการลูกค้าของเรา เรายินดีช่วยเหลือคุณ',
  CARD_DENIED: 'ขออภัย! เราไม่สามารถโอนเงินเข้าบัตรนี้ได้ เรายินดีช่วยเหลือคุณ',
  CARD_DEPOSIT: 'ฝากเงินเข้าบัตรในต่างประเทศ',
  CARD_DEPOSIT_TO: 'ฝากเงินเข้าบัตรใน {dest}',
  CARD_DEPOSIT_MAX: 'การเติมเงินในบัตร',
  CARD_WRONG_DETAILS: 'ขออภัย! รายละเอียดของการโอนเงินนี้ไม่ถูกต้อง คุณสามารถแก้ไข แล้วลองอีกครั้ง เรายินดีช่วยเหลือคุณ',
  cardActionsModalTitle: 'การดำเนินการสำหรับบัตร',
  cardBlock: 'ปิดกั้นบัตร',
  cardBlockBtn: 'ปิดกั้น',
  cardBlockConfirm: {
    1: 'เราได้ปิดกั้นบัตรให้กับคุณแล้ว คุณสามารถเปิดใช้งานใหม่ได้บนเว็บไซต์',
    2: 'กรุณาติดต่อเรา หากคุณมีคำถามใดๆ ก็ตาม',
    3: 'ติดต่อเรา'
  },
  walletOrder: {
    deposited: 'นำฝากเข้า',
    verification: 'นี่คือรหัสยืนยันของคุณ',
    getVerification: 'กดเพื่อรับรหัสของคุณ',
    showTo: 'แสดงรหัสให้กับเจ้าหน้าที่',
    expiry: 'รหัสจะหมดอายุภายใน',
    minutes: 'นาที',
    returnHere: 'คุณสามารถกลับไปที่ของคุณได้ตลอดเวลา',
    mainPage: 'หน้าหลัก',
    cancel: 'ต้องการยกเลิกการออร์เดอร์',
    hooray: 'เย้',
    close: 'ปิด',
    depositedToYourWallet: 'เราได้ทำการฝากเงิน',
    depositedToYourWallet1: 'ไปที่บัญชีวอลเลทของคุณแล้ว',
    cancelModalTitle: 'คุณต้องการยกเลิกการออเดอร์นี้ใช่ไหม?',
    cancelModalText: 'คุณสามารถออเดอร์ไหม่ได้ทุกเมื่อ',
    goToActiveOrder: 'รหัสการนำฝากเงินของคุณ',
    depositCash: 'การฝากเงินสดเข้าวอลเลทของคุณ',
    btnOk: 'อนุมัติ',
    btnReturn: 'ย้อนกลับ',
    amount: 'จำนวนเงิน',
    summaryOfDetails: 'สรุปรายละเอียดการโอนเงิน',
    transferType: 'ประเภทการโอน'
  },
  wallet: {
    menu: 'กระเป๋าเงินของฉัน',
    balance: 'กระเป๋าเงินของฉัน: ',
    payWith: 'ยืนยันและจ่ายด้วย กระเป๋าเงินของฉัน',
    payment: {
      title: 'กระเป๋าเงินของฉัน',
      body: 'หลังจากที่ท่านได้กดปุ่ม " สั่งจ่าย" เราจะเริ่มทำธุรกรรมให้กับท่าน และจะปรับยอดเงินคงเหลือในบัญชีของท่านตามนั้น.',
      footer: 'หลังจากจ่ายเงินแล้ว, จะมียอดเงินคงเหลือในบัญชีของท่านเป็นจำนวน {currency}{amount}',
      inActive: {
        footer: "ขออภัย, ยอดคงเหลือในกระเป๋าเงินของท่านไม่เพียงพอในการทำธุรกรรมนี้,ท่านสามารถเปลี่ยนจำนวนเงิน หรือฝากเงินเข้าบัญชีกระเป๋าเงินให้มากกว่านี้."
      }
    },
    main: {
      title: 'สวัสดี {name},',
      card: {
        title: 'ยอดเงินใน wallet ของคุณ',
        transactionsLink: 'ดูธุรกรรมทั้งหมดของคุณ>'
      },
      depositToWallet: 'ฝากเข้า wallet ของคุณ โดย',
      sendMoney: 'ส่งเงิน',
      walletOptions: 'ตัวเลือกของ wallet',
      depositActions: {
        cash: 'ฝากด้วยเงินสด',
        bank: 'ผ่านแบงค์ไปรษณีย์',
        card: 'ฝากเข้าผ่านบัตร'
      },
      sendActions: {
        sendHome: 'ส่งเงินกลับบ้าน',
        inIsrael: 'ส่งเงินภายในอิสราเอล',
      },
      walletActions: {
        loadPrePaid: 'myGMT บัตรวีซ่า PrePaid ',
        send: 'ส่งเงินให้เพื่อนที่ร่วมใช้ myGMT',
        invite: ' เชิญเพื่อนและจะได้รับเงินคืน'
      },
      doarInstruction: 'วิธีการโอนเงินจากแบงค์ไปรษณีย์'
      // doarInstruction: 'ข้อเสนอสุดพิเศษต้อนรับปีใหม่ของ GMT!'
    },
    invite: {
      title: 'แนะนำเพื่อนและคุณจะได้รับเงินของขวัญ cash back',
      body: 'คุณและเพื่อนของคุณสามารถใช้ประโยชน์จาก myGMT ได้มากขึ้น\nส่งลิงค์การลงทะเบียนส่วนตัวของคุณให้เพื่อนของคุณ หลังจากทำธุรกรรมครั้งแรกใน myGMT คุณทั้งคู่จะได้รับ {amount} เชคเกล cash back\n\nคุณสามารถแชร์ลิงค์การลงทะเบียนส่วนบุคคลของคุณโดยใช้ปุ่มด้านล่าง',
      shareActions: {
        copy: 'คัดลอกลิงค์ของคุณ',
        copied: 'คัดลอก',
        whatsapp: 'เชิญชวนผ่าน Whatsapp',
        wechat: 'เชิญชวนผ่าน wechat',
        line: 'เชิญชวนผ่าน LINE',
        general: 'แชร์'
      },
      inviteMessages: {
        firstLine: 'สวัสดี คุณได้เข้าร่วม myGMT! ด้วยลิงค์ส่วนตัวด้านล่าง เริ่มการลงทะเบียนของคุณ',
        secondLine: 'ไปที่ myGMT และเราทั้งคู่จะได้รับเงินคืน!cash back >'
      }
    },
    deposit: {
      cash: {
        title: 'ฝากเงินเข้า wallet ของคุณ',
        body: 'คุณสามารถฝากเงินสดได้ที่ GMT ทุกสาขา\nหลังจากนั้นไม่กี่วินาที  myGMT wallet ของคุณจะได้รับการอัปเดตและคุณจะใช้งานได้ทันที',
        locations: 'สาขาของ GMT>',
        walletOrderExplanation: {
          depositAnywhere: 'คุณสามารถฝากเงินสดเข้าวอลเลท ได้ที่GMTทุกสาขา',
          amount: 'ใส่จำนวนเงินที่คุณต้องการฝาก',
          verificationCode: 'รับรหัสยืนยันของคุณ',
          teller: 'นำเงินสดและรหัสที่ได้รับส่งให้กับเจ้าหน้าที่',
          finishTitle: 'เรียบร้อย',
          finishText: 'เงินจะถูกฝากเข้าที่วอลเลทของคุณทันที'
        }
      },
      bank: {
        title: 'โอนเงินจากบัญชีธนาคารหรือบัญชี HaDoar ไปยังกระเป๋าเงินของคุณ',
        body: 'โอนเงินผ่านธนาคารไปยัง GMT และเงินจะเข้าไปยัง wallet ของคุณในทันที\nนายจ้างของคุรสามารถโอนเงินไปยังกระเป๋าตัง wallet ของคุณได้ จากบัญชีของนายจ้างเอง',
        transferDetails: 'รายละเอียดบัญชี:\nGMT tech innovation LTD\nBank HaPoalim 12\nสาขา 613\nบัญชี 545690',
        uploadDetails: 'อัปโหลดการยืนยันการโอนเงินของคุณที่นี่ อย่าลืมใส่ หมายเลขพาสปอร์ และ เบอร์โทรศัพท์ ของคุณ',
        uploadConfirmationBtn: 'ยืนยันการอัปโหลด',
        placeholder: 'เพิ่มการยืนยันการโอนเงิน',
        upload: {
          title: 'อัปโหลดการยืนยันการโอนเงินของคุณ',
          loader: 'เราได้อัพโหลดรูปภาพของคุณแล้ว',
          footer: 'อย่างลืมใส่ หมายเลขพาสปอร์ต และ เบอร์โทรศัพท์ของคุณ',
          success: 'ยอดเยี่ยม!! ทีมของเราได้ทำการดำเนินการและยอดเงินใน wallet ของคุณจะถูกอัพโหลดโดยเร็ว\nเราจะส่งการยืนยันทาง sms และ email ของคุณเช่นกัน'
        }
      }
    },
    friends: {
      title: 'ส่งให้เพื่อนที่ใช้ myGMT',
      addFriend: 'เพิ่มเพื่อนไหม่',
      newFriend: 'ส่งไปที่ myGMT วอลเลท',
      addContactByPhone: 'โอนไปยังผู้รับที่ยังไม่ได้เพิ่มเป็นเพื่อน',
      searchByNameOrPhone: 'โอนไปยังผู้รับที่ยังไม่ได้เพิ่มเป็นเพื่อน',
      header: 'ด้วยคุณสมบัติในการโอนเงินที่ใช้งานง่ายของเรา คุณสามารถส่งเงินให้เพื่อนที่ร่วมใช้งาน myGMT เหมือนกับคุณได้อย่างปลอดภัย \n วิธีการโอนที่ง่ายดายและรวดเร็ว!',
      contacts: 'เลือกจากรายการผู้ติดต่อของฉัน',
      mobilePhone: 'ใส่หมายเลขโทรศัพท์เพื่อนของคุณ',
      bulletsOne: 'ใส่หมายเลขโทรศัพท์ของเพื่อนของคุณ',
      bulletsTwo: 'กรอกจำนวนเงินที่คุณต้องการส่ง',
      bulletsThree: 'ยืนยันการทำรายการ',
      outro: 'เรียบร้อย! ยอดเงินจะถูกโอนเข้าบัญชีของเพื่อนคุณทันที \n เริ่มใช้งานได้แล้ววันนี้!',
      inviteBnt: 'เชิญชวนเพื่อนของคุณเข้าร่วม myGMT',
      list: {
        title: 'รายชื่อเพื่อน'
      },
      addModal: {
        title: 'คุณต้องการที่จะเพิ่มหรือไม่',
        footer: 'ส่งไปที่ wallet ของเพื่อนของคุณ',
        saveBtn: 'บันทึก',
        closeBtn: 'ยกเลิก'
      },
      friendCard: {
        title: 'Wallet',
        delete: 'ลบ',
        deleteDialogFirstLine: 'คุณต้องการลบ {beneficiary} ออกจากรายชื่อเพื่อนใน Wallet ของคุณหรือไม่?',
        deleteDialogSecondLine: 'การดำเนินการนี้จะไม่ส่งผลต่อการโอนที่มีอยู่',
        send: 'ส่งไปที่ wallet'
      }
    },
    transfer: {
      amount: {
        title: 'ส่งเงินไปที่ myGMT wallet'
      },
      send: {
        title: 'ส่ง {amount} ILS ไปที่ myGMT wallet'
      },
      summary: {
        title: 'ส่ง {amount} ILS ไปที่ myGMT wallet'
      },
      pay: {
        title: 'ส่ง {amount} ILS ไปที่ myGMT wallet'
      },
    },
    load: {
      amount: {
        title: 'ฝากเงินเข้า wallet ของคุณ โดย ชำระด้วยบัตรเครดิตของคุณ'
      },
      send: {
        title: 'ฝากเงินเข้า wallet ของคุณ'
      },
      summary: {
        title: 'ฝากเงินเข้า wallet ของคุณ'
      },
      pay: {
        title: 'ฝากเงินเข้า wallet ของคุณ'
      },
    },
    card: {
      amount: {
        title: 'เติมเงินเข้า myGMT card ด้วยเงินในวอลเลทของคุณ'
      },
      send: {
        title: 'เติมเงินเข้า myGMT card ด้วยเงินในวอลเลทของคุณ'
      },
      summary: {
        title: 'เติมเงินเข้า myGMT card'
      },
      pay: {
        title: 'เติมเงินเข้า myGMT card'
      }
    }
  },
  pay: 'ยืนยันคำสั่งซื้อของคุณ',
  loadCard: 'เติมเงินด้วย วอลเลท ของคุณ',
  cardBlockModalMessage: {
    1: 'หลังจากที่คุณกดปุ่ม "ปิดกั้น" แล้ว เราจะปิดกั้นบัตรของคุณชั่วคราว ไม่ให้ทำธุรกรรมใหม่ๆ หรือถอนเงินสดได้',
    2: 'ยอดคงเหลือของบัตรของคุณจะถูกบันทึกไว้',
    3: 'ในกรณีที่คุณเจอบัตรแล้ว คุณสามารถเปิดใช้งานใหม่ได้ที่นี่ หรือคุณจะขอให้เราปิดกั้นบัตรอย่างถาวร และออกบัตรใบใหม่ให้กับคุณก็ได้'
  },
  cardDepositAccepted: 'บริการนี้สามารถใช้ได้สำหรับบัตร Visa และ Mastercard เท่านั้น',
  cardHystory: 'ธุรกรรมก่อนหน้านี้',
  cardMargketing: {
    btn: 'แชทหาเราบน WhatsApp',
    footer: {
      1: 'คุณสามารถรับบัตรใบใหม่ได้ที่สาขาของ GMT',
      2: 'สำหรับรายละเอียดและการจอง'
    },
    info1: {
      con1: 'การซื้อในรัฐอิสราเอล ในต่างประเทศ และทางออนไลน์',
      con2: 'สิทธิประโยชน์ของบัตร:',
      title: 'สิทธิประโยชน์ของบัตร:'
    },
    info2: {
      con1: 'ดูยอดคงเหลือของบัตรของคุณ',
      con2: 'ติดตามสถานะการซื้อ',
      con3: 'กู้คืนรหัส PIN ของคุณ',
      con4: 'ปิดกั้นบัตรที่สูญหายหรือถูกขโมย',
      title: 'คุณสามารถ:'
    },
    subtitle: 'เรายินดีที่จะนำเสนอวิธีที่สะดวกในการควบคุมค่าใช้จ่ายของคุณ โดยไม่ต้องใช้บัญชีธนาคาร',
    title: 'บัตรเติมเงินที่ได้รับความร่วมมือจาก'
  },
  cardMerchantAddress: 'ที่อยู่',
  cardMerchantPhone: 'โทรศัพท์',
  cardNumber: 'หมายเลขบัตร',
  cardPresented: 'ใช้บัตรรูด',
  cardRoute: 'บัตรเติมเงิน',
  cardUnBlock: 'เปิดใช้งานบัตรที่ปิดกั้นไว้',
  cardUnBlockBtn: 'เปิดใช้งาน',
  cardUnBlockConfirm: 'บัตรของคุณได้ถูกเปิดใช้งานแล้ว',
  cardUnBlockModalMessage: {
    1: 'หลังจากที่คุณกดปุ่ม เปิดใช้งาน แล้ว บัตรของคุณจะถูกเปิดใช้งานสำหรับการทำธุรกรรมใหม่ๆ และการถอนเงินสด',
    2: 'กรุณาเปิดใช้งาน เมื่อคุณมีบัตรอยู่กับคุณเท่านั้น'
  },
  cashioPageTtile: 'Foreign currency exchange services including delivery or airport pickup',
  CASH_TRANSFER: 'รับเงินสดในต่างประเทศ',
  CASH_TRANSFER_INLAND: 'รับเงินสดในต่างประเทศ',
  CASH_TRANSFER_ISR: 'รับเงินสด',
  CASH_TRANSFER_TO: 'ประเทศปลายทาง {dest}',
  cashBack: 'เครดิตเงินคืนของฉัน',
  cashBackReceive: 'รับของขวัญเงินคืน',
  cashBackLabel: 'CashBack',
  cashBackBalance1: 'เย้ คุณได้ของขวัญเงินคืนแล้ว!',
  cashBackBalance2: 'ยอดเงินของคุณคือ:',
  cashBackCredit: 'แลกเครดิตเงินคืน',
  cashBackModalMessage: 'เซอร์ไพรส์!',
  cashBackModalMessage2: 'คุณมี',
  cashBackModalMessage3: 'จากเรา',
  cashBackModalMessage4: 'คุณสามารถใช้ได้เลยและจ่ายน้อยลง',
  cashBackModalMessageCancel: 'ฉันจะเก็บสะสมต่อไป',
  cashBackModalMessageConfirm: 'ดีมาก! ฉันต้องการใช้เลย',
  cashBackPageFotter: 'คุณได้รับเครดิตเงินคืนในสกุลเงินเดียวกับการโอนเงินของคุณ',
  cashBackPageFotter2: 'คุณสามารถใช้เครดิตเงินคืนของคุณใน myGMTหรือที่สาขาใดก็ได้ของ GMT (เร็วๆ นี้)',
  cashBackPageFotter3: 'ถ้าคุณไม่ได้ใช้บริการของเรานานกว่า 6 เดือน บัญชีเครดิตเงินคืนของคุณจะว่างเปล่า',
  cashBackPageFotter4: 'อ่าน T&C ฉบับเต็ม>',
  cashBackPageMessage2: 'แค่ใช้บริการของ GMT เป็นประจำ แล้วรับเครดิตเงินคืน',
  cashBackPageMessage3: 'เมื่อคุณมียอดถึง',
  cashBackPageMessage4: '$/€ คุณจะสามารถเริ่มใช้ หรือเก็บสะสมต่อไปก็ได้',
  cashBackPageMessagePlus: 'ฉันจะรับเครดิตเงินคืนเพิ่มได้อย่างไร?',
  cashBackPageMessageZero: 'ทำอย่างไรถึงจะได้เครดิตเงินคืน?',
  cashBackPageTitlePlus: 'ดีมาก! คุณมี',
  cashBackPageTitleZero: 'รับเงินคืน',
  chatRepresentative: 'แชทกับเรา',
  checkStatus: 'ตรวจสอบสถานะ',
  status: 'ตรวจสอบสถานะ',
  changeAmount: 'ต้องการเปลี่ยนจำนวนเงินที่จะส่ง?',
  changePayoutAmount: 'ต้องการเปลี่ยนจำนวนเงินที่ผู้รับจะได้รับ?',
  changeAmountLabel: 'จำนวนไหม่คือ {amount}',
  changeAmounBtn: 'คำนวณ',
  city: 'เมือง',
  clone: 'ส่งอีกครั้ง',
  commission: 'ค่าบริการ',
  COMPANY_IDENTIFICATION: 'ขออภัย! ดูเหมือนคุณพยายามลงทะเบียนด้วยหมายเลขของธุรกิจ คุณสามารถลงทะเบียนด้วยหมายเลขประจำตัว เรายินดีช่วยเหลือ หากคุณมีคำถาม',
  confirmBankAccount: 'ข้อสำคัญ! กรุณาตรวจสอบรายละเอียดของบัญชีอย่างถี่ถ้วน เนื่องจากคุณไม่สามารถขอเงินคืนสำหรับเงินทุนที่ฝากเข้าบัญชีในต่างประเทศไปแล้วได้',
  confirmBankAccount2: 'บัญชีของผู้รับผลประโยชน์จะได้รับเงินเข้าประมาณ 2-4 วันทำการ',
  confirmCreditNumber: 'ข้อสำคัญ! กรุณาตรวจสอบหมายเลขบัตรให้รอบคอบ เนื่องจากคุณไม่สามารถขอเงินคืนสำหรับเงินทุนที่ฝากเข้าบัตรในต่างประเทศไปแล้วได้',
  confirmMarketingContent: 'ฉันต้องการรับเนื้อหาและข้อมูลล่าสุดทางการตลาด',
  connectionError: 'ข้อผิดพลาดในการเชื่อมต่อ',
  Contact: 'ติดต่อ',
  CONTACT_DENIED: 'ขออภัย! แต่เราต้องปฏิเสธการโอนเงินนี้เพื่อปกป้องคุณ และเราไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  CONTACT_GENERAL: 'ขออภัย! เรากำลังปรับปรุงเว็บไซต์ เพื่อปรับปรุงบริการต่างๆ ของเรา เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  contactBtnCaption: 'หน้าบริการ >',
  contactMenuCaption2: 'Line',
  contactMenuCaption3: 'โทร',
  contactMenuCaption4: 'Whatsapp',
  contactUsLink: 'en/contact-us/',
  CORRESPONDENT_BLOCKED: 'ขออภัย! บริการนี้ปิดปรับปรุงอยู่ในขณะนี้ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  CORRESPONDENT_EXCEPTION: 'ขออภัย! มีข้อผิดพลาดเกิดขึ้นขณะที่เราพยายามโอนเงินของคุณไปต่างประเทศ กรุณาติดต่อเรา เรายินดีตรวจสอบและช่วยเหลือคุณ',
  correspondentReference: 'หมายเลขอ้างอิง',
  correspondentSystem: 'สาขาของ',
  qrCodeDownload: "Download QR",
  scanExplanation: "Scan this QR code find us on WeChat",
  country: 'ประเทศต้นทาง',
  countryBirth: 'ประเทศที่เกิด',
  countryDestination: 'ประเทศปลายทาง',
  countryFrom: 'จากประเทศ',
  cookies: 'เว็บไซต์นี้ใช้ไฟล์คุ้กกี้ ถ้าคุณใช้งานเว็บไซต์นี้ต่อไป เราถือว่าคุณยินยอมให้เราใช้คุกกี้ หากคุณต้องการดูรายละเอียดเพิ่มเติม กรุณาดูที่ <a style="text-decoration:underline;display: inline;" href="https://gmtonline.co.il/en/privacy/" target="_blank">นโยบายความเป็นส่วนตัว </a>',
  CREDIT_BLOCKED: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตไม่สามารถใช้บริการได้ในขณะนี้ เนื่องจากเรากำลังอัปเดตเว็บไซต์ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  CREDIT_CARD_COMMIT_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_CARD_ILS_AMOUNT: 'เรากำลังคำนวณยอดเงินในสกุล ILS การชำระเงินจะสามารถใช้งานได้ในทันที',
  CREDIT_CARD_SIGN_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_CARD_VERIFY_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_LIMIT: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด ',
  CREDIT_LIMIT_MONTHLY: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด {amount}',
  CREDIT_LIMIT_CREDIT_CARD: 'จำนวนเงินที่สามารถทำการโอนได้ต่อเดือน ด้วยบัตรเครดิต. คุณสามารถโอนได้อีก {amount}',
  CREDIT_LIMIT_WALLET: 'จำนวนเงินที่สามารถทำการโอนได้ต่อเดือน ด้วยกระเป๋าเงิน. คุณสามารถโอนได้อีก {amount}',
  CREDIT_LIMIT_RISKY: 'คุณสามารถส่งเงินไป {country} ได้สูงสุด',
  CREDIT_LIMIT_UNREGISTERED: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด',
  CREDIT_NOT_ACTIVE: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตไม่สามารถใช้บริการได้ในขณะนี้ เวลาให้บริการคือ 06:00-24:00 น.',
  creditCard: 'บัตรเครดิต',
  creditCardCommissionMsg: 'บริษัทบัตรเครดิตของคุณอาจคิดค่าบริการสำหรับการแลกเปลี่ยนเงินตรา',
  creditCardOwner: 'ชื่อบนบัตร',
  creditCardSecurityMsg: 'เราไม่เก็บข้อมูลบัตรของคุณไว้ เราแค่ส่งข้อมูลให้กับบริษัทบัตรเครดิต เพื่อขออนุมัติเท่านั้น',
  creditCardSupport: 'เราทำงานกับ',
  crediting: 'เครดิต',
  currency: 'สกุลเงิน',
  currencyBankMsg: 'จำนวนเงินที่ได้รับท้ายที่สุดจะขึ้นกับชนิดและสกุลเงินของบัญชีของผู้รับผลประโยชน์',
  currencyCardMsg: 'จำนวนเงินที่ได้รับท้ายที่สุดจะขึ้นกับชนิดและสกุลเงินของบัตรของผู้รับผลประโยชน์',
  currencyDestination: 'สกุลเงินของผู้รับ',
  currencyEUR: 'EUR',
  currencyILS: 'ILS',
  currencyUSD: 'USD',
  CUSTOMER_ALREADY_REGISTERED: 'คุณคงลงทะเบียนใน myGMT แล้ว คุณสามารถเข้าสู่ระบบได้ที่นี่ > <a href="https://mygmt.gmtonline.co.il" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
  WALLET_FRIEND_NOT_UNIQUE: 'ขออภัย,เราไม่สามารถเพิ่มเพื่อนด้วยหมายเลขโทรศัพท์นี้ ในรายการเพื่อนของคุณได้. รบกวนติดต่อเราสำหรับรายละเอียดเพิ่มเติม',
  CUSTOMER_BLOCKED: 'ขออภัย! โพรไฟล์ของคุณถูกปิดแล้ว กรุณาติดต่อ<a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">แผนกบริการลูกค้า</a>ของเรา ',
  CUSTOMER_DAILY_LIMIT_EXCEEDED: 'ขออภัยคะ คุณได้โอนเงินเกินจำนวนสูงสุดของวันนี้แล้ว สำหรับคำถามใด ๆ ติดต่อฝ่ายบริการลูกค้าของเรา เรายินดีช่วยเหลือคุณ',
  NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'เราดีใจที่คุณได้เข้าร่วมกับเรา! คุณได้โอนเงินให้กับลูกค้ารายใหม่ถึงจำนวนสูงสุดในสัปดาห์แรกแล้ว สำหรับคำถามใด ๆ ฝ่ายบริการลูกค้าของเรายินดีช่วยเหลือ',
  NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'เราดีใจที่คุณเข้าร่วมกับเรา! คุณได้ส่งเงินถึงจำนวนสูงสุดสำหรับลูกค้าใหม่ในสัปดาห์แรกแล้ว. สำหรับคำถามใด ๆ ฝ่ายบริการลูกค้าของเรายินดีที่จะช่วยเหลือ',
  NEW_BENEFICIARY_LIMIT_EXCEEDED: 'ขออภัย คุณสามารถทำการโอนเงินกับบัตรใหม่ในต่างประเทศได้สูงสุดแค่ 2 ครั้งต่อสัปดาห์ หากมีคำถามใด ๆ ติดต่อฝ่ายบริการลูกค้าของเรา เรายินดีให้ความช่วยเหลือ',
  NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'ขออภัย คุณได้โอนเงินถึงจำนวนสูงสุดสำหรับการโอนเงินเข้าบัตรในต่างประเทศ หากมีคำถามใด ๆ ฝ่ายบริการลูกค้าของเรายินดีที่จะช่วยเหลือ',
  CUSTOMER_DENIED: 'สวัสดี เรายินดีที่เห็นคุณกลับมา คุณยังลงทะเบียนไม่เสร็จในครั้งที่แล้ว กรุณาติดต่อเรา เพื่อดำเนินการให้เสร็จ',
  CUSTOMER_MOBILE_NOT_MATCH: 'ขออภัย! หากคุณต้องการลงทะเบียน myGMT กรุณาติดต่อ<a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">แผนกบริการลูกค้า</a>ของเรา ',
  CUSTOMER_MONTHLY_LIMIT: 'ขออภัย! คุณใช้บริการครบขีดจำกัดรายเดือนของ GMT แล้ว เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  CUSTOMER_MULTIPLE: 'ขออภัย! หากคุณต้องการลงทะเบียน myGMT กรุณาติดต่อ<a href="https://gmtonline.co.il/en/contact-us/" style="color:#d4c641;text-decoration: underline;">แผนกบริการลูกค้า</a>ของเรา ',
  CUSTOMER_NOT_AUTHORIZED: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตนี้ไม่สามารถใช้บริการได้ในโพรไฟล์ของคุณ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  CUSTOMER_NOT_SIGNED: 'ขออภัย! คุณยังลงทะเบียนไม่เสร็จ ดังนั้น ตัวเลือกการชำระเงินบัตรเครดิตจึงไม่สามารถใช้บริการได้ในโพรไฟล์ของคุณ ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CUSTOMER_SESSION_NOT_FOUND: 'ข้อมูลประจำตัวไม่ถูกต้อง หรือคุณยังไม่ได้ลงทะเบียน myGMT คุณสามารถลงทะเบียนได้ที่นี่ <a href="https://mygmt.gmtonline.co.il/#/registration" style="color:#d4c641;text-decoration: underline;">mygmt.gmtonline.co.il</a>',
  customerCommission: 'ค่าบริการของคุณ',
  customerCommissionMaxTopup: 'ค่าธรรมเนียมในการเติมเงิน',
  customerEmailNotification: {
    title: 'กรุณาใส่่ที่อยู่ อีเมลย์ของท่าน',
    body: 'เราพบว่า เรายังไม่มีอีเมลย์ของท่าน\nเพื่อความสะดวก ควรใส่อีเมลย์ของท่านที่ช่องด้านล่างนี้ เพื่อที่เราจะได้ส่งสำเนาการทำธุรกรรมทุกอย่างไปที่อีเมลย์ของท่านด้วย.\nพวกเราจะไม่มีการส่งข้อมูลที่เป็นสแปมใดๆ ให้ท่าน.',
    cancel: 'ย้อนกลับ'
  },
  dateBirth: 'วันเกิด',
  daysWork: 'วันอาทิตย์ถึงวันพฤหัสบดี',
  daysWork2: 'วันอาทิตย์ถึงวันพฤหัสบดี 08:30-19:00 น. วันศุกร์ 08:30-14:00 น.',
  daysWork3: 'วันศุกร์',
  depositCardNumber: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
  destination: 'ปลายทาง',
  discount: 'ส่วนลด',
  docsTitle: 'กรุณาสแกนเอกสารแสดงตัวตนที่ถูกต้องของคุณ',
  docsTitleUploaded: 'ขอบคุณ, การดำเนินการใกล้เสร็จสิ้นแล้ว!',
  DOCUMENT_NOT_FOUND: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  documentsUploading1: 'เรากำลังบันทึกรูปภาพที่คุณได้ทำการเพิ่ม',
  documentsUploading2: 'จะใช้เวลาอีกสักครู่',
  documentsUploading3: 'จากนั้น ให้คุณกดปุ่มถัดไป',
  editUserProfile: 'รายละเอียดของฉัน',
  email: 'ที่อยู่อีเมล',
  error: 'ข้อผิดพลาด',
  errorCode: 'รหัสไม่ถูกต้อง กรุณาลองอีกครั้ง',
  estimated: 'โดยประมาณ',
  exchangeRate: 'อัตราแลกเปลี่ยน',
  expired: 'หมดอายุแล้ว',
  extraDetails: 'รายละเอียดเพิ่มเติม',
  faqLink: 'en/faq/',
  fax: 'แฟ็กซ์',
  FILE_NOT_ALLOWED: 'เกิดปัญหากับไฟล์',
  finished: 'เสร็จแล้ว',
  for: 'สำหรับ',
  foreignCurrencyHandlingFee: 'ค่าธรรมเนียมการจัดการสกุลเงินต่างประเทศ',
  foreignPassport: 'ฉันมีหนังสือเดินทางของต่างประเทศ',
  freeSearch: 'ค้นหา',
  friday: 'วันศุกร์',
  fullName: 'ชื่อเต็ม',
  fun: 'เราจะส่งข้อมูลอัปเดตในเรื่องสำคัญๆ เท่านั้นให้กับคุณ',
  gender: 'เพศ',
  new: 'ไหม่',
  newCustomer_section: 'ทางเลือกที่ดีที่สุดสำหรับการจัดสรรการเงินของคุณ',
  quickSend: 'ส่งอีกครั้ง',
  quickActions: 'ทางลัด',
  quickSendActionsParagraph: 'นี่คือผู้รับผลประโยชน์ล่าสุดของคุณ คุณสามารถที่จะกดส่งอีกครั้งได้เลย',
  quickActionsAdd: 'เพิ่ม',
  payAnywhere: 'บัตรเติมเงิน',
  carousel_slide_one_text: 'รับบัตรเติมเงินเพื่อจัดสรรการเงินของคุณให้ดียิ่งขึ้น',
  carousel_slide_one_button: 'รายละเอียดเพิ่มเติม',
  seeAll: 'ดูทั้งหมด',
  cardBalance: 'ยอดคงเหลือในบัตร',
  getCashback: 'และรับ',
  cashBack_info: 'ของขวัญเงินคืน',
  home: 'หน้าหลัก',
  menu: 'เมนู',
  activity: 'กิจกรรม',
  cards: 'บัตร',
  greeting_morning: 'สวัสดีตอนเช้า',
  greeting_afternoon: 'สวัสดีตอนบ่าย',
  greeting_evening: 'สวัสดีตอนเย็น',
  greeting_night: 'ราตรีสวัสดิ์',
  paidBy: 'ชำระด้วย',
  incoming: 'คุณได้รับ',
  outgoing: 'คุณได้ชำระ',
  CONVERSION: 'เรทเงิน',
  PAYMENT: 'การชำระเงิน',
  CASH_BACK: 'ของขวัญเงินคืน',
  cardActions: 'ตัวเลือกของบัตร',
  actions: 'ตัวเลือกการใช้งาน',
  activateCard: 'เปิดใช้งานบัตรใหม่ของคุณ',
  activateCardAction: 'เปิดใช้งานบัตร',
  enterLastDigits: 'กรอกหมายเลข 4 ตัวท้ายของบัตรเพื่อเปิดการใช้งาน',
  searchTransactions: 'ค้นหาธุรกรรม',
  filterTransactions: 'กรองรายการโอน',
  filterTransactionsButton: 'กรอง',
  openWallet: 'เปิดบัญชีกระเป๋าเงินฟรี',
  pleaseSelect: 'เลือกได้มากกว่าหนึ่งรายการขึ้นไป',
  date: 'วันที่',
  from_date: 'จากวันที่',
  to_date: 'ถึงวันที่',
  choose_date: 'เลือกวันที่',
  showResults: 'แสดงผลลัพธ์',
  clearFilter: 'ล้างตัวกรอง',
  today: 'วันนี้',
  contact_us_paragraph: '"เรายินดีที่จะให้ความช่วยเหลือ, คุณสามารถติดต่อหาเราได้หลากหลายช่องทางที่นี่.',
  changeLang: 'Change Language',
  call_us: 'โทรหาเรา',
  contact_us_or: 'หรือ',
  lastTransactions:'การทำธุรกรรมครั้งสุดท้ายของบัตร',
  transactionClasses: {
    BANK_TRANSFER: 'โอนเงินผ่านธนาคาร',
    CASH_TRANSFER: 'โอนแบบรับเงินสดปลายทาง',
    CARD_DEPOSIT: 'โอนเข้าบัตรเครดิต',
    PAYMENT: 'การโอนเข้า',
    CONVERSION: 'อัตราแลกเปลี่ยน',
    CASH_BACK: 'เงินคืน',
    WALLET_DEPOSIT: 'ฝากด้วยตนเองไปยังวอลเลทของคุณ',
    WALLET_TRANSFER: 'การโอนเงินเข้า E-Wallet',
    WALLET_TO_WALLET: 'การโอนเงินเข้า myGMT',
    WALLET_FROM_WALLET: 'การโอนเงินเข้า myGMT วอลเลท'
  },
  paymentTypes: {
    CASH: 'เงินสด',
    CHECK: 'ตรวจสอบ',
    BANK_TRANSFER: 'โอนเงินผ่านธนาคาร',
    CREDIT_CARD: 'บัตร',
    CREDITTING: 'บัตร',
    OUTER_CONVERSION: 'เงินสด',
    CASHBACK: 'ของขวัญเงินคืน',
    CUSTOMER_DEPOSIT: 'ยอดคงเหลือของบุคคลที่สาม',
    WALLET: 'วอลเลท',
    CHANGE: 'แลกเปลี่ยน',
    AIRPORT: 'สนามบิน',
  },
  nothing_found: 'ไม่พบธุรกรรม',
  nothing_found_text: 'เมื่อคุณได้มีการทำกิจกรรมใดๆ จะขึ้นแสดงที่นี่',
  creditCardReferenceNumber: 'ข้อมูลอ้างอิงของบริษัทบัตรเครดิต',
  checkNumber: 'ตรวจสอบหมายเลข',
  referenceNumber: 'อ้างอิง',
  flightDate: 'วันที่บิน',
  deposit_to_wallet: 'เติมเงินเข้าวอลเลท',
  contactMenuCaption1: 'เทเลแกรม',
  CARD: 'บัตร',
  CASH: 'เงินสด',
  AIRPORT: 'สนามบิน',
  CHECK: 'ตรวจสอบ',
  CHANGE: 'แลกเปลี่ยน',
  CREDIT_CARD: 'บัตร',
  CREDITTING: 'บัตร',
  OUTER_CONVERSION: 'เงินสด',
  CASHBACK: 'ของขวัญเงินคืน',
  CUSTOMER_DEPOSIT: 'ยอดคงเหลือของบุคคลที่สาม',
  WALLET: 'วอลเลท',
  quick_guide: 'คู่มือฉบับย่อสำหรับคุณ',
  invite_friends: 'เชิญเพื่อนเข้าร่วมใช้งาน myGMT',
  cash_back_desc: 'ของขวัญเงินคืน',
  send_money_home: 'ส่งเงินกลับบ้าน',
  send_money_worldwide: 'ส่งเงินไปต่างประเทศ',
  generalErrorMsg: 'ขออภัย! เรากำลังปรับปรุงเว็บไซต์ เพื่อปรับปรุงบริการต่างๆ ของเรา เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  GMTbranches: '<a href="https://gmtonline.co.il/en/locating-branches/" style="color:#d4c641;text-decoration: underline;">ที่สาขาใดก็ได้ของ GMT</a>',
  GRAILS_VALIDATION_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  here: 'ที่นี่',
  hours: 'หมายเหตุ: คุณต้องไปที่สาขาของ GMT เพื่อชำระเงินให้เสร็จ ภายใน 48 ชั่วโมงนับจากนี้',
  houseNumber: 'เลขที่บ้าน',
  welcome: 'ยินดีต้อนรับ',
  interest: 'รวมค่าธรรมเนียมแล้ว {inter}',
  interested: 'ในระหว่างนี้ คุณสามารถไปที่เว็บบล็อกของเรา',
  INVALID_CARD: 'ขออภัย! เรายังไม่รองรับบัตรที่คุณพยายามจะส่ง คุณสามารถลองอีกครั้ง โดยใช้บัตรอื่น เรายินดีช่วยเหลือคุณ',
  INVALID_SESSION_INFO: 'ขออภัย! มีบางสิ่งผิดปกติ กรุณาลองอีกครั้ง สิ่งนี้สำคัญมากสำหรับเรา',
  israel: 'อิสราเอล',
  imprtant: 'Important',
  israeliID: 'ฉันมีเลขประจำตัวประชาชนของอิสราเอล',
  israeliResident: 'ผู้มีถิ่นที่อยู่ในอิสราเอล',
  installments: 'การชำระเงิน',
  inProcess: 'อยู่ระหว่างการดำเนินการ',
  itemNavMain: 'My Wallet',
  itemNav0: 'เครดิตเงินคืนของฉัน',
  itemNav1: 'ส่งเงิน',
  itemNav10: 'สาขา',
  itemNav11: 'เกี่ยวกับ',
  itemNav12: 'บัตรของฉัน',
  itemNavUserProfile: 'รายละเอียดส่วนตัว และ การตั้งค่า',
  itemNav13: 'การสั่งซื้อและแลกเปลี่ยนเงินตรา',
  itemNav2: 'ประวัติของฉัน',
  itemNav3: 'เช็คเรทเงินและค่าธรรมเนียม',
  itemNav4: 'ผู้รับผลประโยชน์ของฉัน',
  itemNav5: 'สาขาของ GMT',
  itemNav6: 'คำถามที่พบบ่อย',
  itemNav7: 'ติดต่อเรา',
  itemNav8: 'ข้อกำหนดและเงื่อนไข',
  itemNav9: 'ออกจากระบบ',
  KYC_EXCEPTION: 'ขออภัยในความไม่สะดวก. เพื่อให้การทำธุรกรรมเสร็จสมบูรณ์, เราต้องการรายละเอียดเพิ่มเติมจากท่าน. โปรดติดต่อฝ่ายบริการลูกค้าเพื่อขอความช่วยเหลือ. ขอบคุณ.',
  WALLET_FRIEND_ALREADY_ADDED: 'สวัสดีคะ, หมายเลขโทรศัพท์นี้ได้อยู่ในรายชื่อเพื่อนของคุณแล้ว',
  WALLET_FRIEND_BLOCKED: 'ขออภัย,เราไม่สามารถเพิ่มเพื่อนด้วยหมายเลขโทรศัพท์นี้ ในรายการเพื่อนของคุณได้. รบกวนติดต่อเราสำหรับรายละเอียดเพิ่มเติม',
  WALLET_FRIEND_NOT_REGISTERED: 'ขออภัย หมายเลขโทรศัพท์มือถือ {mobileNumber} ยังไม่อยู่ใน myGMT ใช้ลิงค์ลงทะเบียนส่วนตัวของคุณเพื่อเชิญเพื่อนของคุณและรับเงินคืน!',
  labelEmail: 'ที่อยู่อีเมล',
  labelMsg: 'คุณต้องการพูดเรื่องอะไร?',
  labelName: 'ชื่อจริงของฉัน',
  labelPhone: 'หมายเลขโทรศัพท์',
  labelSurname: 'นามสกุลของฉัน',
  lblDone: 'การโอนเงินนี้เป็นการโอนเงินเพื่อเป็นของขวัญหรือเงินสนับสนุนให้กับญาติสายตรง และไม่ได้ก่อให้เกิดรายได้กับผู้รับผลประโยชน์',
  lblDoneWechat: 'Note that for the first transaction, your recipient needs to approve the WeChat transaction. Tell them to click on the SMS link.',
  lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
  leaveMsg: 'คุณต้องการพูดเรื่องอะไร?',
  linkToLoginPage: 'ลงทะเบียนแล้วใช่ไหม? ลงชื่อเข้าใช้ >',
  listBranches: 'ค้นหา GMT',
  locatingBranches: 'กำลังค้นหาตำแหน่งที่ตั้งของสาขา',
  locationBtn: 'เปิด',
  locationBtnClose: 'ปิด',
  locationLink: 'en/locating-branches/',
  loginAgreementRequired: 'กรุณาอ่านและยอมรับข้อกำหนดในการใช้บริการ',
  loginLabel1: 'หมายเลขประจำตัว/หนังสือเดินทาง',
  loginLabel2: 'หมายเลขโทรศัพท์มือถือ',
  logInConsent: 'เมื่อเข้าสู่ระบบ, ฉันได้ยอมรับ',
  mailingAddress: 'ที่อยู่',
  mailingRecipient: 'Raul Wallenberg 18C, Tel-Aviv, 6971915',
  marked: 'เปิดอยู่ในตอนนี้',
  marketingMessage: 'myGMT ช่วยให้คุณสามารส่งเงินให้กับครอบครัวของคุณในต่างประเทศ และรับบคะแนนการจ่ายเงินหลายพันคะแนนโดยใช้หมายเลขอ้างอิง สำหรับการฝากเงินเข้าบัตรเครดิตในต่างประเทศโดยตรง หรือการฝากเงินเข้าบัญชีธนาคารในต่างประเทศโดยตรง',
  marketingMessage2: 'ง่าย รวดเร็ว และปลอดภัย สำหรับคุณเท่านั้น',
  maxAccessionCommission: 'ค่าธรรมเนียมในการร่วมใช้บริการ',
  MAX_EXCEPTION: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  maybe: 'ในระหว่างนี้ คุณสามารถไปที่เว็บบล็อกของเรา',
  messageCode: 'เราเพิ่งส่ง SMS ที่มีรหัสไปให้กับคุณ กรุณาป้อนรหัสดังกล่าวในช่องด้านล่างนี้',
  messageRegistration1: 'สวัสดี เรายินดีที่เห็นคุณกลับมา',
  messageRegistration2: 'เราต้องการให้คุณอัปโหลดบัตรประชาชน/หนังสือเดินทางของคุณ ยอมรับ T&C ของเรา เท่านี้ก็เสร็จแล้ว',
  messageSend: 'เราจะส่งการยืนยันนี้ให้กับคุณทาง SMS และอีเมลในอีกสักครู่',
  messageNotSent: 'มีปัญหาในการรับรหัส OTP ของคุณใช่หรือไม่? โปรดตรวจสอบกล่องจดหมาย SMS และทำการเช็คที่ข้อความสแปมของคุณ. เรายินดีที่จะช่วยเหลือหากคุณต้องการรายละเอียดเพิ่มเติม',
  messageUploadDocuments: 'ดูเหมือนเอกสารแสดงตัวตนของคุณจะใช้ไม่ได้อีกต่อไป กรุณาอัปโหลดเอกสารใบใหม่',
  middleName: 'ชื่อกลาง',
  mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
  mobilePhoneNumber: 'หมายเลขโทรศัพท์อิสราเอล',
  monday: 'วันจันทร์',
  moneyApproved: 'การโอนเงินได้รับอนุมัติแล้ว',
  MoneyGram: 'MoneyGram',
  MONEYGRAM_301: 'เกินจำนวนเงินสูงสุดที่สามารถโอนได้',
  MONEYGRAM_304: 'ขออภัย! บริการโอนเงินของเราปิดให้บริการอยู่ในขณะนี้ เราเปิดให้บริการในวันทำการ เวลา 8:00-21:00 น. และวันศุกร์ เวลา 8:00-15:00 น.',
  MONEYGRAM_323: 'ขออภัย! ชื่อของผู้รับผลประโยชน์ที่คุณป้อนยาวเกินไป คุณสามารถลองอีกครั้ง โดยใช้ชื่อที่สั้นลง เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_4055: 'ขออภัย! แต่ MoneyGram ได้ปฏิเสธการโอนเงินนี้เพื่อปกป้องคุณ และระบบไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_7003: 'ขออภัย! แต่คุณได้โอนเงินครบวงเงินสูงสุดของ MoneyGram แล้ว และระบบไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_EXCEPTION: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_VALIDATION_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  moreOptions: 'ตัวเลือกอื่นๆ',
  msgError: 'เฉพาะหมายเลขเท่านั้น',
  myCard: 'บัตรของฉัน',
  myDateBirthday: '(วัน-เดือน-ปี)',
  myId: 'บัตรประชาชนของฉัน',
  nameBeneficiary: 'ชื่อผู้รับผลประโยชน์',
  NEW_CUSTOMER: 'เราใช้เวลาประมาณ 24 ชั่วโมงเพื่อดำเนินการลงทะเบียนของคุณให้เสร็จ ในระหว่างนี้ ตัวเลือกการชำระเงินบัตรเครดิตจะไม่สามารถใช้งานได้ เราจะแจ้งให้คุณทราบทันทีที่พร้อมให้บริการ',
  newBeneficiary: 'ผู้รับผลประโยชน์คนใหม่',
  nextLevel: 'ถัดไป',
  no: 'ไม่ใช่',
  noBeneficiaries: 'ยังไม่ได้กำหนดผู้รับผลประโยชน์คนใดไว้ในระบบ',
  noCardRecentActions: 'ไม่พบธุรกรรม',
  nonIsraeliResident: 'ผู้อยู่อาศัยที่เป็นชาวต่างชาติ',
  noRecentActions: 'ยินดีต้อนรับสู่โซนส่วนตัวของคุณใน myGMT',
  noRecentActions1: 'คุณสามารถดูประวัติทั้งหมดของคุณกับ myGMT ได้ที่นี่',
  noRecentActions2: 'มาส่งเงินกันเลยเถอะ!',
  notAvailable: 'ไม่สามารถใช้ได้',
  notRequired: 'ไม่บังคับ',
  numberOfTransaction: 'หมายเลขใบกำกับสินค้า',
  numberPassport: 'หมายเลขหนังสือเดินทาง',
  openNow: 'เปิด',
  payingReference: 'หมายเลขคำสั่งซื้อ',
  paymentCredit: 'บัตรเครดิต',
  paymentFrom: 'การชำระเงินจาก',
  paymentProcessing: 'โปรดรอซักครู่ ขณะนี้เรากำลังดำเนินการตามคำขอของคุณ',
  paymentType: 'ชนิดของการชำระเงิน',
  paymentTypeByCredit: 'ชำระด้วยบัตร',
  paymentTypeCash: 'เงินสด',
  paymentTypeCredit: 'ชำระด้วยบัตรเครดิต',
  payUsing: 'ฉันจะชำระด้วย',
  toPay: 'ยอดชำระ',
  payRate: 'เรทเงิน {rate}',
  photoID: 'รูปหน้าพาสปอร์ตของท่าน',
  photoRequired: 'กรุณาเพิ่มภาพถ่าย',
  pinCode: 'ดูรหัส PIN',
  pinCodeModalMessage: 'คุณต้องการดูรหัส PIN ของบัตรที่ลงท้ายด้วยเลขต่อไปนี้ใช่ไหม {digits}',
  pinCodeModalMessage2: 'เราจะส่งข้อความไปที่โทรศัพท์มือถือของคุณ เพื่อให้คุณดูรหัส PIN',
  placeholderDigit: 'หมายเลขประจำตัว 9 หลัก',
  protocolLink: 'en/protocol/',
  privacyLink: 'en/privacy',
  privacyPolicy: 'นโยบายความเป็นส่วนตัว',
  profileDelete: "ลบบัญชีของคุณ",
  profileDeleteModalTitle: "คุณต้องการที่จะทำการยกเลิกบัญชี myGMT ของคุณหรือไม่",
  profileDeleteModalText: "ทำได้โดยการที่คลิกปุ่มอนุมัติ,เราจะได้รับข้อความและติดต่อกลับโดยเร็วที่สุด",
  questionsAnswers: 'คำถามที่พบบ่อย',
  questionsAnswersSubtitle: 'ไม่พบคำตอบสำหรับคำถามของคุณ? กรุณาติดต่อเราด้วยวิธีที่ระบุไว้ในหน้าการติดต่อ',
  rate: 'เรทเงิน',
  reasonTransfer: 'เหตุผลในการโอนเงิน',
  recentActions: 'ประวัติของฉัน',
  recentActionsBeneficiaries: 'ผู้รับผลประโยชน์ของฉัน',
  recentActionsTab1: 'ผู้รับผลประโยชน์คนใหม่',
  recentActionsTab2: 'บริการแลกเปลี่ยนเงินตราต่างประเทศที่สนามบิน Ben Gurion',
  recentActionsTab3: 'กำลังเรียกเก็บเงินจากบัตร',
  recentActionsTab4: 'กำลังรับเงิน',
  recentActionsTab5: 'กำลังซื้อ Bitkoiin',
  recentActionsTransfer: 'ส่งเงิน',
  recommended: 'รายการที่แนะนำ!',
  r_step_address: 'ที่อยู่',
  r_step_doc: 'เอกสาร',
  r_step_name: 'ชื่อ',
  REGISTER_INFO_DETAILS_REQUIRED: 'เราต้องการรายละเอียดและเอกสารแสดงตัวตนของคุณ เพื่อส่งเงินไปต่างประเทศ',
  REGISTER_INFO_SEARCH_ZIP: 'คุณสามารถค้นหารหัสไปรษณีย์ของคุณได้ โดยกดที่ไอคอนแว่นขยาย',
  registerFooter: 'GMT ดำเนินงานโดยได้รับใบอนุญาตจากกระทรวงการคลัง',
  registerFooter2: 'ผู้ให้บริการด้านการเงินแบบขยาย - ใบอนุญาตเลขที่ 57479',
  registerFooter3: 'บริการของเรามีการเข้ารหัสลับและปลอดภัย และเราจะไม่เปิดเผยข้อมูลของคุณกับใครทั้งสิ้น',
  registerSystem: 'การลงทะเบียน myGMT',
  registerSystemAddDocuments: 'อัปโหลดเอกสารแสดงตัวตน',
  registerText1: 'คุณจะสามารถส่งเงินได้ในอีกสักครู่',
  registerText2: 'เตรียมบัตรประชาชน/หนังสือเดินทางของคุณไว้ เราจะขอให้คุณถ่ายภาพเอกสารในภายหลัง มาเริ่มกันเลยเถอะ',
  REGISTRATION_TOKEN_EXPIRED: 'ขออภัย! รหัสนี้ไม่สามารถใช้ได้อีกต่อไป คุณสามารถขอรหัสใหม่ได้',
  REGISTRATION_TOKEN_NOT_FOUND: 'คุณป้อนรหัสไม่ถูกต้อง',
  registrationSuccess: 'การลงทะเบียนสำเร็จแล้ว',
  registrationSuccess1: 'ขอบคุณ เราได้รับรายละเอียดของคุณแล้ว',
  registrationSuccess2: 'myGMT ช่วยให้คุณสามารถส่งเงินไปต่างประเทศได้อย่างรวดเร็วและง่ายดาย',
  requestSuccessfully: 'เราได้รับคำขอของคุณแล้ว',
  requestSuccessfully1: 'หมายเลขคำสั่งซื้อของคุณคือ:',
  requestSuccessfully2: 'ฉันต้องทำอะไรต่อไป? คุณต้องชำระเงินสำหรับการโอนเงินนี้ภายใน 24 ชั่วโมงนับจากนี้',
  requestSuccessfully3: '<a href="https://gmtonline.co.il/en/locating-branches/" style="color:#d4c641;text-decoration: underline;">ที่สาขาใดก็ได้ของ GMT </a>',
  requestSuccessfully4: 'จากนั้น เงินดังกล่าวจะถูกส่งไปต่างประเทศ',
  riskyLimitMsg: 'หากต้องการส่งจำนวนเงินที่สูงขึ้น? ติดต่อซัพพอร์ตทีมของเรา.',
  russianSpeaker: 'บริการในภาษารัสเซีย',
  saturday: 'วันเสาร์',
  searchResult: 'พบ X สาขาใน XXX',
  selectBeneficiary: 'ผู้รับผลประโยชน์ของฉัน',
  selectCity: 'ค้นหาจากเมือง',
  selectCountry: 'ประเทศบ้านเกิด',
  selectProvince: 'เลือกจังหวัด',
  sendMarketingContentLbl: 'ส่งข้อเสนอพิเศษและข้อมูลล่าสุดให้กับฉัน',
  sentOn: 'วันที่',
  SERVER_ERROR: 'ขออภัย! เรากำลังอัปเดตระบบอยู่ในขณะนี้ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  SESSION_EXPIRED: 'คุณกลับมาแล้ว เราคิดว่าคุณไปแล้ว เราเลยออกจากหน้านี้เพื่อปัองกันความปลอดภัย คุณสามารถเริ่มต้นอีกครั้ง',
  signedUp: 'คุณได้ลงทะเบียนแล้ว',
  SMS_EXPIRED: 'ขออภัย! รหัสนี้ไม่สามารถใช้ได้อีกต่อไป คุณสามารถขอรหัสใหม่ได้',
  SMS_WRONG: 'คุณป้อนรหัสไม่ถูกต้อง',
  soonPossible: 'เราจะติดต่อกลับไปหาคุณในชั่วโมงทำการของเรา',
  staging: {
    payWith: 'Cash at GMT location',
    body: 'you\'ll press the "place your order" button, we will prepare the transfer for you. The transfer will be sent after you pay at one of GMT\'s locations.',
    order: 'Place Your Order'
  },
  streetName: 'ถนน',
  streetName_HouseNumber: 'เลขที่บ้านและชื่อถนน',
  subscribeDesc: 'พบกับข่าวสารล่าสุดทั้งหมดในโลกของ FinTech ได้ที่นี่',
  subscribeNewsletter: 'ลงทะเบียนเพื่อรับจดหมายข่าวขอเรา - คุ้มนะ!',
  subtitleCustomerService: 'กรุณาติดต่อแผนกบริการลูกค้าของเรา เรายินดีช่วยเหลือคุณ',
  sumAmount: 'ยอดเงินรวม',
  summaryTransfer: 'สรุปรายละเอียดการโอนเงิน',
  sunday: 'วันอาทิตย์',
  surtitleFormLang: 'เลือกภาษาที่คุณต้องการ',
  smartBannerTitle: 'อัปเกรดเป็นแอป myGMT',
  smartBannerText: 'ง่าย. เร็ว. ปลอดภัย. สำหรับคุณ.',
  smartBannerBtnText: 'รับ',
  system: 'ระบบ',
  TEMPORARY_ERROR: 'ขออภัย! มีข้อผิดพลาดเกิดขึ้นขณะที่เราพยายามโอนเงินของคุณไปต่างประเทศ กรุณาติดต่อเรา เรายินดีตรวจสอบและช่วยเหลือคุณ',
  terms: 'ข้อกำหนดและเงื่อนไข',
  termsTitle: 'กรุณาอ่านและยอมรับ T&C',
  tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
  surveyModal: {
    active: true,
    title: 'แบ่งปันความคิดเห็นของคุณ: ทำแบบสำรวจโดยไม่ระบุชื่ออย่างรวดเร็วของเรา',
    body: 'สวัสดี {name} เราอยากได้ยินความคิดเห็นจากคุณ! เพื่อช่วยให้เราได้ปรับปรุงบริการทางการเงินของเราโดยการตอบแบบสำรวจแบบสั้นๆ โดยไม่เปิดเผยตัวตน ความคิดเห็นของคุณมีความสำคัญกับเรามาก',
    confirm: 'เริ่มการสำรวจกันเลย',
    cancel: 'คราวหลัง',
    link: 'https://forms.gle/ZmtBA8s9HT7vVtvS8'
  },
  termsUpdate: {
    title: 'T&C และ นโยบายของความเป็นส่วนตัว',
    body: {
      first: 'สวัสดีคะ เราได้ทำการอัปเดตเอกสารทางกฎหมายของเรา',
      second: 'คุณสามารถเข้าอ่านได้ที่นี่>'
    }
  },
  termsUse: 'T&C',
  thanks: 'ขอบคุณที่ติดต่อมาหาเรา',
  thursday: 'วันพฤหัสบดี',
  titleFormLang: 'ยินดีต้อนรับ',
  titleLoginPage: 'ลงชื่อเข้าใช้ myGMT',
  to: 'ไปที่',
  toBankAccount: 'ไปที่บัญชี',
  toWalletAccount: 'ไปที่บัญชีวอลเลท',
  pickupPoint: 'จุดรับเงิน',
  toCreditCard: 'ไปที่บัตร',
  total: 'ยอดรวม',
  totalNIS: 'ยอดรวมเป็น NIS',
  totalTransfer: 'ยอดรวมที่ต้องชำระ',
  TRANSACTION_LIMIT: 'ขออภัย การโอนนี้จำกัดอยู่ที่ {currency}{amount} ต่อธุรกรรม  คุณสามารถอัปเดตจำนวนเงินและทำการส่ง  เรายินดีที่จะช่วยเหลือหากมีคำถามใดๆ',
  TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  t_step_amount: 'จำนวน',
  t_step_transfer: 'โอน',
  t_step_summary: 'สรุป',
  t_step_pay: 'ชำระ',
  transactionAmount: 'จำนวนเงิน',
  transactionCharge: 'เรียงตาม',
  filterPaymentType: 'ประเภทการชำระเงิน',
  transactionType: 'ประเภทการโอน',
  transferAmount: 'จำนวนเงิน',
  transferFundsTo: 'ส่ง',
  transferOptions: 'ตัวเลือกการโอน',
  transferType: 'ชนิดการโอน',
  transferTypePick: 'กรุณาเลือกวิธีการโอนเงินของคุณ',
  tuesday: 'วันอังคาร',
  updateBeneficiary: 'รายละเอียดของผู้รับผลประโยชน์',
  uploadDocumentMsg1: 'เราต้องตรวจสอบเอกสารแสดงตัวตนของคุณ กรุณาถ่ายภาพเอกสารของคุณ',
  uploadDocumentMsg2: 'เรารักษาความเป็นส่วนตัวของคุณอย่างเคร่งครัด เว็บไซต์ของเรามีการเข้ารหัสลับและปลอดภัย และเราจะไม่เปิดเผยข้อมูลของคุณกับใครทั้งสิ้น',
  version: 'v {num}',
  waitingPayment: '- กำลังรอชำระเงิน',
  WALLET_TRANSFER: 'โอนไปยัง eWallet ในต่างประเทศ',
  WALLET_TRANSFER_TO: 'โอนไปยัง eWallet ใน {dest}',
  WALLET_TO_WALLET: 'ส่งไปที่ myGMT wallet',
  WALLET_FROM_WALLET: 'ได้รับจาก myGMT wallet',
  WALLET_DEPOSIT: 'ฝากเงินไปที่ myGMT wallet',
  wednesday: 'วันพุธ',
  YAAD_SARIG_141: 'ขออภัย! เรารับแค่บัตร Visa และ MasterCard เท่านั้นในตอนนี้ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_26: 'ขออภัย! บัตรนี้ไม่ได้เป็นของหมายเลขประจำตัวของคุณ คุณสามารถลองอีกครั้ง โดยใช้บัตรที่เป็นของคุณ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_3: 'ขออภัย! บริษัทบัตรเครดิตไม่อนุมัติการโอนเงินนี้ เราขอแนะนำให้คุณติดต่อบริษัทเพื่อแก้ปัญหา แล้วลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_3000: 'ขออภัย! บริษัทบัตรเครดิตไม่อนุมัติการโอนเงินนี้ เราขอแนะนำให้คุณติดต่อบริษัทเพื่อแก้ปัญหานี้ แล้วลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_3003: 'ขออภัย! บริษัทบัตรเครดิตไม่อนุมัติการโอนเงินนี้ เราขอแนะนำให้คุณติดต่อบริษัทเพื่อแก้ปัญหานี้ แล้วลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_303: 'ขออภัย! บัตรเครดิตของคุณไม่รองรับการตรวจสอบความปลอดภัยของบริษัทบัตรเครดิต คุณสามารถติดต่อบริษัทบัตรเครดิต เพื่อแก้ปัญหานี้ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_4: 'ขออภัย! บริษัทบัตรเครดิตไม่อนุมัติการโอนเงินนี้หรือรายละเอียดบัตรที่คุณป้อน เราขอแนะนำให้คุณลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_447: 'ขออภัย! คุณป้อนหมายเลขบัตรเครดิตไม่ถูกต้อง เราขอแนะนำให้คุณลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_553: 'ขออภัย! เราคิดว่าคุณไม่อนุมัติการชำระเงินเข้าบัตรเครดิตของคุณ เราเลยยกเลิกไปแล้ว คุณสามารถลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_6: 'ขออภัย! มีบางสิ่งผิดปกติเกี่ยวกับรายละเอียดบัตรที่คุณป้อน เราขอแนะนำให้คุณลองอีกครั้ง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_9101: 'ขออภัย! บัตรเครดิตของคุณไม่รองรับการตรวจสอบความปลอดภัยของบริษัทบัตรเครดิต คุณสามารถติดต่อบริษัทบัตรเครดิต เพื่อแก้ปัญหานี้ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  YAAD_SARIG_999: 'ขออภัย! มีบางสิ่งผิดพลาด เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  YAAD_SARIG_GENERAL: 'ขออภัย! มีบางสิ่งผิดปกติเกี่ยวกับการชำระเงิน เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  INVALID_VERSION: 'ขออภัยในความไม่สะดวก, เราได้ทำการอัพเดทเว็บไซต์ โปรดรีเฟรช หน้าเพจเว็บไซต์เพื่อไปที่การอัพเดทล่าสุดของ myGMT',
  BENEFICIARY_CARD_BLOCKED: 'This card number or the bank are not supported by the service, you can try to send to a different card',
  ERROR_NEGATIVE: 'ขออภัย คุณไม่สามารถทำธุรกรรมในจำนวนเงินที่ติดลบหรือน้อยเกินไป โปรดระบุยอดจำนวนเงินไหม่',

  yes: 'ใช่',
  yourCardBalance: 'ยอดคงเหลือของบัตร',
  zipCode: 'รหัสไปรษณีย์',
  zipCodeNotFound: 'เราค้นหารหัสไปรษณีย์ของที่อยู่นี้ไม่พบ กรุณาตรวจสอบอีกครั้ง หรือกรอกรหัสไปรษณีย์ 7 หลักของคุณ',

  E_DECIMAL: 'ขอเฉพาะตัวเลขที่ปัดเศษ',
  E_INVALID_zipCode: 'รหัสไปรษณีย์ไม่ถูกต้อง',
  E_LEMGTH: { 1: 'ตัวเลข', 2: 'หลักเท่านั้น' },
  E_MAX_VALUE: 'คุณป้อนค่าเกินขีดจำกัดสูงสุดแล้ว',
  E_MAX_CHAR: { 1: 'ความยาวสูงสุด ', 2: 'อักขระ' },
  E_MIN_CHAR: { 1: 'ความยาวอย่างน้อย', 2: 'อักขระ' },
  E_MIN_passport: 'ต้องยาวเกิน 5 อักขระ',
  E_NUMERIC: 'ใช้แค่ตัวเลขเท่านั้น',
  E_REGEX: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
  E_REGEX_email: 'รูปแบบอีเมลไม่ถูกต้อง',
  E_REGEX_english: 'เฉพาะภาษาอังกฤษเท่านั้น',
  E_REGEX_mobileNumber: 'กรุณาป้อนหมายเลขโทรศัพท์มือถืออิสราเอลที่ถูกต้อง ด้วยตัวเลข 10 หลัก',
  E_REGEX_israeliId: 'ไม่ใช่เลขประจำตัวประชาชนของอิสราเอลที่ถูกต้อง',
  E_REGEX_passport: 'เฉพาะตัวเลขและตัวอักษรเท่านั้น',
  E_REGEX_birthDateAge: 'สำหรับผู้ที่มีอายุ 18-85 ปี',
  E_REGEX_zipCode: 'รหัสไปรษณีย์ต้องเป็นตัวเลข 7 หลัก',
  E_REQUIRED: 'ช่องที่ต้องระบุ',
  E_REQUIRED_amount: 'จำนวนเงินเท่าใด?',
  E_REQUIRED_currency: 'สกุลเงินอะไร?',
  E_REQUIRED_destinationCountry: 'ปลายทางคือที่ใด?',
  E_REQUIRED_agreement: 'กรุณาอ่านและยอมรับข้อกำหนดในการใช้บริการ',

  STATUS_NOT_FOUND: 'ไม่พบ',
  STATUS_CONFIRMED: 'Confirmed',
  STATUS_READY_FOR_PAYMENT: 'พร้อมชำระเงินแล้ว',
  STATUS_PAID: 'ชำระเงินแล้ว',
  STATUS_SENT_TO_BANK: 'ส่งไปที่ธนาคารแล้ว',
  STATUS_BEING_CANCEL: 'Being canceled',
  STATUS_READY_TO_CANCEL: 'Ready for Cancelation',
  STATUS_CANCELED: 'ยกเลิกแล้ว',
  STATUS_PREPARED: 'Prepared',
  STATUS_FAILED: 'Failed',
  STATUS_RETURNED: 'Returned',
  STATUS_ACCEPTED: 'Accepted',
  STATUS_STAGED: 'STAGED',
  STATUS_INCOMPLETE: 'incomplete',
  STATUS_IN_PROCESS: 'Being paid out',
  STATUS_SWIFT_TRANSMITTED: 'Transmitted',
  STATUS_SWIFT_APPROVED: 'Approved',
  STATUS_REJECTED: 'Rejected',
  STATUS_EXPIRED: 'Expired',
  STATUS_CLOSED: 'Closed',
  STATUS_SUBMITTED: 'Submitted',

  DESC_CASHBACK: 'เครดิตเงินคืน',
  DESC_CASHBACK_BIRTHDAY: 'สุขสันต์วันเกิด! รับเครดิตเงินคืน',
  DESC_CASHBACK_ONETIME: 'รับเครดิตเงินคืนเป็นของขวัญ',
  DESC_CASHBACK_WELCOME: 'ยินดีต้อนรับ! รับเครดิตเงินคืน',
  DESC_WALLET: 'ฝากเงินเข้ากระเป๋า',
  DESC_MAX_TOPUP: 'การเติมเงินในบัตร',
  DESC_MAX_PURCHASE: 'การซื้อ และการเติมเงินในบัตร',
  DESC_MAX_ORDER: 'การสั่งซื้อบัตรเติมเงิน',
  DESC_CONVERSION: 'การแปลงเงิน',
  DESC_019MOBILE: '019 mobile',
  DESC_REWIRE: 'Rewire',
  DESC_DEPOSIT_3MONTHS: 'แผนเงินฝากสำหรับ 3 เดือน',
  DESC_DEPOSIT_6MONTHS: 'แผนเงินฝากสำหรับ 6 เดือน',
  DESC_DEPOSIT_WITHDRAW: 'ถอนเงินออกจากบัญชีเงินฝาก',
  DESC_DEPOSIT_INTEREST_PAYMENT: 'สินเชื่อดอกเบี้ยสะสม',

  DEPOSIT_IN: 'โอนเข้าบัญชีเงินฝาก',
  DEPOSIT_OUT: 'การถอนเงินออกจากบัญชีเงินฝาก',
  DEPOSIT_INTEREST: 'สินเชื่อดอกเบี้ยสะสม',

  NAME_ALIPAY: 'ALIPAY',
  NAME_UNIONPAY: 'UNIONPAY',
  NAME_WECHATPAY: 'WECHATPAY',
  NAME_UPI: 'UPI',

  validation: {
    mock: '',
    beneficiary: {
      invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
      maskedCardNumber: {
        invalidCountry: 'บัตรอาจไม่ตรงกับประเทศปลายทาง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง'
      },
      accountIdentifier: {
        invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง'
      },
      walletIdentifier: {
        invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง'
      },
      phoneWallet: {
        unavailable: 'ขออภัย, บัญชีนี้ใช้งานไม่ได้และไม่สามารถรับเงินได้. โปรดตรวจสอบบัญชีก่อนลองอีกครั้ง.',
        mismatch: 'ขออภัย, ชื่อผู้รับผลประโยชน์และบัญชีไม่ตรงกัน. คุณสามารถแก้ไขรายละเอียดและลองอีกครั้ง.',
        invalidAccount: 'ขออภัย, บัญชีนี้ไม่ถูกต้อง. โปรดตรวจสอบบัญชีก่อนลองไหม่อีกครั้ง.',
        barred: 'ขออภัย, บัญชีนี้ถูกล็อค. โปรดตรวจสอบบัญชีก่อนลองไหม่อีกครั้ง.'
      },
      phoneNumber: {
        format: {
          passParam: 'use correct format: {format}'
        }
      },
    }
  }
}
