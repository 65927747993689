import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import VeeValidate from 'vee-validate';
import VueCollapse from 'vue2-collapse'
import VueCookies from 'vue-cookies'
import vSelect from 'vue-select'
import './ml'
import { ModelListSelect, ModelSelect } from 'vue-search-select'
import cookieconsent from 'vue-cookieconsent-component'
import { Workbox } from 'workbox-window';
import { formatDecimal,formatAmount, formatComma, dirMaskedCardNumber, iso3ToSymbol, lowerCaseCapitalise } from './filters/AppFilters';
import { paintStatusBar, processPhoneBook } from './services/utils';
import {AnalyticsService} from './services/AnalyticsService'
import { formatDecimalAndStyle, formatDecimalAndStyleTitle } from './directives/AppDirectives';
import "@/assets/styles/media.scss";


if (process.env.NODE_ENV === 'production') {
    Vue.config.productionTip = false;
    Vue.config.silent = true;
}

Vue.use(VeeValidate, {
    events: 'blur'
});
Vue.use(VueCollapse);
Vue.use(VueCookies)
Vue.component('v-select', vSelect);
Vue.component('model-list-select', ModelListSelect);
Vue.component('model-select', ModelSelect);

Vue.filter('formatAmount', formatAmount);
Vue.filter('formatDecimal', formatDecimal);
Vue.filter('formatComma', formatComma);
Vue.filter('iso3ToSymbol', iso3ToSymbol)
Vue.filter('formatCardNumber', dirMaskedCardNumber);
Vue.filter('lowerCaseCapitalise', lowerCaseCapitalise);

Vue.directive('formatTitleAmount', formatDecimalAndStyleTitle);
window.rsa = require('node-forge')

import VueProgressBar from 'vue-progressbar'

Vue.component('cookie-consent', cookieconsent)

Vue.use(VueProgressBar, {
    color: '#092086', failedColor: 'red', height: '10px', width: '50%', left: '25%', autoFinish: false,

    thickness: '15px', transition: {
        speed: '0.2s', opacity: '0.6s', termination: 300
    },
});

Vue.prototype.$walletTransferType = {
    SELF: 'self', FRIEND: 'friend', CARD: 'card', WALLETORDER: 'walletOrder', NONE: 'none'
};
Vue.prototype.$analyticsService = new AnalyticsService(store, router);
Vue.prototype.homeRoutes = ["Login", "Registration", "calculator-unregistered"];
Vue.prototype.stepManagedRoutes = ["transfer", 'transfernew' , "wallet-transfer", "transfer-beneficiary", "registration_steps", "wallet-bank-confirmation", "credit_card_payment", "Contacts"];
Vue.prototype.transactionClasses = ["BANK_TRANSFER", "CASH_TRANSFER", "CARD_DEPOSIT", "PAYMENT", "CONVERSION", "CASH_BACK", "WALLET_DEPOSIT", "WALLET_TRANSFER", "WALLET_TO_WALLET", "WALLET_FROM_WALLET","UPI_BANK_TRANSFER"]
Vue.prototype.paymentTypes = ["CASH", "CHECK", "BANK_TRANSFER", "CREDIT_CARD", "CREDITTING", "OUTER_CONVERSION", "CASHBACK", "CUSTOMER_DEPOSIT", "WALLET"]
Vue.prototype.completedStatuses = ["STATUS_CONFIRMED", "STATUS_READY_FOR_PAYMENT", "STATUS_PAID", "STATUS_SENT_TO_BANK", "STATUS_SWIFT_APPROVED"]
Vue.prototype.failedStatuses = ["STATUS_CANCELED", "STATUS_FAILED", "STATUS_RETURNED", "STATUS_REJECTED"]
Vue.prototype.defaultAnimationDurationMs = 800

window.$ = window.jQuery = require('jquery');
window.moment = require('moment');
window._ = require('lodash');
window.select2 = require('select2')
require('air-datepicker');
require('air-datepicker/dist/js/i18n/datepicker.en');

Object.filter = (obj, predicate) => Object.keys(obj)
    .filter(key => predicate(obj[key]))
    .reduce((res, key) => (res[key] = obj[key], res), {});


export default new Vue({
    router, store, created() {
        this.$store.dispatch('getLangTitle');
    }, render: h => h(App)
}).$mount('#app');

try {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let i = 0; i < registrations.length; i++) {
            registrations[i].unregister()
        }
    });
} catch (e) {
    console.log(e)
}

window.gonative_library_ready = () => {
    gonative.auth.status({
        'callbackFunction': (res) => {
            res.loaded = true;
            store.commit('setBiometricData', { ...res })
        }
    });
    paintStatusBar('ffffff', 'dark')
    gonative.firebaseAnalytics.event.collection({ 'enabled': true })
    if (window.location.href.indexOf('/wallet/contacts') > -1) {
        gonative.contacts.getAll({
            'callback': (data) => {
                store.commit('setContacts', processPhoneBook(data.contacts))
            }
        });
    }
}