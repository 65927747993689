import banners from '../data/banners';
import _ from 'lodash';

export class BannerService {
  constructor() {
    this.banners = banners;
  }

  getBannerByLanguage(language) {
    return this.banners[language];
  }

  getBannerByQuery(query) {
    const { language, origin, showType, currentDate } = query;

    let filteredBanners = this.banners[language];

    if (origin !== undefined) {
      filteredBanners = _.filter(filteredBanners, banner => {
        return banner.origins.includes(origin) || !banner.origins || !banner.origins.length;
      });
    }

    if (showType !== undefined) {
      filteredBanners = _.filter(filteredBanners, { [showType]: true });
    }

    if (currentDate) {
      filteredBanners = _.filter(filteredBanners, banner => {
        return banner.showUntil && moment(banner.showUntil).isSameOrAfter(moment(currentDate));
      });
    }

    filteredBanners = _.uniqWith(filteredBanners, (a, b) => {
      return (
        a.title === b.title &&
        a.subtitle === b.subtitle &&
        a.imgSrc === b.imgSrc &&
        a.actionLink === b.actionLink
      );
    });

    filteredBanners = _.orderBy(filteredBanners, ['isFirst', 'createdAt'], ['desc', 'desc']);
    return filteredBanners;
  }
}
